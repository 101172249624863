import * as React from 'react';

export function FindPartner(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={54} height={54} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#prefix__clip0_4401_1521)" fill="#BFBEC3">
                <path d="M35.141 12.132a18.47 18.47 0 00-16.578-10.34C8.328 1.792 0 10.072 0 20.25c0 2.765.594 5.416 1.766 7.888L.032 36.813a1.583 1.583 0 001.858 1.862l8.783-1.736a18.2 18.2 0 003.663 1.281c-2.818-13.346 7.298-25.873 20.805-26.088z" />
                <path d="M52.234 41.637c.303-.64.567-1.29.792-1.953h-.058c3.95-11.592-4.356-23.776-16.618-24.37v-.001c-10.55-.486-19.316 7.961-19.316 18.436 0 10.168 8.264 18.441 18.43 18.456 2.756-.004 5.4-.597 7.864-1.766 9.619 1.902 8.869 1.766 9.09 1.766 1 0 1.746-.916 1.55-1.892l-1.734-8.676z" />
            </g>
            <defs>
                <clipPath id="prefix__clip0_4401_1521">
                    <path fill="#fff" d="M0 0h54v54H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
