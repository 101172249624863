import './UploadingImagePreview.css';
import React, {ReactElement} from 'react';
import {CrossIcon} from '../../../assets/svg/CrossIcon';

interface Props {
    selectedImage: File | null;
    clearImage: () => void;
}

export const UploadingImagePreview = (props: Props): ReactElement | null => {
    const {selectedImage, clearImage} = props;

    if (!selectedImage) {
        return null;
    }

    return (
        <div className={'UploadingImagePreview-container'}>
            <img
                src={URL.createObjectURL(selectedImage)}
                className={'UploadingImagePreview-img'}
                width={100}
                alt={'uploading file preview'}
            />
            <div className={'UploadingImagePreview-cross-div'} onClick={clearImage}>
                <CrossIcon />
            </div>
        </div>
    );
};
