import './SettingsItem.css';
import React, {PropsWithChildren, ReactElement} from 'react';

export interface SettingsItemBaseProps {
    bottomTip?: string;
    onClick?: () => void;
}

export const SettingsItem = (props: PropsWithChildren<SettingsItemBaseProps>): ReactElement => {
    const {bottomTip, onClick, children} = props;
    return (
        <div className={`SettingsItem-container ${onClick ? '' : 'disabled'}`} onClick={() => onClick?.()}>
            <div className={'SettingsItem-body'}>{children}</div>
            {bottomTip && <span className={'SettingsItem-bottom-tip'}>{bottomTip}</span>}
        </div>
    );
};
