import './MessagePreview.css';
import React, {ReactElement} from 'react';
import {VscChromeClose} from 'react-icons/vsc';
import {MessagePreview as MessagePreviewType, MessagePreviewAction} from '../../../../types/messagePreview';
import {useAppDispatch, useAppSelector} from '../../../../redux/hooks';
import {clearMessagePreview} from '../../../../redux/reducers/localSettingsReducer';
import {getLocalizedString} from '../../../../utils/helpers/getLocalizedString';

interface Props {
    messagePreviewState: MessagePreviewType | null;
    dialogId: string;
}

const previewTitle: Record<MessagePreviewAction, string> = {
    edit: 'previewEditTitle',
    reply: 'previewReplyTitle',
};

export const MessagePreview = (props: Props): ReactElement | null => {
    const {dialogId, messagePreviewState} = props;
    const dispatch = useAppDispatch();
    const message = useAppSelector((state) => state.dialogs.messages[dialogId][messagePreviewState?.messageId ?? '']);

    if (!messagePreviewState || !message) {
        return null;
    }

    const getPreviewBody = (): string => {
        if (message.type === 'text') {
            return message.text ?? '';
        } else if (message.type === 'voice') {
            return getLocalizedString('lastMessageVoice');
        } else if (message.type === 'image') {
            return message.text && message?.text?.length > 0 ? message.text : getLocalizedString('lastMessageImage');
        }
        return '';
    };

    return (
        <div className={'MessagePreview-container'}>
            <div className={'MessagePreview-content'}>
                {message.type === 'image' && (
                    <img className={'MessagePreview-img'} src={message.uri} alt={'Message preview'} />
                )}
                <div className={'MessagePreview-spans-container'}>
                    <span className={'MessagePreview-span-title'}>
                        {getLocalizedString(previewTitle[messagePreviewState.action])}
                    </span>
                    <span className={'MessagePreview-span-body'}>{getPreviewBody()}</span>
                </div>
                <VscChromeClose
                    onClick={() => dispatch(clearMessagePreview({dialogId}))}
                    className={'MessagePreview-close-icon'}
                />
            </div>
        </div>
    );
};
