export const connection = 'connection';
export const startSearch = 'start-search';
export const getUpdatesV2 = 'get-updates-v2';
export const getUpdatesV3 = 'get-updates-v3';
export const getDialogs = 'get-dialogs';
export const getAdminDialogs = 'get-admin-dialogs';
export const getUser = 'get-user';
export const disconnect = 'disconnect';
export const connectError = 'connect_error';
export const reconnect = 'reconnect';
export const dialogLiked = 'dialog-liked';
export const quitSearchingQueue = 'quit-searching-queue';
export const sendComplaint = 'send-complaint';
export const findNextPartner = 'find-next-partner';
export const pushNotificationToken = 'push-notification-token';
export const pushNotificationTokenV2 = 'push-notification-token-v2';
export const persistDialog = 'persist-dialog';
export const updateDialogMeta = 'update-dialog-meta';
export const closeDialog = 'close-dialog';
export const updateUser = 'update-user';
export const newMessage = 'send-message';
export const userTyping = 'user-typing';
export const readMessage = 'read-message';
export const readMessages = 'read-messages';
export const partnerFound = 'partner-found';
export const checkVersion = 'client-version-verifying';
export const changeConnectionStatus = 'change-connection-status';
export const changeConnectionStatusV2 = 'change-connection-status-v2';
export const updateCookie = 'update-cookie';
export const clearCookie = 'clear-cookie';
export const error = 'connect_error';
export const partnerLikedDialog = 'partner-liked-dialog';
export const cancelLike = 'cancel-like';
export const changeSettings = 'change-settings';
export const editMessage = 'edit-message';
export const deleteMessage = 'delete-message';
export const updateDialogId = 'update-dialog-id';
export const removeDialog = 'remove-dialog';
export const updateBanInfo = 'update-ban-info';
export const updatePartnerSettings = 'update-partner-settings';
export const createDialogWithAdmin = 'create-dialog-with-admin';
export const createDialogWithAdminV2 = 'create-dialog-with-admin-v2';
export const createDialogWithAdminV3 = 'create-dialog-with-admin-v3';
export const blockPartner = 'block-partner';
export const getDialog = 'get-dialog';
export const getUserInfo = 'get-user-info';
export const validatePurchase = 'validate-purchase';
export const deleteAccount = 'delete-account';
export const cancelAccountDeletion = 'cancel-account-deletion';
export const timeSync = 'timesync';
export const updateDialogMutedList = 'update-muted-list';
export const updatePin = 'update-pin';
export const updatePartnerName = 'update-partner-name';
export const restorePurchase = 'restore-purchase';
export const callRequest = 'call-request';
export const declineCall = 'on-decline-call';
export const acceptCall = 'on-accept-call';
export const canMakeCall = 'can-make-call';
export const updateClientFlags = 'update-clients-flags';
export const updateClientFlagsV2 = 'update-clients-flags-v2';
export const getPartnersPublicInfo = 'get-partners-public-info';
export const performanceTest = 'performance-test';
export const partnerMadeScreenshot = 'partner-made-screenshot';
export const hangUpCall = 'hang-up-call';
export const webRTC = 'webrtc';
export const updateForbidToCall = 'update-forbid-to-call';
export const getInterests = 'get-interests';
export const getInterestsV2 = 'get-interests-v2';
export const getFAQ = 'getFAQ';
export const rateAppClick = 'rate-app-click';
export const rateAppClickV2 = 'rate-app-click-v2';
export const cleanDialog = 'clean-dialog';
export const acceptDialog = 'accept-dialog';
export const closeMyDialog = 'closeMyDialog';
export const closeAndDeleteMine = 'closeAndDeleteMine';
export const closeAndDeleteAll = 'closeAndDeleteAll';
export const voisaBannerClick = 'voisaBannerClick';
export const loadMoreRemoteMessages = 'load-more-remote-messages';
export const sendSubscriptions = 'send-subscriptions';
export const fetchClientSubscriptions = 'fetch-client-subscriptions';
export const triggerInAppReview = 'trigger-in-app-review';
export const triggerAppReviewBanner = 'trigger-app-review-banner';
export const listenVoiceMessage = 'listen-voice-message';
export const sendAppCheckToken = 'send-app-check-token';
export const openOneTimeImage = 'open-onetime-image';
export const openOneTimeVideo = 'open-onetime-video';
export const linkAccount = 'link-account';
export const unlinkAccount = 'unlink-account';
export const restoreLinkedAccount = 'restore-linked-account';
export const linkNewAndDeleteOldAccount = 'link-new-and-delete-old-account';
export const showCaptcha = 'showCaptcha';
export const verifyCaptcha = 'verifyCaptcha';
export const regenerateCaptcha = 'regenerateCaptcha';
export const acceptTermsOfUse = 'accept-terms-of-use';
export const reactToMessage = 'react-to-message';
export const reportDebugMessage = 'report-debug-message';
export const cancelUnitpaySubscription = 'cancel-unitpay-subscription';
export const updateUnitpaySubscription = 'update-unitpay-subscription';
export const getIsSearching = 'get-is-searching';
export const updateFlag = 'update-flag';
