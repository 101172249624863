import './SearchAnimation.css';
import React, {ReactElement} from 'react';

export const SearchAnimation = (): ReactElement => {
    return (
        <div className={'SearchAnimation-container'}>
            <div className={'SearchAnimation-circle first'} />
            <div className={'SearchAnimation-circle second'} />
            <div className={'SearchAnimation-circle third'} />
        </div>
    );
};
