import {deleteMessage as deleteMessageEvent} from '../../shared/events';
import {socketEmit} from '../../socket/socketEmit';
import {SharedError} from '../../shared/errors-types';
import {MessageAny} from '../../shared/message-any';
import {store} from '../../redux/store';
import {addMessage} from '../../redux/reducers/dialogsReducer';

export const deleteMessage = async (messageId: string, dialogId: string, deleteForBoth: boolean): Promise<void> => {
    return new Promise((resolve, reject) => {
        socketEmit(
            deleteMessageEvent,
            {
                messageId: messageId,
                deleteForBoth: deleteForBoth,
                dialogId: dialogId,
            },
            (err: SharedError | null, message: MessageAny) => {
                if (err) {
                    console.log('Error in deleteMessage', err);
                    reject(err);
                    return;
                }
                store.dispatch(addMessage({dialogId, message}));
            }
        );
    });
};
