export const en = {
    lookingForPartner: 'Looking for a partner',
    findPartnerTitle: 'Find a person to chat with',
    findPartnerSubtitleBeginning: 'Here you can find a partner to chat with.',
    findPartnerSubTitle: 'Anonymous, secure, fun',
    startSearch: 'Start searching',
    stopSearch: 'Stop searching',
    male: 'Male',
    female: 'Female',
    dunno: 'Unknown',
    partnerGender: 'Partner gender',
    interestsTitle: 'Interests',
    clearInterests: 'Clear interests',
    online: 'online',
    wasJustNow: 'was just now',
    typing: 'typing',
    recording: 'recording voice message',
    choosingImage: 'choosing image',
    takingPhoto: 'taking photo',
    me: 'Me',
    username: 'Anonymous',
    lastMessageVoice: 'Voice message',
    lastMessageImage: 'Image',
    edited: 'edited',
    todaySeparator: '[Today]',
    yesterdaySeparator: '[Yesterday]',
    popUpEdit: 'Edit',
    popUpDelete: 'Delete',
    popUpCopy: 'Copy',
    popUpReply: 'Reply',
    popUpDeleteForMe: 'Delete for me',
    popUpDeleteForPartner: 'Delete for both',
    complaint: 'Report',
    closeDialog: 'Close chat',
    addToFriends: 'Offer friendship',
    removeFromFriends: 'Cancel request',
    newPartner: 'New partner',
    acceptFriendRequest: 'Become friends',
    previewReplyTitle: 'Reply',
    previewEditTitle: 'Edition',
    deleteFriendTitle: 'Remove friend',
    block: 'Block partner',
    mute: 'Mute',
    unmute: 'Unmute',
    advertisementAndSelling: 'Advertisement and selling',
    childPorn: 'Child porn',
    insulting: 'Insulting',
    violence: 'Violence',
    wrongGender: 'Wrong gender',
    objectionableContent: 'Objectionable content',
    badProfilePicture: 'Inappropriate avatar',
    begging: 'Begging',
    underageUser: 'Underage user',
    scammer: 'Scamming',
    complaintModalTitle: 'Choose report reason',
    reportSentSuccess: 'Report has been sent',
    cancelButton: 'Cancel',
    deleteFriendSaveChat: 'Save chat history',
    deleteFriendDeleteMyChat: 'Delete chat for me',
    deleteFriendDeleteBothChats: 'Delete chat for both',
    deleteFriendDescription:
        "If you remove your friend, you won't be able to chat with them again.\n\nChoose what to do with the chat history:",
    profile: 'Profile',
    gender: 'Gender',
    modalAboutMeTitle: 'Description',
    emptyAboutMeDescription: 'Partner does not have a description',
    temporaryPartner: 'Temporary partner',
    loadingDialogs: 'Loading chats...',
    noFriendsTitle: "You don't have any friends yet",
    noFriendsDescription: 'Find a partner and add them to friends',
    back: 'Back',
    name: 'Nickname',
    writeYourName: 'Write your nickname...',
    aboutMe: 'About me',
    modalDescriptionPlaceholder: 'Tell us about yourself...',
    modalAboutMeHint: 'This description will be displayed to your partner in the beginning of the chat.',
    settingsTitle: 'Settings',
    settingsHelp: 'Support',
    settingsLanguage: 'Language',
    settingsNSFW: 'Safe mode',
    settingsPrivacy: 'Privacy',
    settingsPremium: 'Premium',
    settingsRules: 'Rules',
    settingsSystemInformation: 'System information',
    settingsBottomTipHelp: 'If you have any questions, please contact us',
    settingsBottomTopLanguage: 'You will be matched with partners who have set the same language',
    settingsBottomTopNSFW: 'With safe mode enabled we will hide all incoming images',
    settingsBottomTopPrivacy: 'You can change your privacy settings here',
    settingsBottomTopPremium: 'You can manage your premium here',
    settingsBottomTopRules: 'Read the rules of the service',
    decline: 'Cancel',
    save: 'Save',
    deletedDialog: 'Unfortunately your partner has deleted this chat :(',
    iSee: 'I see',
    dialogNotFound: 'Chat not found',
    goToExplore: 'Return to main page',
    copied: 'copied',
    accountId: 'Account ID',
    appVersion: 'App version',
    commitHash: 'Commit version',
    termsOfUse: 'Terms of use',
    nsfwOff: "Don't blur",
    nsfwTemporary: 'Blur in temporary chats',
    nsfwAll: 'Blur in all chats',
    hideOnlineTitle: 'Hide your online',
    hideOnlineDescription: "If you hide your online, you won't be able to see other users online statuses",
    you: 'You',
    draft: 'Draft',
    lastMessageInlineButton: 'Inline button',
    lastMessageInterests: 'Interests',
    lastMessageCall: 'Call',
    admin: 'Admin',
    anonymousChat: 'Anonymous chat',
};
