import './SettingsGender.css';
import React, {memo, ReactElement, useEffect, useState} from 'react';
import Select, {SingleValue} from 'react-select';
import {Gender} from '../../shared/gender';
import {prettifyGender} from '../../utils/helpers/prettifyGender';
import {updateSettings} from '../../utils/user/updateSettings';
import {getLocalizedString} from '../../utils/helpers/getLocalizedString';

interface Props {
    gender: Gender;
}

type GenderSelectOption = {
    value: Gender;
    label: string;
};

export const SettingsGender = memo((props: Props): ReactElement => {
    const {gender} = props;
    const [selectedOption, setSelectedOption] = useState({
        value: gender,
        label: getLocalizedString(prettifyGender[gender]),
    });

    const genderOptions: GenderSelectOption[] = [
        {value: 'male', label: getLocalizedString(prettifyGender['male'])},
        {value: 'female', label: getLocalizedString(prettifyGender['female'])},
        {value: 'dunno', label: getLocalizedString(prettifyGender['dunno'])},
    ];

    useEffect(() => {
        if (gender !== selectedOption.value) {
            setSelectedOption({value: gender, label: getLocalizedString(prettifyGender[gender])});
        }
    }, [gender, selectedOption.value]);

    const onChange = (newValue: SingleValue<GenderSelectOption>): void => {
        if (newValue) {
            // Optimistic update
            setSelectedOption(newValue);
            updateSettings('gender', newValue.value).catch(() => {
                setSelectedOption({value: gender, label: getLocalizedString(prettifyGender[gender])});
            });
        }
    };
    return (
        <div>
            <span className={'SettingsGenderContainer-title'}>{getLocalizedString('gender')}</span>
            <Select<GenderSelectOption>
                value={selectedOption}
                options={genderOptions}
                onChange={onChange}
                isSearchable={false}
                styles={customStyles}
            />
        </div>
    );
});

const fontStyles = {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Roboto',
};

type selectState = {isSelected: boolean; isFocused: boolean};

const customStyles = {
    container: (base: any) => ({
        ...base,
        width: '90%',
        marginTop: 10,
    }),
    control: (base: any) => ({
        ...base,
        ...fontStyles,
        margin: 0,
        paddingLeft: 5,
        height: 50,
        borderRadius: 12,
        border: 0,
        boxShadow: 'none',
        color: '#201F24',
    }),
    option: (base: any, {isSelected}: selectState) => ({
        ...base,
        ...fontStyles,
        margin: 0,
        paddingLeft: 15,
        height: 50,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: isSelected ? '#97A6F2' : '#201F24',
        backgroundColor: 'white',
        ':active': {backgroundColor: '#F3F3F3'},
        ':hover': {backgroundColor: '#F3F3F3'},
    }),
    menu: (base: any) => ({
        ...base,
        borderRadius: 12,
        overflow: 'hidden',
        margin: 0,
    }),
    indicatorSeparator: () => ({}),
};
