import './ChatProfileInfoSection.css';
import React, {ReactElement} from 'react';

interface Props {
    title: string;
    content: string;
    placeholder?: string;
}

export const ChatProfileInfoSection = (props: Props): ReactElement => {
    const {title, content, placeholder} = props;
    return (
        <div className={'ChatProfileInfoSection-container'}>
            <span className={'ChatProfileInfoSection-title'}>{title}</span>
            <span className={'ChatProfileInfoSection-info'}>{content?.length ? content : placeholder}</span>
        </div>
    );
};
