import {MessageText} from '../../shared/message-text';
import {MessageImage} from '../../shared/message-image';
import {socketEmit} from '../../socket/socketEmit';
import {SharedError} from '../../shared/errors-types';
import {editMessage as editMessageEvent} from '../../shared/events';
import {OnEditMessageInputParams} from '../../shared/callback-types';
import {store} from '../../redux/store';
import {addMessage} from '../../redux/reducers/dialogsReducer';

export const editMessage = async (newText: string, params: OnEditMessageInputParams): Promise<void> => {
    return new Promise((resolve, reject) => {
        const {text, messageId, dialogId} = params;
        // Do nothing if text was not changed
        if (text === newText) {
            resolve();
            return;
        }
        socketEmit(
            editMessageEvent,
            {
                messageId: messageId,
                dialogId: dialogId,
                text: newText,
            },
            (err: SharedError | null, message: MessageText | MessageImage) => {
                if (err) {
                    console.log('Error in editMessage:', err);
                    reject(err);
                    return;
                }
                resolve();
                store.dispatch(addMessage({dialogId, message}));
            }
        );
    });
};
