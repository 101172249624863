import React, {ReactElement} from 'react';
import {Chat} from '../components/Chat/Chat';
import {useParams} from 'react-router-dom';
import {useAppSelector} from '../redux/hooks';
import {DialogNotFound} from '../components/Utils/DialogNotFound';

export const ChatRoute = (): ReactElement | null => {
    const {dialogId} = useParams();
    const doesDialogExist = useAppSelector((state) => state.dialogs.metas[dialogId ?? '']);
    if (!doesDialogExist || !dialogId) {
        return <DialogNotFound dialogId={dialogId} />;
    }

    return <Chat dialogId={dialogId} />;
};
