import './SettingsSelectPickerItem.css';
import React, {ReactElement} from 'react';

interface Props {
    title: string;
    value: string;
    selected: boolean;
    onClick: (value: string) => void;
    border: boolean;
}

export const SettingsSelectPickerItem = (props: Props): ReactElement => {
    const {title, value, selected, onClick, border} = props;
    return (
        <div
            className={`SettingsSelectPickerItem-container ${selected && 'selected'} ${border && 'border'}`}
            onClick={() => onClick(value)}
        >
            <span className={`SettingsSelectPickerItem-span ${selected && 'selected'}`}>{title}</span>
        </div>
    );
};
