import './ExtraButton.css';
import React, {ReactElement} from 'react';

interface Props {
    title: string;
    icon: JSX.Element;
    onPress: () => void;
    isMiddle?: boolean;
}

export const ExtraButton = (props: Props): ReactElement => {
    const {title, icon, isMiddle, onPress} = props;
    return (
        <div className={`ExtraButton-container ${isMiddle && 'ExtraButton-middle-div'}`} onClick={onPress}>
            {icon}
            <span className={'ExtraButton-title-text'}>{title}</span>
        </div>
    );
};
