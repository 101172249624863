import {MessageText} from '../../shared/message-text';
import {MessageImage} from '../../shared/message-image';
import {ClientTemplate} from '../../shared/template';
import {generateMessage} from '../message/generateMessage';

export const generateMessageFromTemplate = async (
    template: ClientTemplate
): Promise<MessageText | MessageImage | null> => {
    let message: MessageText | MessageImage | null;
    if (template.image) {
        message = await generateMessage({
            type: 'image',
            text: template.text,
            width: template.image.width,
            height: template.image.height,
        });
    } else {
        message = await generateMessage({
            type: 'text',
            text: template.text,
        });
    }
    return message;
};
