import {OnPersistDialog} from '../../shared/callback-types';
import {getUserId} from '../../utils/user/getUserId';
import {updatePartnerPublicSettings} from '../../redux/reducers/partnerReducer';
import {makeDialogPermanent} from '../../redux/reducers/dialogsReducer';
import {clearExploreDialogId} from '../../redux/reducers/userReducer';
import {store} from '../../redux/store';

export const onDialogPersist = async (data: OnPersistDialog): Promise<void> => {
    const {partnerSettings, meta, chatId: dialogId} = data;
    const userId = await getUserId();
    const partnerId = meta.users.find((u) => u !== userId);
    store.dispatch(updatePartnerPublicSettings({partnerId: partnerId, publicSettings: partnerSettings}));
    store.dispatch(makeDialogPermanent({dialogId}));
    store.dispatch(clearExploreDialogId());
};
