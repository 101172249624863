import './ChatPreview.css';
import React, {memo, ReactElement} from 'react';
import {Meta} from '../../../shared/callback-types';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {PreviewProfilePicture} from './PreviewProfilePicture';
import {PreviewContent} from './PreviewContent';
import {PreviewTimestamp} from './PreviewTimestamp';
import {useNavigate} from 'react-router-dom';
import {MessageAny} from '../../../shared/message-any';
import {hideSlideMenu, setActiveChat} from '../../../redux/reducers/localSettingsReducer';
import {isServiceChat} from '../../../utils/dialog/checkServiceChats';
import {useDraft} from '../../../utils/hooks/useDraft';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';
import {getPartnerName} from '../../../utils/dialog/getPartnerName';
import {useLastMessage} from '../../../utils/message/useLastMessage';

interface Props {
    dialogId: string;
    meta: Meta;
}

export const ChatPreview = memo((props: Props): ReactElement | null => {
    const {meta, dialogId} = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isChatSelected = useAppSelector((state) => state.localSettings.activeChat === dialogId);
    const userId = useAppSelector((state) => state.user.userId ?? '');
    const partner = useAppSelector((state) => state.partners.partners[meta.partner ?? '']);
    const messages = useAppSelector((state) =>
        Object.values(state.dialogs.messages[dialogId]).filter((msg) => {
            return !msg?.deletedFor?.includes(userId);
        })
    ).sort((a, b) => {
        const createdAtA = new Date(a.createdAt).getTime();
        const createdAtB = new Date(b.createdAt).getTime();
        return createdAtB - createdAtA;
    });
    const lastMessage = useLastMessage(dialogId);
    const isMuted = useAppSelector((state) => Boolean(state.dialogs.metas[dialogId].mutedFor?.includes(userId)));
    const unreadMessagesCount = messages.filter(
        ({status, sender}) => status !== 'read' && sender === partner?.uid
    ).length;
    const isSentByMe = lastMessage?.sender === userId;
    const draft = useDraft(dialogId);
    if (!lastMessage) {
        return null;
    }
    return (
        <>
            <div
                className={'ChatPreview-container'}
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(hideSlideMenu());
                    dispatch(setActiveChat({dialogId}));
                    navigate(`/dialog/${dialogId}`);
                }}
            >
                <PreviewProfilePicture
                    gender={partner?.publicSettings?.gender ?? 'dunno'}
                    profilePicture={partner?.publicSettings?.profilePicture}
                    isTemporaryDialog={meta?.temporary}
                    hasPremium={partner?.hasPremium}
                    isSystemChat={isServiceChat(partner?.uid)}
                />
                <PreviewContent
                    username={getLocalizedString(getPartnerName(dialogId))}
                    lastMessageText={_getLastMessageText(lastMessage)}
                    // Pass draft only if chat isn't selected
                    draft={!isChatSelected ? draft : ''}
                    typingStatus={partner.typingStatus}
                    isSentByMe={isSentByMe}
                    isMuted={isMuted}
                />
                <PreviewTimestamp
                    timestamp={lastMessage?.createdAt}
                    unreadMessagesCount={unreadMessagesCount}
                    isMuted={isMuted}
                />
            </div>
            <div className={'ChatPreview-border-div'} />
        </>
    );
});

const _getLastMessageText = (lastMessage: MessageAny): string => {
    if (!lastMessage) {
        return '';
    }
    switch (lastMessage.type) {
        case 'text':
        case 'system':
        case 'about-me':
            return lastMessage.text;
        case 'image':
            return `🖼 ${getLocalizedString('lastMessageImage')}`;
        case 'voice':
            return `🎙 ${getLocalizedString('lastMessageVoice')}`;
        case 'video':
            return `📹 ${getLocalizedString('lastMessageVideo')}`;
        case 'inline-button':
            return getLocalizedString('lastMessageInlineButton');
        case 'interests':
            return getLocalizedString('lastMessageInterests');
        case 'call':
            return getLocalizedString('lastMessageCall');
    }
};
