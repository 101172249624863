import React, {forwardRef, memo, ReactElement, useCallback} from 'react';
import {FancyModal, ModalRef} from './FancyModal';
import {ModalButton, ModalButtonProps} from './ModalButton';
import {closeDialog} from '../../../utils/dialog/closeDialog';
import {useNavigate} from 'react-router-dom';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    dialogId: string;
}

export const DeleteFriendModal = memo(
    forwardRef<ModalRef, Props>((props, ref): ReactElement => {
        const {dialogId} = props;
        const navigate = useNavigate();
        const options: Omit<ModalButtonProps, 'onPress'>[] = [
            {title: getLocalizedString('deleteFriendSaveChat'), value: 'deleteFriendSaveChat'},
            {title: getLocalizedString('deleteFriendDeleteMyChat'), value: 'deleteFriendDeleteMyChat'},
            {title: getLocalizedString('deleteFriendDeleteBothChats'), value: 'deleteFriendDeleteBothChats'},
        ];

        const onCloseDialog = useCallback(
            (value: string) => {
                switch (value) {
                    case 'deleteFriendSaveChat':
                        closeDialog(dialogId);
                        navigate(-1);
                        break;

                    case 'deleteFriendDeleteMyChat':
                        closeDialog(dialogId, true);
                        navigate('/explore', {replace: true});
                        break;

                    case 'deleteFriendDeleteBothChats':
                        closeDialog(dialogId, true, true);
                        navigate('/explore', {replace: true});
                        break;
                }
            },
            [dialogId, navigate]
        );
        return (
            <FancyModal
                ref={ref}
                title={getLocalizedString('deleteFriendTitle')}
                description={getLocalizedString('deleteFriendDescription')}
            >
                {options.map(({title, value}) => {
                    return <ModalButton key={value} title={title} value={value} onPress={onCloseDialog} />;
                })}
            </FancyModal>
        );
    })
);
