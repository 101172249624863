import './ImageCropModalFooter.css';
import React, {ReactElement} from 'react';
import {getLocalizedString} from '../../../../utils/helpers/getLocalizedString';

interface Props {
    onSave: () => void;
    onCancel: () => void;
}

export const ImageCropModalFooter = (props: Props): ReactElement => {
    const {onSave, onCancel} = props;
    return (
        <div className={'ImageCropModalFooter-container'}>
            <div className={'ImageCropModalFooter-button-common ImageCropModalFooter-button-cancel'} onClick={onCancel}>
                <span>{getLocalizedString('decline')}</span>
            </div>
            <div className={'ImageCropModalFooter-button-common ImageCropModalFooter-button-save'} onClick={onSave}>
                <span>{getLocalizedString('save')}</span>
            </div>
        </div>
    );
};
