import './UserSettings.css';
import React, {memo, ReactElement} from 'react';
import {hideSlideMenu} from '../../../redux/reducers/localSettingsReducer';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {useNavigate} from 'react-router-dom';
import {UserSettingsAvatar} from './UserSettingsAvatar';
import {RiSettings4Fill} from 'react-icons/ri';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

export const UserSettings = memo((): ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const publicSettings = useAppSelector((state) => state.user.data.settings.public);
    const hasPremium = useAppSelector((state) => state.user.data.hasPremium);
    return (
        <div className={'UserSettings-container'}>
            <UserSettingsAvatar
                src={publicSettings?.profilePicture ?? null}
                gender={publicSettings?.gender ?? 'dunno'}
                hasPremium={hasPremium}
            />
            <div className={'UserSettings-username-div'}>
                <span className={'Username-text UserSettings-username-span'}>
                    {publicSettings?.username?.length ? publicSettings?.username : getLocalizedString('username')}
                </span>
            </div>
            <RiSettings4Fill
                className={'UserSettings-settings-icon'}
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(hideSlideMenu());
                    navigate(`/settings`);
                }}
            />
        </div>
    );
});
