import './MessageVoice.css';
import WaveSurfer from 'wavesurfer.js';
import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {BsFillPlayCircleFill, BsPauseCircleFill} from 'react-icons/bs';
import {beautifyDuration} from '../../../../../utils/helpers/beautifyDuration';

interface Props {
    url: string;
    isSentByMe: boolean;
}

export const MessageVoice = (props: Props): ReactElement => {
    const {url, isSentByMe} = props;
    const waveformRef = useRef<HTMLDivElement>(null);
    const wavesurfer = useRef<WaveSurfer>();
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState<number>();
    const [progress, setProgress] = useState<number>();

    useEffect(() => {
        if (waveformRef.current) {
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                partialRender: true,
                mediaType: 'audio',
                waveColor: '#CBD2F8',
                progressColor: isSentByMe ? 'white' : '#97A6F2',
                cursorColor: '#97A6F2',
                height: 30,
                barWidth: 2,
                barRadius: 2,
                barMinHeight: 1,
                cursorWidth: 2,
            });
            wavesurfer.current.load(url);
            wavesurfer.current.on('ready', () => {
                setDuration(wavesurfer.current?.getDuration());
            });
            wavesurfer.current.on('play', () => {
                setIsPlaying(true);
            });
            wavesurfer.current.on('pause', () => {
                setIsPlaying(false);
            });
            wavesurfer.current.on('audioprocess', (_progress) => {
                setProgress(_progress);
            });
        }
        return () => {
            wavesurfer.current?.unAll();
        };
    }, [isSentByMe, url]);
    const senderRelatedStyles = isSentByMe ? 'user' : 'partner';
    const playPauseIconProps = {
        onClick: () => wavesurfer.current?.playPause(),
        className: `MessageVoice-play ${senderRelatedStyles}`,
    };
    return (
        <div className={'MessageVoice-container'} onClick={(e) => e.stopPropagation()}>
            {isPlaying ? (
                <BsPauseCircleFill {...playPauseIconProps} />
            ) : (
                <BsFillPlayCircleFill {...playPauseIconProps} />
            )}
            <div ref={waveformRef} className={'MessageVoice-waveform'} />
            <span className={`MessageVoice-duration ${senderRelatedStyles}`}>
                {isPlaying || progress ? beautifyDuration(progress ?? 0) : beautifyDuration(duration ?? 0)}
            </span>
        </div>
    );
};
