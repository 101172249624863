import './MessageTimestamp.css';
import moment from 'moment';
import React, {memo, ReactElement} from 'react';

interface Props {
    sentAt: Date;
    isSentByMe: boolean;
}

export const MessageTimestamp = memo((props: Props): ReactElement => {
    const {sentAt, isSentByMe} = props;
    return (
        <span
            className={`MessageTimestamp-sent-at-text ${
                isSentByMe ? 'MessageTimestamp-user' : 'MessageTimestamp-partner'
            }`}
        >
            {moment(sentAt).format('HH:mm')}
        </span>
    );
});
