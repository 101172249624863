import './OptionsPickerItem.css';
import React, {ReactElement} from 'react';

interface Props {
    title: string;
    isActive: boolean;
    isDisabled: boolean;
    onPress: () => void;
}

export const OptionsPickerItem = (props: Props): ReactElement => {
    const {title, onPress, isActive, isDisabled} = props;
    return (
        <div
            className={`OptionsPickerItem-container 
            ${isActive && 'active'}
            ${isDisabled && 'disabled'}
           `}
            onClick={() => {
                if (isDisabled) return;
                onPress();
            }}
        >
            <span
                className={`OptionsPickerItem-span 
            ${isActive && 'active'}
            ${isDisabled && 'disabled'}
            `}
            >
                {title}
            </span>
        </div>
    );
};
