import React, {ReactElement, useEffect, useState} from 'react';
import {PopoverMenu} from '../../Utils/PopoverMenu/PopoverMenu';
import {PopOverOptionProps} from '../../Utils/PopoverMenu/PopOverOption';
import {BsFillPencilFill, BsReplyFill} from 'react-icons/bs';
import {checkText} from '../../../utils/message/checkMessage';
import {IoCopy} from 'react-icons/io5';
import {AiFillDelete} from 'react-icons/ai';
import {hidePopOver, updateMessagePreview} from '../../../redux/reducers/localSettingsReducer';
import {deleteMessage} from '../../../utils/message/deleteMessage';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {Point} from '../../../types/point';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    dialogId: string;
}

export const ChatHistoryPopover = (props: Props): ReactElement | null => {
    const {dialogId} = props;
    const {popOverProps, isMenuVisible} = useChatHistoryPopover();
    const [isDeletionMenuVisible, setIsDeletionMenuVisible] = useState(false);
    const [deletionMenuPosition, setDeletionMenuPosition] = useState({top: 0, left: 0});
    const message = useAppSelector((state) => {
        if (popOverProps) {
            return state.dialogs.messages[dialogId][popOverProps?.messageId];
        }
    });
    const isSentByMe = useAppSelector((state) => state.user.userId === message?.sender);
    const dispatch = useAppDispatch();

    if (!message || !popOverProps) {
        return null;
    }

    const position = {
        top: popOverProps.point.y,
        left: popOverProps.point.x,
    };

    const options: Omit<PopOverOptionProps, 'onPress'>[] = [
        {
            title: getLocalizedString('popUpReply'),
            value: 'reply',
            icon: <BsReplyFill className={'PopoverMenu-icon'} />,
        },
        {
            title: getLocalizedString('popUpEdit'),
            value: 'edit',
            icon: <BsFillPencilFill className={'PopoverMenu-icon'} />,
            disabled: !isSentByMe && checkText(message),
        },
        {
            title: getLocalizedString('popUpCopy'),
            value: 'copy',
            icon: <IoCopy className={'PopoverMenu-icon'} />,
            disabled: !('text' in message && message.text?.length),
        },
        {
            title: getLocalizedString('popUpDelete'),
            value: 'delete',
            icon: <AiFillDelete className={'PopoverMenu-icon'} />,
        },
    ];

    const deletionOptions: Omit<PopOverOptionProps, 'onPress'>[] = [
        {
            title: getLocalizedString('popUpDeleteForMe'),
            value: 'deleteForMe',
            icon: <AiFillDelete className={'PopoverMenu-icon'} />,
        },
        {
            title: getLocalizedString('popUpDeleteForPartner'),
            value: 'deleteForBoth',
            icon: <AiFillDelete className={'PopoverMenu-icon'} />,
        },
    ];

    const hideAllMenus = () => {
        dispatch(hidePopOver());
        setIsDeletionMenuVisible(false);
    };

    const onOptionPress = (value: string, point: Point): void => {
        switch (value) {
            case 'copy':
                if (message.type === 'text' || message.type === 'image') {
                    navigator.clipboard.writeText(message.text ?? '');
                }
                hideAllMenus();
                break;
            case 'reply':
                dispatch(
                    updateMessagePreview({
                        action: 'reply',
                        messageId: popOverProps.messageId,
                        dialogId: dialogId,
                    })
                );
                hideAllMenus();
                break;
            case 'edit':
                dispatch(
                    updateMessagePreview({
                        action: 'edit',
                        messageId: popOverProps.messageId,
                        dialogId: dialogId,
                    })
                );
                hideAllMenus();
                break;
            case 'delete':
                if (!isSentByMe) {
                    deleteMessage(message.msgId, dialogId, false);
                    hideAllMenus();
                } else {
                    setDeletionMenuPosition({
                        top: point.y,
                        left: point.x,
                    });
                    setIsDeletionMenuVisible(true);
                }
                break;
            case 'deleteForMe':
                deleteMessage(message.msgId, dialogId, false);
                hideAllMenus();
                break;
            case 'deleteForBoth':
                deleteMessage(message.msgId, dialogId, true);
                hideAllMenus();
                break;
        }
    };
    return (
        <>
            <PopoverMenu
                isVisible={isMenuVisible}
                options={options}
                onOptionPress={onOptionPress}
                onBackdropPress={() => {
                    dispatch(hidePopOver());
                    setIsDeletionMenuVisible(false);
                }}
                position={position}
            />
            <PopoverMenu
                isVisible={isDeletionMenuVisible}
                options={deletionOptions}
                onOptionPress={onOptionPress}
                onBackdropPress={() => {
                    dispatch(hidePopOver());
                    setIsDeletionMenuVisible(false);
                }}
                position={deletionMenuPosition}
            />
        </>
    );
};

const useChatHistoryPopover = () => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const popOverProps = useAppSelector((state) => state.localSettings.showPopOverMenu);

    useEffect(() => {
        setIsMenuVisible(Boolean(popOverProps));
    }, [popOverProps]);

    return {
        isMenuVisible,
        popOverProps,
    };
};
