import './ModalButton.css';
import React, {ReactElement} from 'react';

export interface ModalButtonProps {
    title: string;
    value: string;
    onPress: (value: string) => void;
}

export const ModalButton = (props: ModalButtonProps): ReactElement => {
    const {title, value, onPress} = props;
    return (
        <div className={'ModalButton-div'} onClick={() => onPress(value)}>
            <span className={'ModalButton-span ModalButton-span-hovered'}>{title}</span>
        </div>
    );
};
