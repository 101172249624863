import * as React from 'react';

export function NewPartnerIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={15} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.327.756a.56.56 0 00-.41-.173.561.561 0 00-.41.173l-1.185 1.176A7.06 7.06 0 0010.089.506 6.878 6.878 0 007.5 0C5.83 0 4.37.506 3.125 1.518 1.88 2.529 1.06 3.849.665 5.478v.064a.28.28 0 00.086.205.28.28 0 00.205.086h1.813c.134 0 .225-.07.274-.21.255-.607.416-.962.483-1.066a4.595 4.595 0 011.695-1.631A4.587 4.587 0 017.5 2.333c1.221 0 2.282.417 3.181 1.249L9.423 4.84a.562.562 0 00-.173.41c0 .158.058.295.173.41a.561.561 0 00.41.173h4.084a.56.56 0 00.41-.173.56.56 0 00.173-.41V1.167a.56.56 0 00-.173-.41zM13.98 8.167h-1.75c-.133 0-.225.07-.273.21-.255.607-.416.962-.483 1.066a4.599 4.599 0 01-1.695 1.631 4.585 4.585 0 01-2.279.593c-.59 0-1.158-.11-1.704-.328a4.706 4.706 0 01-1.468-.93L5.577 9.16a.56.56 0 00.173-.41.56.56 0 00-.173-.41.56.56 0 00-.41-.174H1.083a.56.56 0 00-.41.174.56.56 0 00-.173.41v4.084a.56.56 0 00.173.41.562.562 0 00.41.173.561.561 0 00.41-.173l1.176-1.176a6.96 6.96 0 002.22 1.43c.83.335 1.688.502 2.575.502 1.665 0 3.117-.506 4.356-1.518 1.24-1.011 2.054-2.332 2.443-3.96a.28.28 0 00-.078-.269.282.282 0 00-.205-.086z"
                fill="#201F24"
            />
        </svg>
    );
}
