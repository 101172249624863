import {socketEmit} from '../../socket/socketEmit';
import {updateDialogMutedList} from '../../shared/events';
import {store} from '../../redux/store';
import {updateMutedFor} from '../../redux/reducers/dialogsReducer';

export const toggleMute = async (dialogId: string, isMuted: boolean): Promise<void> => {
    const state = store.getState();
    return new Promise((resolve, reject) => {
        socketEmit(updateDialogMutedList, {dialogId, isMuted}, (err: Error | null, success: boolean) => {
            if (err) {
                console.log('Error in toggleMute:', err.message);
                reject(err);
                return;
            }
            const meta = state.dialogs.metas[dialogId];
            const userId = state.user.userId;
            let mutedFor = meta.mutedFor ?? [];
            if (success) {
                // If dialog was muted then remove userId from the muted list
                // Otherwise push it to the array
                if (isMuted) {
                    mutedFor = meta?.mutedFor?.filter((uid) => userId !== uid) ?? [];
                } else {
                    mutedFor = [...(meta.mutedFor ?? []), userId ?? ''];
                }
                store.dispatch(updateMutedFor({dialogId, mutedFor}));
            }
        });
    });
};
