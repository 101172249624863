import './SettingsSelectPicker.css';
import React, {ReactElement} from 'react';
import {SettingsSelectPickerItem} from './SettingsSelectPickerItem';

interface PickerItem {
    title: string;
    value: string;
}

interface Props {
    options: PickerItem[];
    onClick: (value: string) => void;
    selectedItem: string;
}

export const SettingsSelectPicker = (props: Props): ReactElement => {
    const {options, selectedItem, onClick} = props;
    return (
        <div className={'SettingsSelectPicker-container'}>
            {options.map(({value, title}, idx) => {
                return (
                    <SettingsSelectPickerItem
                        key={value}
                        value={value}
                        title={title}
                        selected={selectedItem === value}
                        onClick={onClick}
                        border={idx !== options.length - 1}
                    />
                );
            })}
        </div>
    );
};
