import {loginApiKey} from '../../shared/salt';
import {LOGIN_URL} from '../../constants/paths';
import {setCookie} from '../cookie/setCookie';
import {store} from '../../redux/store';
import {activateAdmin} from '../../redux/reducers/adminReducer';
import {ADMIN_LANGUAGE, ADMIN_LOGIN} from '../../constants/localStorageKeys';
import {clearUser} from '../user/clearUser';
import {getSocket} from '../../socket/createSocket';
import {fetchUpdates} from '../fetchData/fetchUpdates';

export const login = async (login: string, password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        const data = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login: login,
                password: password,
                key: loginApiKey,
            }),
        };
        // Send post request to the server
        fetch(LOGIN_URL, data)
            .then((res) => {
                if (res.status === 400) {
                    reject(new Error('Wrong login or password'));
                    return;
                }
                return res.json();
            })
            .then(async (parsedResponse) => {
                clearUser();
                localStorage.setItem(ADMIN_LOGIN, login);
                localStorage.setItem(ADMIN_LANGUAGE, parsedResponse.language);
                setCookie(parsedResponse.cookie);
                await getSocket();
                await fetchUpdates();
                store.dispatch(activateAdmin());
                resolve();
            })
            .catch((err) => reject(err));
    });
};
