import {socketEmit} from '../../socket/socketEmit';
import {AdminPublicUserInfo, PublicUserInfo} from '../../shared/callback-types';
import {SharedError} from '../../shared/errors-types';
import {getPartnersPublicInfo} from '../../shared/events';
import {store} from '../../redux/store';
import {upsertPartner} from '../../redux/reducers/partnerReducer';

export const fetchPartnersInfo = () => {
    return new Promise((resolve, reject) => {
        socketEmit(
            getPartnersPublicInfo,
            async (err: SharedError | null, data: {partnersInfo: Array<PublicUserInfo | AdminPublicUserInfo>}) => {
                if (err) {
                    console.log(`Error in fetchPartnersInfo: ${err?.code}`);
                    reject(err);
                    return;
                }
                data.partnersInfo.forEach((partnerPublicInfo) => {
                    store.dispatch(upsertPartner({partnerPublicInfo}));
                });
            }
        );
    });
};
