import {socketEmit} from '../../socket/socketEmit';
import {Meta} from '../../shared/callback-types';
import {SharedError} from '../../shared/errors-types';
import {dialogLiked} from '../../shared/events';

export const sendFriendRequest = async (dialogId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        socketEmit(dialogLiked, dialogId, async (err: SharedError | null, success: boolean, _: Date, meta: Meta) => {
            try {
                if (err) {
                    console.log(err);
                    reject();
                    return;
                }
            } finally {
                resolve();
            }
        });
    });
};
