import * as React from 'react';

interface Props {
    color: string;
}

export function ReadStatus(props: Props): JSX.Element {
    return (
        <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.683 3.267L3.5 9.45 1.05 7l-.817.821L3.5 11.088l7-7-.817-.821zM12.95 3.267L6.824 9.45l-.467-.467-.817.816 1.284 1.284 6.997-7-.873-.816z"
                fill={props.color}
            />
        </svg>
    );
}
