import {isDev} from './isDev';

export const getServerUrl = (): string => {
    return 'https://anonchatapi.stivisto.com';
    if (isDev()) {
        return 'http://localhost:5000';
    } else {
        return 'https://anonchatapi.stivisto.com';
    }
};
