import {MessageAny} from '../../shared/message-any';
import {MessageText} from '../../shared/message-text';
import {MessageImage} from '../../shared/message-image';
import {MessageSystem} from '../../shared/message-system';
import {MessageAboutMe} from '../../shared/message-about-me';
import {MessageInterests} from '../../shared/message-interests';

// Function that convinces TypeScript that message has text field
export const checkText = (message: MessageAny): message is MessageText | MessageImage => {
    return 'text' in message;
};

// Function that convinces TypeScript that message has replyTo field
export const checkReply = (message: MessageAny): message is MessageSystem | MessageAboutMe | MessageInterests => {
    return !('replyTo' in message);
};
