import {readMessages as readMessagesEvent} from '../../shared/events';
import {SharedError} from '../../shared/errors-types';
import {socketEmit} from '../../socket/socketEmit';
import {readMessages as reduxReadMessages} from '../../redux/reducers/dialogsReducer';
import {store} from '../../redux/store';

export const readMessages = async (dialogId: string, messagesIds: string[]): Promise<void> => {
    return new Promise((resolve, reject) => {
        socketEmit(
            readMessagesEvent,
            {
                messagesIds: messagesIds,
                dialogId: dialogId,
            },
            async (err: SharedError | null, readMessagesIds: string[]) => {
                if (err) {
                    console.log(err);
                    reject(err);
                    return;
                }
                if (readMessagesIds) {
                    store.dispatch(reduxReadMessages({dialogId: dialogId, messagesIds: readMessagesIds}));
                }
            }
        );
    });
};
