import './ChatHistory.css';
import _ from 'lodash';
import React, {forwardRef, memo, ReactElement, useEffect} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import {Message} from './Message/Message';
import {MessageAny} from '../../../shared/message-any';
import {getUserId} from '../../../utils/user/getUserId';
import {readMessages} from '../../../utils/message/readMessages';
import {wereSentOnDifferentDays} from '../../../utils/message/wereSentOnDifferentDays';
import {ChatHistoryPopover} from './ChatHistoryPopover';

interface Props {
    dialogId: string;
}

export const ChatHistory = memo(
    forwardRef<HTMLDivElement, Props>((props, ref): ReactElement | null => {
        const {dialogId} = props;
        const userId = useAppSelector((state) => state.user.userId ?? '');
        const messages = useAppSelector((state) =>
            _filterMessages(userId, Object.values(state.dialogs.messages[dialogId]))
        );
        const amountOfMessages = Object.keys(messages ?? {}).length;

        useEffect(() => {
            _throttledMessageReading(dialogId, Object.values(messages));
        }, [amountOfMessages, dialogId, messages]);

        if (!messages) {
            return null;
        }

        return (
            <div ref={ref} className={'ChatHistory-container'}>
                {messages.map((message, index) => {
                    // Get neighbor message
                    const prevMessage = messages[index + 1];
                    // Check were they sent on different days or not
                    const wereSentOnNewDay =
                        prevMessage && wereSentOnDifferentDays(prevMessage.createdAt, message.createdAt);
                    const shouldShowDaySeparator = wereSentOnNewDay || index === messages.length - 1;
                    return (
                        <Message
                            key={message.msgId}
                            dialogId={dialogId}
                            message={message}
                            shouldShowDaySeparator={shouldShowDaySeparator}
                        />
                    );
                })}
                <ChatHistoryPopover dialogId={dialogId} />
            </div>
        );
    })
);

const _handleMessageReading = async (dialogId: string, messages: MessageAny[]): Promise<void> => {
    const userId = await getUserId();
    if (!userId) {
        return;
    }
    const messagesToRead = messages
        .filter(({status, sender}) => status !== 'read' && sender !== userId)
        .map(({msgId}) => msgId);

    if (messagesToRead.length === 0) {
        return;
    }

    readMessages(dialogId, messagesToRead);
};

const _throttledMessageReading = _.throttle(_handleMessageReading, 300, {
    leading: false,
    trailing: true,
});

const _filterMessages = (userId: string, messages: MessageAny[]): MessageAny[] => {
    return messages
        .filter((message) => {
            return !message?.deletedFor?.includes(userId);
        })
        .sort((a, b) => {
            const createdAtA = new Date(a.createdAt).getTime();
            const createdAtB = new Date(b.createdAt).getTime();
            return createdAtB - createdAtA;
        });
};
