import './EmojiPanel.css';
import React, {ReactElement} from 'react';

interface Props {
    isVisible: boolean;
    onEmojiPress: (emoji: string) => void;
}

const EMOJIS = ['💔', '❤️', '😅', '🤔', '🙏'];

export const EmojiPanel = (props: Props): ReactElement | null => {
    const {isVisible, onEmojiPress} = props;

    if (!isVisible) {
        return null;
    }

    return (
        <div className={'EmojiPanel-container'}>
            {EMOJIS.map((emoji) => {
                return (
                    <div
                        className={'EmojiPanel-emoji-container'}
                        key={emoji}
                        onClick={(e) => {
                            e.stopPropagation();
                            onEmojiPress(emoji);
                        }}
                    >
                        {emoji}
                    </div>
                );
            })}
        </div>
    );
};
