import {GET_USER_ID} from '../../constants/paths';
import {getCookie} from '../cookie/getCookie';
import {registerAccount} from '../helpers/registerAccount';
import {store} from '../../redux/store';
import {saveUserId} from '../../redux/reducers/userReducer';
import {generateDeviceId} from '../helpers/generateDeviceId';

export const getUserId = async (): Promise<string | null> => {
    const userId = store.getState().user.userId;
    if (userId) {
        return userId;
    }

    let cookie = getCookie();
    if (!cookie) {
        cookie = await registerAccount();
    }

    const data = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            // TODO: implement deviceId
            deviceId: generateDeviceId(),
            cookie: cookie,
        }),
    };
    // Send post request to the server
    const res = await fetch(GET_USER_ID, data);
    if (res) {
        const resJson = await res.json();
        if (resJson && resJson.userId) {
            store.dispatch(saveUserId({userId: resJson.userId}));
            return resJson.userId;
        }
    }
    return null;
};
