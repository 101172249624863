import './ChatPreview/ChatPreview.css';
import './ExplorePreview.css';
import './ChatPreview/PreviewContent.css';
import React, {ReactElement} from 'react';
import {ExplorePreviewIcon} from '../../assets/svg/ExplorePreviewIcon';
import {ExplorePreviewArrow} from '../../assets/svg/ExplorePreviewArrow';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../redux/hooks';
import {hideSlideMenu, setActiveChat} from '../../redux/reducers/localSettingsReducer';
import {getLocalizedString} from '../../utils/helpers/getLocalizedString';

export const ExplorePreview = (): ReactElement => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return (
        <>
            <div
                className={'ChatPreview-container ExplorePreview-container'}
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(hideSlideMenu());
                    dispatch(setActiveChat({dialogId: null}));
                    navigate(`/explore`);
                }}
            >
                <div className={'ExplorePreview-img ExplorePreview-icon-div ExplorePreview-icon-div-hovered'}>
                    <ExplorePreviewIcon />
                </div>
                <div className={'ExplorePreview-username-div'}>
                    <span className={'Username-text PreviewContent-username'}>
                        {getLocalizedString('temporaryPartner')}
                    </span>
                </div>
                <div className={'ExplorePreview-arrow'}>
                    <ExplorePreviewArrow />
                </div>
            </div>
            <div className={'ExplorePreview-border-div'} />
        </>
    );
};
