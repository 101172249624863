import * as React from 'react';

export function SendButton(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={14} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.039 1.24c0-.617.484-1.117 1.08-1.117.598 0 1.082.5 1.082 1.117v15.643C8.201 17.5 7.717 18 7.12 18s-1.081-.5-1.081-1.117V1.24z"
                fill="#fff"
            />
            <path
                d="M6.35 2.025a1.144 1.144 0 010-1.58 1.056 1.056 0 011.53 0l5.803 5.998a1.144 1.144 0 010 1.58 1.057 1.057 0 01-1.529 0L6.35 2.026z"
                fill="#fff"
            />
            <path
                d="M7.763 1.907a1.144 1.144 0 000-1.58 1.056 1.056 0 00-1.528 0L.317 6.443a1.144 1.144 0 000 1.58 1.056 1.056 0 001.529 0l5.917-6.116z"
                fill="#fff"
            />
        </svg>
    );
}
