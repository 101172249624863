import './SettingsPageBase.css';
import React, {PropsWithChildren, ReactElement} from 'react';
import {PageHeader} from '../Utils/PageHeader';
import {getLocalizedString} from '../../utils/helpers/getLocalizedString';

interface Props {
    title?: string;
}

export const SettingsPageBase = (props: PropsWithChildren<Props>): ReactElement => {
    const {children, title} = props;
    return (
        <div className={'SettingsPageBase-container'}>
            <PageHeader title={title ?? getLocalizedString('settingsTitle')} />
            {children}
        </div>
    );
};

export const SettingsPageBaseAligned = (props: PropsWithChildren<Props>): ReactElement => {
    const {children, title} = props;
    return (
        <div className={'SettingsPageBase-container'}>
            <PageHeader title={title ?? getLocalizedString('settingsTitle')} />
            <div className={'SettingsPageBase-content'}>{children}</div>
        </div>
    );
};
