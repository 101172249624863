import {getSocket} from './createSocket';

const RETRY_COUNT = 3;

export async function socketEmit(event: string, ...args: any[]): Promise<void> {
    socketEmitRetryWrapper(event, 0, ...args);
}

async function socketEmitRetryWrapper(event: string, retry: number, ...args: any[]) {
    // Extract original callback out of emit arguments, if it exists
    // eslint-disable-next-line
    let originalCallback: (...args: any[]) => void;
    const lastArg = args[args.length - 1];
    if (typeof lastArg === 'function') {
        originalCallback = lastArg;
        args.pop(); // Remove callback from the arguments, because we inject our own callback
    } else {
        // If the callback did not exist, substitute with a dummy function
        originalCallback = () => {};
    }

    // Track performance of the request
    const socket = await getSocket();

    if (!socket) {
        return;
    }
    // eslint-disable-next-line
    socket.timeout(10000).emit(event, ...args, async (err: Error | null, ...callbackArgs: any[]) => {
        if (err && err?.message === 'operation has timed out') {
            if (retry < RETRY_COUNT) {
                socketEmitRetryWrapper(event, ++retry, ...args, originalCallback);
            }
            return;
        }
        originalCallback(...callbackArgs);
    });
}
