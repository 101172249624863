import './MessageEditedLabel.css';
import React, {ReactElement} from 'react';
import {getLocalizedString} from '../../../../../utils/helpers/getLocalizedString';

interface Props {
    isEdited: boolean;
    isSentByMe: boolean;
}

export const MessageEditedLabel = (props: Props): ReactElement | null => {
    const {isEdited, isSentByMe} = props;

    if (!isEdited) {
        return null;
    }
    return (
        <span className={`MessageEditedLabel-span ${isSentByMe ? 'user' : 'partner'}`}>
            {getLocalizedString('edited')}
        </span>
    );
};
