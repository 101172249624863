import {socketEmit} from '../../socket/socketEmit';
import {UserSettings} from '../../shared/callback-types';
import {SharedError} from '../../shared/errors-types';
import {changeSettings} from '../../shared/events';
import {Gender} from '../../shared/gender';
import {store} from '../../redux/store';
import {updateUserSettings} from '../../redux/reducers/userReducer';

export async function updateSettings(
    settingsToChange: string,
    newValue: Gender | boolean | string | null
): Promise<void> {
    return new Promise((resolve, reject) => {
        socketEmit(
            changeSettings,
            {
                settingsToChange,
                newValue,
            },
            async (err: SharedError | null, lastUpdatedAt: Date, settings: UserSettings) => {
                if (err) {
                    console.log('Error in updateSettings', err);
                    reject(err);
                    return;
                }
                resolve();
                store.dispatch(updateUserSettings({settings}));
            }
        );
    });
}
