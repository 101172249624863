import {socketEmit} from '../../socket/socketEmit';
import {startSearch} from '../../shared/events';
import {store} from '../../redux/store';
import {startSearching as startSearchingAction} from '../../redux/reducers/userReducer';

export const startSearching = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
        socketEmit(startSearch, {}, (err: Error | null) => {
            if (err) {
                console.log('start search error', err);
                reject(err);
                return;
            }
            resolve();
            store.dispatch(startSearchingAction());
        });
    });
};
