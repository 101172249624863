import './BurgerMenuTrigger.css';
import React, {ReactElement} from 'react';
import {AiOutlineMenu} from 'react-icons/ai';
import {useAppDispatch} from '../../redux/hooks';
import {showSlideMenu} from '../../redux/reducers/localSettingsReducer';

export const BurgerMenuTrigger = (): ReactElement => {
    const dispatch = useAppDispatch();
    return (
        <div
            className={'BurgerMenuTrigger-container'}
            onClick={(e) => {
                e.stopPropagation();
                dispatch(showSlideMenu());
            }}
        >
            <AiOutlineMenu className={'BurgerMenuTrigger-icon'} size={30} />
        </div>
    );
};
