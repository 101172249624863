import './ProfilePicture.css';
import React, {CSSProperties, ReactElement, useRef} from 'react';
import {ImageModal} from './Modals/ImageModal';
import {ModalRef} from './Modals/FancyModal';

interface Props {
    profilePictureUrl: any | null;
    isClickable: boolean;
    hasPremium: boolean;
    height?: number;
    width?: number;
    extraStyles?: CSSProperties;
    imageModalChildren?: JSX.Element;
}

export const ProfilePicture = (props: Props): ReactElement => {
    const {profilePictureUrl, width, height, isClickable, extraStyles, imageModalChildren, hasPremium} = props;
    const imageModalRef = useRef<ModalRef>(null);
    const premiumBorderStyles = {
        borderColor: '#FDD835',
        borderStyle: 'solid',
        borderWidth: hasPremium ? 2 : 0,
    };
    return (
        <>
            <img
                src={profilePictureUrl}
                alt={'profilePicture'}
                onClick={() => isClickable && imageModalRef.current?.open()}
                style={{
                    borderRadius: '50%',
                    margin: 20,
                    objectFit: 'cover',
                    maxWidth: width,
                    maxHeight: height,
                    minWidth: width,
                    minHeight: height,
                    alignSelf: 'center',
                    flexGrow: 0,
                    cursor: isClickable ? 'pointer' : undefined,
                    ...premiumBorderStyles,
                    ...extraStyles,
                }}
            />
            <ImageModal ref={imageModalRef} src={profilePictureUrl}>
                {imageModalChildren}
            </ImageModal>
        </>
    );
};
