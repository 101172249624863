export const ru = {
    lookingForPartner: 'Ищем собеседника',
    findPartnerTitle: 'Найдите себе собеседника',
    findPartnerSubtitleBeginning: 'Здесь вы можете найти собеседника.',
    findPartnerSubTitle: 'Анонимно, безопасно, весело',
    startSearch: 'Начать поиск',
    stopSearch: 'Остановить поиск',
    male: 'Мужской',
    female: 'Женский',
    dunno: 'Не выбран',
    partnerGender: 'Пол собеседника',
    interestsTitle: 'Интересы',
    clearInterests: 'Очистить интересы',
    online: 'в сети',
    wasJustNow: 'был только что',
    typing: 'набирает сообщение',
    recording: 'записывает голосовое',
    choosingImage: 'выбирает картинку',
    takingPhoto: 'делает фотографию',
    me: 'Я',
    username: 'Аноним',
    lastMessageVoice: 'Голосовое сообщение',
    lastMessageImage: 'Изображение',
    edited: 'изменено',
    todaySeparator: '[Сегодня]',
    yesterdaySeparator: '[Вчера]',
    popUpEdit: 'Изменить',
    popUpDelete: 'Удалить',
    popUpCopy: 'Скопировать',
    popUpReply: 'Ответить',
    popUpDeleteForMe: 'Удалить у меня',
    popUpDeleteForPartner: 'Удалить у обоих',
    closeDialog: 'Завершить диалог',
    addToFriends: 'Стать друзьями',
    removeFromFriends: 'Отменить заявку',
    newPartner: 'Новый собеседник',
    complaint: 'Пожаловаться',
    acceptFriendRequest: 'Стать друзьями',
    previewReplyTitle: 'Ответ',
    previewEditTitle: 'Редактирование',
    deleteFriendTitle: 'Удалить из друзей',
    block: 'Заблокировать',
    mute: 'Выкл. уведомления',
    unmute: 'Вкл. уведомления',
    advertisementAndSelling: 'Реклама и продажа',
    childPorn: 'Детская порнография',
    insulting: 'Оскорбление',
    violence: 'Насилие',
    wrongGender: 'Неверный пол',
    objectionableContent: 'Нежелательный контент',
    badProfilePicture: 'Неуместная аватарка',
    begging: 'Попрошайничество',
    underageUser: 'Несовершеннолетний пользователь',
    scammer: 'Мошенничество',
    complaintModalTitle: 'Выберите причину жалобы',
    reportSentSuccess: 'Жалоба успешно отправлена',
    cancelButton: 'Закрыть',
    deleteFriendSaveChat: 'Сохранить переписку',
    deleteFriendDeleteMyChat: 'Удалить переписку у меня',
    deleteFriendDeleteBothChats: 'Удалить переписку у всех',
    deleteFriendDescription:
        'Если вы удалите собеседника из друзей, то больше не сможете с ним общаться.\n\nВыберите, что делать с перепиской после удаления из друзей:',
    profile: 'Профиль',
    gender: 'Пол',
    modalAboutMeTitle: 'Описание',
    emptyAboutMeDescription: 'У собеседника нет описания',
    temporaryPartner: 'Временный собеседник',
    loadingDialogs: 'Загружаем диалоги...',
    noFriendsTitle: 'У вас пока нет друзей',
    noFriendsDescription: 'Начните общение с людьми, которые вам нравятся, и добавляйте их в друзья.',
    back: 'Назад',
    name: 'Никнейм',
    writeYourName: 'Введите никнейм...',
    aboutMe: 'О себе',
    modalDescriptionPlaceholder: 'Расскажите о себе...',
    modalAboutMeHint: 'Это описание отобразится вашим собеседникам в самом начале диалога.',
    settingsTitle: 'Настройки',
    settingsHelp: 'Помощь',
    settingsLanguage: 'Язык',
    settingsNSFW: 'Безопасный режим',
    settingsPrivacy: 'Приватность',
    settingsPremium: 'Премиум-подписка',
    settingsRules: 'Правила',
    settingsSystemInformation: 'Системная информация',
    settingsBottomTipHelp: 'Ответим на ваши вопросы про АнонЧат',
    settingsBottomTopLanguage: 'Собеседники в поиске будут находиться согласно выбранному языку',
    settingsBottomTopNSFW: 'Будем скрывать входящие картинки',
    settingsBottomTopPrivacy: 'Нажмите сюда чтобы удалить аккаунт или скрыть онлайн',
    settingsBottomTopPremium: 'Нажмите сюда для управления подпиской',
    settingsBottomTopRules: 'Узнайте правила сообщества АнонЧат',
    decline: 'Отменить',
    save: 'Сохранить',
    deletedDialog: 'К сожалению, ваш собеседник удалил этот диалог :(',
    iSee: 'Понятно',
    dialogNotFound: 'Диалог не найден',
    goToExplore: 'Вернуться на главную',
    copied: 'скопирован(а)',
    accountId: 'ID аккаунта',
    appVersion: 'Версия приложения',
    commitHash: 'Версия коммита',
    termsOfUse: 'Пользовательское соглашение',
    nsfwOff: 'Не скрывать',
    nsfwTemporary: 'Скрывать во временных диалогах',
    nsfwAll: 'Скрывать везде',
    hideOnlineTitle: 'Скрывать свой онлайн',
    hideOnlineDescription: 'Скрыв свой онлайн-статус, вы не будете видеть онлайн статус собеседников',
    you: 'Вы',
    draft: 'Черновик',
    lastMessageInlineButton: 'Инлайн кнопка',
    lastMessageInterests: 'Интересы',
    lastMessageCall: 'Звонок',
    admin: 'Администратор',
    anonymousChat: 'Анонимный чат',
};
