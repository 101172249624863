import {getUserId} from '../user/getUserId';
import {isUserAdmin} from '../dialog/checkServiceChats';

export async function withAdminRights<T>(fn: () => Promise<T>): Promise<T | null> {
    const userId = await getUserId();
    const isAdmin = isUserAdmin(userId);

    // If user isn't admin return null;
    if (!isAdmin) {
        return null;
    }

    return fn();
}
