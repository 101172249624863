import {createSlice} from '@reduxjs/toolkit';
import {ADMIN_LOGIN} from '../../constants/localStorageKeys';
import {deleteCookie} from '../../utils/cookie/deleteCookie';

type AdminInitialState = {
    isAdmin: boolean;
    login: string | null;
    templates: string[];
};

const initState: AdminInitialState = {
    isAdmin: false,
    login: localStorage.getItem(ADMIN_LOGIN),
    templates: [],
};

export const adminSlice = createSlice({
    name: 'admin',
    initialState: initState,
    reducers: {
        activateAdmin: (state) => {
            state.isAdmin = true;
            state.login = localStorage.getItem(ADMIN_LOGIN);
        },
        logoutAdmin: (state) => {
            state.isAdmin = false;
            state.login = null;
            localStorage.removeItem(ADMIN_LOGIN);
            deleteCookie();
        },
        updateTemplates: (state, action) => {
            const {templates} = action.payload;
            state.templates = templates;
        },
    },
});

export const {activateAdmin, updateTemplates, logoutAdmin} = adminSlice.actions;

export const adminReducer = adminSlice.reducer;
