import * as React from 'react';

export function CrossIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#prefix__clip0_4421_1524)" fill="#77767D">
                <path d="M1.75 17.7A1.025 1.025 0 01.3 16.25L16.25.3a1.025 1.025 0 111.45 1.45L1.75 17.7z" />
                <path d="M17.7 16.25a1.025 1.025 0 11-1.45 1.45L.3 1.75A1.025 1.025 0 011.75.3L17.7 16.25z" />
            </g>
            <defs>
                <clipPath id="prefix__clip0_4421_1524">
                    <path fill="#fff" d="M0 0h18v18H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
