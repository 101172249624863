import {STIVISTO_DRIVE_URL} from '../../shared/stivito-drive-url';

export const uploadFileToDrive = async (file: File | Blob): Promise<string | undefined> => {
    const base64 = await _blobToBase64(file);
    const res = await fetch(STIVISTO_DRIVE_URL, {
        method: 'POST',
        body: JSON.stringify({
            body: base64,
            content_type: 'image/jpeg',
        }),
    });

    if (res && res.status === 200) {
        return (await res.json()).url;
    }
    return undefined;
};

function _blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            let base64 = reader.result;
            if (base64 && typeof base64 === 'string') {
                base64 = base64.substring(base64.indexOf(',') + 1);
                resolve(base64);
            } else {
                resolve('');
            }
        };
        reader.readAsDataURL(blob);
    });
}
