import './SettingsContainer.css';
import React, {PropsWithChildren, ReactElement} from 'react';

interface Props {
    title: string;
}

export const SettingsContainer = (props: PropsWithChildren<Props>): ReactElement => {
    const {title, children} = props;
    return (
        <div className={'SettingsContainer-container'}>
            <span className={'SettingsContainer-title'}>{title}</span>
            <div className={'SettingsContainer-body'}>{children}</div>
        </div>
    );
};
