import {Language} from '../../shared/language';
import moment from 'moment/moment';
import {getLocalizedString} from './getLocalizedString';

export const beautifyMessageTimestamp = (timestamp: Date, language: Language): string =>
    moment(timestamp)
        .locale(language)
        .calendar({
            sameDay: getLocalizedString('todaySeparator'),
            lastDay: getLocalizedString('yesterdaySeparator'),
            lastWeek: 'D MMM',
            sameElse: () => {
                const wasMessageSentInThisYear = moment().diff(timestamp, 'year') === 0;
                return wasMessageSentInThisYear ? 'D MMM' : 'll';
            },
        });

export const beautifyLastMessageInChatTimestamp = (timestamp: Date, language: Language): string =>
    moment(timestamp)
        .locale(language)
        .calendar({
            sameDay: 'HH:mm',
            lastDay: getLocalizedString('yesterdaySeparator'),
            lastWeek: 'D MMM',
            sameElse: () => {
                const wasMessageSentInThisYear = moment().diff(timestamp, 'year') === 0;
                return wasMessageSentInThisYear ? 'D MMM' : 'll';
            },
        });
