import './PreviewContent.css';
import './PreviewTimestamp.css';
import React, {ReactElement} from 'react';
import {beautifyLastMessageInChatTimestamp} from '../../../utils/helpers/beautifyMessageTimestamp';
import {useAppSelector} from '../../../redux/hooks';

interface Props {
    timestamp: Date;
    unreadMessagesCount: number;
    isMuted: boolean;
}

export const PreviewTimestamp = (props: Props): ReactElement => {
    const {timestamp, unreadMessagesCount, isMuted} = props;
    const language = useAppSelector((state) => state.user.data.language ?? 'en');
    return (
        <div className={'PreviewTimestamp-container'}>
            <span className={'PreviewContent-last-message-text'}>
                {beautifyLastMessageInChatTimestamp(timestamp, language)}
            </span>
            <div
                className={`PreviewTimestamp-unread-messages-count-div
                    ${unreadMessagesCount === 0 ? 'PreviewTimestamp-hide' : ''}
                    ${isMuted && 'muted'}`}
            >
                <span className={`PreviewTimestamp-unread-messages-count-span`}>{unreadMessagesCount}</span>
            </div>
        </div>
    );
};
