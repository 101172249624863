import {store} from '../../redux/store';
import {loadDialog} from '../fetchData/loadDialog';

export const loadDialogIfNeeded = async (dialogId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (doesDialogExist(dialogId)) {
            resolve();
            return;
        }
        resolve(loadDialog({dialogId}));
    });
};

export const doesDialogExist = (dialogId: string): boolean => {
    const metas = store.getState().dialogs.metas;
    return Boolean(metas[dialogId]);
};
