import {socketEmit} from '../../socket/socketEmit';
import {updateUser as updateUserEvent} from '../../shared/events';

export const updateUser = async (key: string, value: any): Promise<void> => {
    return new Promise((resolve, reject) => {
        socketEmit(
            updateUserEvent,
            {
                propertyToChange: key,
                newValue: value,
            },
            (err: Error | null) => {
                if (err) {
                    reject(err);
                    console.log('Error in updateUser', err.message);
                    return;
                }
                resolve();
            }
        );
    });
};

export const optimisticUpdateUser = async (
    key: string,
    value: any,
    dispatch: (val: any) => void,
    currValue: any
): Promise<void> => {
    // Optimistic update
    dispatch(value);
    return updateUser(key, value).catch(() => {
        dispatch(currValue);
    });
};
