import {CREATE_ACCOUNT} from '../../constants/paths';
import {VERSION} from '../../constants/version';
import {generateDeviceId} from './generateDeviceId';
import {setCookie} from '../cookie/setCookie';

export const registerAccount = async (): Promise<string | null> => {
    const data = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: {
                systemLanguage: 'ru',
                version: VERSION,
                platform: 'web',
                // TODO: implement deviceId
                deviceId: generateDeviceId(),
            },
        }),
    };
    const res = await fetch(CREATE_ACCOUNT, data);
    if (res) {
        const resJson = await res.json();
        if (resJson) {
            setCookie(resJson.cookie);
            return resJson.cookie;
        }
    }
    return null;
};
