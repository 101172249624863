import {configureStore} from '@reduxjs/toolkit';
import {userReducer} from './reducers/userReducer';
import {partnersReducer} from './reducers/partnerReducer';
import {dialogsReducer} from './reducers/dialogsReducer';
import {localSettingsReducer} from './reducers/localSettingsReducer';
import {adminReducer} from './reducers/adminReducer';

export const store = configureStore({
    reducer: {
        user: userReducer,
        partners: partnersReducer,
        dialogs: dialogsReducer,
        localSettings: localSettingsReducer,
        admin: adminReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
