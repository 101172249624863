import {getCookie} from '../cookie/getCookie';
import {GET_TEMPLATES} from '../../constants/paths';
import {store} from '../../redux/store';
import {updateTemplates} from '../../redux/reducers/adminReducer';
import {withAdminRights} from '../helpers/withAdminRights';
import {ADMIN_LANGUAGE} from '../../constants/localStorageKeys';

export const loadTemplates = async (): Promise<void> => {
    // Create local function
    const getTemplates = async (retry = 0): Promise<void> => {
        const state = store.getState();
        const adminLanguage = localStorage.getItem(ADMIN_LANGUAGE);
        // If no language in redux then it's not time to load templates
        if (!adminLanguage && retry < 10) {
            retry++;
            setTimeout(() => getTemplates(retry), 250);
            return;
        }
        const data = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cookie: getCookie(),
                admin: state.admin.login,
                language: adminLanguage,
            }),
        };
        const res = await fetch(GET_TEMPLATES, data);
        const resJson = await res.json();
        if (resJson?.length) {
            store.dispatch(updateTemplates({templates: resJson}));
        }
    };
    // Execute local function with withAdminRights wrapper
    withAdminRights(getTemplates);
};
