import {useAppSelector} from '../../redux/hooks';
import {getLastDialogMessage} from '../dialog/getLastDialogMessage';

export function useLastMessage(dialogId: string) {
    const userId = useAppSelector((state) => state.user.userId ?? '');
    const lastMessage = useAppSelector((state) =>
        getLastDialogMessage(userId, Object.values(state.dialogs.messages[dialogId]))
    );

    return lastMessage;
}
