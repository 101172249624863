import {MessageAny} from '../../shared/message-any';

export const getLastDialogMessage = (userId: string, messages: MessageAny[]): MessageAny | null => {
    const visibleMessages = messages.filter((msg) => !msg?.deletedFor?.includes(userId));
    // Should never happen because every dialog has at least one system message
    if (visibleMessages.length === 0) {
        return null;
    }
    return visibleMessages.sort((a, b) => {
        const createdAtA = new Date(a.createdAt).getTime();
        const createdAtB = new Date(b.createdAt).getTime();
        return createdAtB - createdAtA;
    })[0];
};
