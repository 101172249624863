import * as React from 'react';

interface Props {
    color?: string;
}

export function BackButtonIcon(props: Props): JSX.Element {
    return (
        <svg width={9} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.125 14.417L1 8l6.125-6.417"
                stroke={props.color ?? '#201F24'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
