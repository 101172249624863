import './Username.css';
import React, {ReactElement, useRef} from 'react';
import {Toast, ToastRef} from '../../Utils/Toast';
import {generateAdminWebLink} from '../../../utils/admin/generateAdminWebLink';
import {AiFillAndroid, AiFillApple} from 'react-icons/ai';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';
import {useIsAdmin} from '../../../utils/user/isUserAdmin';

interface Props {
    partnerId: string;
    username: string;
    isTemporary: boolean;
    platform: string;
}

export const Username = (props: Props): ReactElement => {
    const {username, isTemporary, partnerId, platform} = props;
    const toastRef = useRef<ToastRef>(null);
    const name = isTemporary || username?.length === 0 ? getLocalizedString('username') : username;
    const isAdmin = useIsAdmin();
    return (
        <>
            <div className={'Username-container'}>
                <span
                    className={'Username-text'}
                    onClick={(e) => {
                        if (isAdmin) {
                            e.preventDefault();
                            e.stopPropagation();
                            global.navigator.clipboard.writeText(partnerId);
                            toastRef.current?.show();
                        }
                    }}
                    onContextMenu={(e) => {
                        if (isAdmin) {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(generateAdminWebLink(partnerId), '_blank');
                        }
                    }}
                >
                    {name}
                </span>
                {isAdmin && <AdminPlatformIcon platform={platform} />}
            </div>
            <Toast ref={toastRef} title={'Айди юзера скопирован'} />
        </>
    );
};

const AdminPlatformIcon = ({platform}: {platform: string}): ReactElement => {
    if (platform === 'ios') {
        return <AiFillApple />;
    } else {
        return <AiFillAndroid />;
    }
};
