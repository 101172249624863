import React, {ReactElement} from 'react';
import './SideMenu.css';
import {ChatList} from '../../components/ChatList/ChatList';
import {useAppSelector} from '../../redux/hooks';

export const SideMenu = (): ReactElement => {
    const isVisible = useAppSelector((state) => state.localSettings.shouldShowSlideMenu);
    return (
        <div className={`SideMenu-container ${isVisible ? 'show' : 'hide'}`}>
            <ChatList />
        </div>
    );
};
