import * as React from 'react';

export function ExplorePreviewIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 1.5c-2.07 0-3.947.84-5.303 2.197A7.479 7.479 0 001.5 9c0 2.07.84 3.947 2.197 5.303A7.479 7.479 0 009 16.5c2.07 0 3.947-.84 5.303-2.197A7.479 7.479 0 0016.5 9c0-2.07-.84-3.947-2.197-5.303A7.479 7.479 0 009 1.5zm0 3.75a.75.75 0 01.75.75v2.69l1.28 1.28a.75.75 0 11-1.06 1.06l-1.5-1.5A.75.75 0 018.25 9V6A.75.75 0 019 5.25z"
                fill="#77767D"
            />
        </svg>
    );
}
