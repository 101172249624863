import {RefObject} from 'react';

export const moveCaretToEnd = (elementRef: RefObject<HTMLDivElement>): void => {
    // Get child nodes length
    const childNodes = elementRef.current?.childNodes?.length;
    if (childNodes) {
        const range = document.createRange();
        const sel = window.getSelection();
        // Get last child node
        const childNode = elementRef.current?.childNodes[childNodes - 1];
        // Set selection to the last line after last character
        range.setStart(childNode, childNode?.textContent?.length ?? 0);
        range.collapse(false);
        sel?.removeAllRanges();
        sel?.addRange(range);
    }
};
