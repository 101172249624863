import './ChatHeader.css';
import React, {memo, ReactElement} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import {HeaderProfilePicture} from './HeaderProfilePicture';
import {Username} from './Username';
import {useNavigate} from 'react-router-dom';
import {BurgerMenuTrigger} from '../../Utils/BurgerMenuTrigger';
import {ChatHeaderStatus} from './ChatHeaderStatus';
import {Spacer} from '../../Utils/Spacer';
import {isServiceChat, isUserAdmin} from '../../../utils/dialog/checkServiceChats';
import {getPartnerName} from '../../../utils/dialog/getPartnerName';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    dialogId: string;
}

export const ChatHeader = memo((props: Props): ReactElement | null => {
    const {dialogId} = props;
    const navigate = useNavigate();
    const meta = useAppSelector((state) => state.dialogs.metas[dialogId]);
    const partner = useAppSelector((state) => state.partners.partners[meta.partner ?? '']);

    return (
        <div
            className={`ChatHeader-container ${isUserAdmin(partner.uid) && 'admin'}`}
            onClick={() => {
                if (isUserAdmin(partner.uid)) return;
                navigate('profile');
            }}
        >
            <HeaderProfilePicture
                gender={partner?.publicSettings?.gender ?? 'dunno'}
                profilePictureUrl={partner?.publicSettings?.profilePicture}
                isTemporaryDialog={meta?.temporary}
                hasPremium={partner?.hasPremium}
                isSystemChat={isServiceChat(partner?.uid)}
            />
            <div className={'ChatHeader-content-div'}>
                <Username
                    partnerId={partner?.uid}
                    username={getLocalizedString(getPartnerName(dialogId))}
                    isTemporary={Boolean(meta?.temporary)}
                    platform={partner?.platform}
                />
                <ChatHeaderStatus lastSeenStatus={partner?.lastSeenStatus} typingStatus={partner?.typingStatus} />
            </div>
            <Spacer />
            <BurgerMenuTrigger />
        </div>
    );
});
