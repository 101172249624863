import './NoFriends.css';
import './ChatList.css';
import './ChatPreview/PreviewProfilePicture.css';
import React, {ReactElement} from 'react';
import {NoFriendsIcon} from '../../assets/svg/NoFriendsIcon';
import {getLocalizedString} from '../../utils/helpers/getLocalizedString';

export const NoFriends = (): ReactElement => {
    return (
        <div className={'NoFriends-container'}>
            <div className={'ExplorePreview-icon-div NoFriends-icon-wrapper'}>
                <NoFriendsIcon />
            </div>
            <div className={'NoFriends-text-div'}>
                <span className={'Username-text NoFriends-container-title'}>
                    {getLocalizedString('noFriendsTitle')}
                </span>
                <span className={'NoFriends-description-span'}>{getLocalizedString('noFriendsDescription')}</span>
            </div>
        </div>
    );
};
