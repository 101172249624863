import {socketEmit} from '../../socket/socketEmit';
import {sendComplaint} from '../../shared/events';
import {SharedError} from '../../shared/errors-types';

export const sendReport = async (dialogId: string, complaintId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        socketEmit(
            sendComplaint,
            {
                dialogId: dialogId,
                complaintId: complaintId,
            },
            (err: SharedError | null) => {
                if (err) {
                    console.log('Error in sendReport', err);
                    reject(err);
                    return;
                }
                resolve();
            }
        );
    });
};
