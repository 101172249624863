import './ImageModal.css';
import React, {forwardRef, PropsWithChildren, ReactElement, useImperativeHandle, useState} from 'react';
import {ModalRef} from './FancyModal';
import {BasicModal} from './BasicModal';

export interface ImageModalData {
    src: string;
    caption?: string;
}

interface ImageModalProps extends ImageModalData {}

export const ImageModal = forwardRef<ModalRef, PropsWithChildren<ImageModalProps>>(
    (props, ref): ReactElement | null => {
        const {src, caption, children} = props;
        const [isOpen, setIsOpen] = useState(false);
        useImperativeHandle(ref, () => ({
            open: () => setIsOpen(true),
            close: () => setIsOpen(false),
        }));

        return (
            <BasicModal
                isVisible={isOpen}
                onBackdropPress={() => setIsOpen(false)}
                backdropStyles={'ImageModal-backdrop'}
            >
                <div className={'ImageModal-container'} onClick={() => setIsOpen(false)}>
                    <img className={'ImageModal-image'} src={src} alt={'full screen modal with selected item'} />
                    {caption && <span className={'ImageModal-caption'}>{caption}</span>}
                    {children}
                </div>
            </BasicModal>
        );
    }
);
