import {getSocket} from '../../socket/createSocket';
import {getCookie} from '../cookie/getCookie';
import {getServerUrl} from './getServerUrl';

interface UploadFileParams {
    file: File | Blob;
    messageType: 'voice' | 'image';
    dialogId: string;
    msgId: string;
}

export async function uploadFile(params: UploadFileParams): Promise<Response | null> {
    const {file, messageType, dialogId, msgId} = params;
    // Create socket before upload a file to avoid 6 limit connection per domain
    // https://docs.pushtechnology.com/cloud/latest/manual/html/designguide/solution/support/connection_limitations.html
    await getSocket();
    const body = new FormData();
    const cookie = getCookie();
    if (!cookie) {
        console.log('No cookie was provided');
        return null;
    }
    body.append('cookie', cookie);
    body.append('messageId', msgId);
    body.append('dialogId', dialogId);
    body.append('type', messageType);
    body.append('file', file);

    return fetch(getServerUrl(), {
        method: 'POST',
        body: body,
    });
}
