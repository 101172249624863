import './SendButton.css';
import React, {ReactElement} from 'react';
import {SendButton as SendButtonSvg} from '../../../../assets/svg/SendButton';

export interface SendButtonProps {
    shouldDisableSend: boolean;
    handleClick: () => void;
}

export const SendButton = (props: SendButtonProps): ReactElement => {
    const {shouldDisableSend, handleClick} = props;
    return (
        <div className={`SendButton-button ${shouldDisableSend && 'disabled'}`} onClick={handleClick}>
            <SendButtonSvg />
        </div>
    );
};
