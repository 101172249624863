import './PopoverMenu.css';
import React, {ReactElement} from 'react';
import {createPortal} from 'react-dom';
import {PopOverOption, PopOverOptionProps} from './PopOverOption';
import {Point} from '../../../types/point';

interface Props {
    isVisible: boolean;
    options: Omit<PopOverOptionProps, 'onPress'>[];
    onOptionPress: (value: string, point: Point) => void;
    onBackdropPress?: () => void;
    position: {
        top: number;
        left: number;
    };
}

export const PopoverMenu = (props: Props): ReactElement | null => {
    const {isVisible, position, options, onOptionPress, onBackdropPress} = props;

    if (!isVisible) {
        return null;
    }

    return createPortal(
        <>
            <div
                className={'PopoverMenu-backdrop'}
                onClick={(e) => {
                    e.stopPropagation();
                    onBackdropPress?.();
                }}
            />
            <div className={'PopoverMenu-container'} style={position}>
                {options.map((option) => {
                    return <PopOverOption key={option.value} {...option} onPress={onOptionPress} />;
                })}
            </div>
        </>,
        document.getElementById('root')!
    );
};
