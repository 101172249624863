import {UpdateMeta} from '../../shared/callback-types';
import {store} from '../../redux/store';
import {upsertPartner} from '../../redux/reducers/partnerReducer';
import {stopSearching, updateExploreDialogId} from '../../redux/reducers/userReducer';
import {addDialog} from '../../redux/reducers/dialogsReducer';
import {isSystemChat, isUserAdmin} from '../../utils/dialog/checkServiceChats';

export const onPartnerFind = async (data: UpdateMeta): Promise<void> => {
    const {_id, partnerPublicInfo, meta} = data;
    store.dispatch(addDialog({meta: meta, dialogId: _id, partnerId: partnerPublicInfo.uid}));
    store.dispatch(upsertPartner({partnerPublicInfo}));

    const partnerId = partnerPublicInfo.uid;
    const userId = store.getState().user.userId;
    if (isUserAdmin(userId) || isUserAdmin(partnerId) || isSystemChat(partnerId)) {
        return;
    }

    store.dispatch(updateExploreDialogId({dialogId: _id}));
    store.dispatch(stopSearching());
};
