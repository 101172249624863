import './SearchButton.css';
import React, {ReactElement} from 'react';
import {useAppSelector} from '../../redux/hooks';
import {startSearching} from '../../utils/user/startSearching';
import {stopSearching} from '../../utils/user/stopSearching';
import {BasicButton} from '../Utils/Buttons/BasicButton';
import {getLocalizedString} from '../../utils/helpers/getLocalizedString';

interface Props {}

export const SearchButton = (props: Props): ReactElement => {
    const isSearching = useAppSelector((state) => state.user.isSearching);
    return (
        <BasicButton
            title={getLocalizedString(isSearching ? 'stopSearch' : 'startSearch')}
            onClick={() => {
                if (!isSearching) {
                    startSearching();
                } else {
                    stopSearching();
                }
            }}
        />
    );
};
