import {getCookie} from '../cookie/getCookie';
import {GET_TEMPLATE_ITEM} from '../../constants/paths';
import {withAdminRights} from '../helpers/withAdminRights';
import {ClientTemplate} from '../../shared/template';

export const getTemplateData = async (name: string, dialogId: string): Promise<ClientTemplate | null> => {
    const _getTemplateData = async (): Promise<ClientTemplate | null> => {
        const data = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                item: name,
                chatId: dialogId,
                cookie: getCookie(),
            }),
        };
        const res = await fetch(GET_TEMPLATE_ITEM, data);
        if (!res.ok) {
            return null;
        }
        return res.json();
    };
    return withAdminRights(_getTemplateData);
};
