import './SettingsItemCopeable.css';
import React, {ReactElement, useRef} from 'react';
import {SettingsItem} from './SettingsItem';
import {Spacer} from '../../../Utils/Spacer';
import {BiCopy} from 'react-icons/bi';
import {Toast, ToastRef} from '../../../Utils/Toast';
import {getLocalizedString} from '../../../../utils/helpers/getLocalizedString';

interface Props {
    value: string;
    subtitle: string;
}

export const SettingsItemCopeable = (props: Props): ReactElement => {
    const {value, subtitle} = props;
    const toastRef = useRef<ToastRef>(null);

    return (
        <>
            <SettingsItem
                bottomTip={subtitle}
                onClick={() => {
                    global.navigator.clipboard.writeText(value);
                    toastRef.current?.show();
                }}
            >
                <span className={'SettingsItemCopeable-title'}>{value}</span>
                <Spacer />
                <BiCopy className={'SettingsItemCopeable-icon'} />
            </SettingsItem>
            <Toast ref={toastRef} title={`${subtitle} ${getLocalizedString('copied')}`} />
        </>
    );
};
