import './ChatFooter.css';
import React, {memo, ReactElement, useEffect, useRef, useState} from 'react';
import {MessageInput} from './MessageInput/MessageInput';
import {ActionButton} from './ActionButton/ActionButton';
import {generateMessage} from '../../../utils/message/generateMessage';
import {sendImage, sendMessage, sendVoice} from '../../../utils/message/sendMessage';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {addMessage} from '../../../redux/reducers/dialogsReducer';
import {AttachButton} from './AttachButton';
import {UploadingImagePreview} from './UploadingImagePreview';
import {AttachButtonRefType} from '../../../types/attachButtonRefType';
import {MessageText} from '../../../shared/message-text';
import {MessageImage} from '../../../shared/message-image';
import {getImageSize} from '../../../utils/message/getImageSize';
import {clearDraft, clearMessagePreview} from '../../../redux/reducers/localSettingsReducer';
import {editMessage} from '../../../utils/message/editMessage';
import {checkText} from '../../../utils/message/checkMessage';
import {ChatAdminTemplates} from '../ChatAdminTemplates/ChatAdminTemplates';
import {useRecorder} from '../../../utils/hooks/useRecorder';
import {useActionOnNewLocation} from '../../../utils/hooks/useActionOnNewLocation';
import {MessageVoice} from '../../../shared/message-voice';
import {MessagePreview} from '../../../types/messagePreview';
import {MessageAny} from '../../../shared/message-any';
import {usePrevious} from '../../../utils/hooks/usePrevious';
import {Recorder} from '../../../types/recorder';
import {moveCaretToEnd} from '../../../utils/helpers/moveCaretToEnd';
import {useDraft} from '../../../utils/hooks/useDraft';
import {isFileImage} from '../../../utils/helpers/isFileImage';
import {EmojiButton} from './EmojiButton/EmojiButton';
import {ChatAdminGptTemplate} from '../ChatAdminGptTemplate/ChatAdminGptTemplate';

interface Props {
    dialogId: string;
    isClosed: boolean;
    scrollToChatEnd: () => void;
}

export const ChatFooter = memo((props: Props): ReactElement | null => {
    const {dialogId, isClosed, scrollToChatEnd} = props;
    const dispatch = useAppDispatch();
    const [messageText, setMessageText] = useState('');
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const {recorderState, ...handlers} = useRecorder();
    const messageInputRef = useRef<HTMLDivElement>(null);
    const inputImageRef = useRef<AttachButtonRefType>(null);
    const messagePreviewState = useAppSelector((state) => state.localSettings.messagePreviews[dialogId]);
    // Subscribe to message when user tries to edit it
    const messageToEdit = useAppSelector((state) => {
        if (messagePreviewState?.action === 'edit') {
            return state.dialogs.messages[dialogId][messagePreviewState?.messageId];
        }
    });

    const clearMessageText = (): void => {
        if (messageInputRef?.current) {
            messageInputRef.current.innerText = '';
        }
        setMessageText('');
    };

    useActionOnNewLocation(() => {
        clearMessageText();
        messageInputRef.current?.focus();
    });

    useHandleMessageInput(dialogId, setMessageText, messagePreviewState, messageInputRef, messageToEdit);

    useHandleRecordingState(messagePreviewState, recorderState, handlers.cancelRecording, () => {
        clearMessageText();
        clearInputFile();
    });

    const clearInputFile = (): void => {
        setSelectedImage(null);
        inputImageRef.current?.clearValue();
    };
    const shouldShowRecord = messageText.length === 0 && !selectedImage && !messagePreviewState;
    // Disable send if there's no text in messageInput
    const shouldDisableSend = Boolean(
        shouldShowRecord || (messageToEdit && messageToEdit?.type !== 'image' && messageText.length === 0)
    );

    const generateProperMessage = async (): Promise<MessageText | MessageImage | MessageVoice | null> => {
        const replyTo = (messagePreviewState?.action === 'reply' && messagePreviewState.messageId) || undefined;
        if (selectedImage) {
            const imageSize = await getImageSize(selectedImage);
            return generateMessage({
                type: 'image',
                text: messageText,
                replyTo,
                ...imageSize,
            });
        } else if (recorderState.audio) {
            return generateMessage({
                type: 'voice',
                replyTo,
            });
        }
        return generateMessage({
            type: 'text',
            text: messageText,
            replyTo,
        });
    };

    const handleClick = async (): Promise<void> => {
        try {
            // Handle message edition
            if (messagePreviewState?.action === 'edit' && messageToEdit && checkText(messageToEdit)) {
                editMessage(messageText, {
                    messageId: messageToEdit?.msgId,
                    text: messageToEdit.text,
                    dialogId,
                });
                return;
            }

            const message = await generateProperMessage();
            if (!message) {
                return;
            }

            switch (message.type) {
                case 'text':
                    dispatch(addMessage({dialogId, message}));
                    sendMessage(dialogId, message);
                    break;
                case 'image':
                    sendImage(dialogId, message, selectedImage!);
                    break;
                case 'voice':
                    sendVoice(dialogId, message, recorderState.audio!);
                    break;
            }
        } finally {
            dispatch(clearDraft({dialogId}));
            // Use finally to clean up states
            dispatch(clearMessagePreview({dialogId}));
            clearMessageText();
            clearInputFile();
            handlers.cancelRecording();
            scrollToChatEnd();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>): void => {
        if (e.clipboardData.files.length) {
            const file = e.clipboardData.files[0];
            if (isFileImage(file)) {
                setSelectedImage(file);
                setTimeout(() => clearMessageText());
            }
        }
    };

    const onEmojiPress = (emoji: string): void => {
        setMessageText((val) => {
            if (messageInputRef.current) {
                messageInputRef.current.innerText = val + emoji;
            }
            return val + emoji;
        });
    };

    const onGptTemplatePress = (text: string): void => {
        setMessageText((val) => {
            if (messageInputRef.current) {
                messageInputRef.current.innerText = text;
            }
            return text;
        });
    };

    if (isClosed) {
        return null;
    }

    return (
        <>
            <ChatAdminTemplates dialogId={dialogId} messageText={messageText} scrollToChatEnd={scrollToChatEnd} />
            <ChatAdminGptTemplate dialogId={dialogId} onPress={onGptTemplatePress} />
            <div className={'ChatFooter-container'} onPaste={handlePaste}>
                <div className={'ChatFooter-toolbar-div'}>
                    <AttachButton ref={inputImageRef} setSelectedImage={setSelectedImage} />
                    <MessageInput
                        ref={messageInputRef}
                        dialogId={dialogId}
                        disabled={shouldDisableSend}
                        messageText={messageText}
                        setMessageText={setMessageText}
                        onClick={handleClick}
                        messagePreviewState={messagePreviewState}
                        recorderState={recorderState}
                        handlers={handlers}
                    />
                    <EmojiButton onEmojiPress={onEmojiPress} />
                    <ActionButton
                        handlers={handlers}
                        recorderState={recorderState}
                        shouldDisableSend={shouldDisableSend}
                        shouldShowRecord={shouldShowRecord}
                        handleClick={handleClick}
                    />
                </div>
                <UploadingImagePreview selectedImage={selectedImage} clearImage={clearInputFile} />
            </div>
        </>
    );
});

export const useHandleMessageInput = (
    dialogId: string,
    setMessageText: (value: ((prevState: string) => string) | string) => void,
    messagePreviewState: MessagePreview | null,
    messageInputRef: React.RefObject<HTMLDivElement>,
    messageToEdit: MessageAny | undefined
): void => {
    const dispatch = useAppDispatch();
    const prevPreviewStateAction = usePrevious(messagePreviewState?.action);
    const draft = useDraft(dialogId);
    useEffect(() => {
        // Listen for messagePreview changes and take messageInput in focus when user wants to reply or edit
        if (messagePreviewState) {
            // If user wants to edit a message we have to fill messageInput with its text
            if (messageToEdit && messageInputRef?.current && checkText(messageToEdit)) {
                // Clear draft
                dispatch(clearDraft({dialogId}));
                messageInputRef.current.innerText = messageToEdit?.text ?? '';
            }

            // setTimeout fixes weird bug when browser does not move caret to the end
            setTimeout(() => {
                messageInputRef.current?.focus();
                moveCaretToEnd(messageInputRef);
            }, 1);
        }

        // If state action changes from edit to null we should clear message input
        if (prevPreviewStateAction === 'edit' && messagePreviewState?.action !== 'edit' && messageInputRef.current) {
            messageInputRef.current.innerText = '';
        }
    }, [messagePreviewState, dialogId, dispatch, messageInputRef, messageToEdit, prevPreviewStateAction]);

    useActionOnNewLocation(() => {
        if (draft?.length > 0) {
            // Using setTimeout to avoid a glitch where it does not set the draft without it
            setTimeout(() => {
                if (messageInputRef.current) {
                    messageInputRef.current.innerText = draft;
                    setMessageText(draft);
                    moveCaretToEnd(messageInputRef);
                }
            });
        }
    });
};

export const useHandleRecordingState = (
    messagePreviewState: MessagePreview | null,
    recorderState: Recorder,
    onPreview: () => void,
    onStartRecording: () => void
): void => {
    useEffect(() => {
        // When user edits message cancel recording
        if (messagePreviewState?.action === 'edit' || messagePreviewState?.action === 'reply') {
            onPreview();
        }
    }, [messagePreviewState, onPreview]);

    useEffect(() => {
        // When user starts recording clear text and file inputs
        if (recorderState) {
            onStartRecording();
        }
    }, [recorderState.initRecording]);
};
