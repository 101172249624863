import {createSlice} from '@reduxjs/toolkit';
import {ReduxPartner} from '../../types/redux';

type Partners = {
    partners: {[uid: string]: ReduxPartner};
};

const initialState: Partners = {partners: {}};

export const partnersSlice = createSlice({
    name: 'partners',
    initialState: initialState,
    reducers: {
        resetPartners: () => initialState,
        upsertPartner: (state, action) => {
            const newPartner = action.payload.partnerPublicInfo;
            state.partners[newPartner.uid] = newPartner;
        },
        updatePartnerLastSeen: (state, action) => {
            const {partnerId, lastSeenStatus} = action.payload;
            if (state.partners[partnerId]) {
                state.partners[partnerId].lastSeenStatus = lastSeenStatus;
            }
        },
        updatePartnerPublicSettings: (state, action) => {
            const {partnerId, publicSettings} = action.payload;
            if (state.partners[partnerId]) {
                state.partners[partnerId].publicSettings = publicSettings;
            }
        },
        updateTypingStatus: (state, action) => {
            const {partnerId, typingStatus} = action.payload;
            if (state.partners[partnerId]) {
                state.partners[partnerId].typingStatus = typingStatus;
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const {resetPartners, upsertPartner, updatePartnerLastSeen, updatePartnerPublicSettings, updateTypingStatus} =
    partnersSlice.actions;

export const partnersReducer = partnersSlice.reducer;
