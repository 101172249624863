import * as React from 'react';

export function ExplorePreviewArrow(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={7} height={11} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#prefix__clip0_4418_1525)" fill="#fff">
                <path d="M1.707 10.192A1 1 0 01.293 8.778l4.243-4.242A1 1 0 015.95 5.95l-4.243 4.242z" />
                <path d="M5.95 4.536A1 1 0 014.536 5.95L.293 1.707A1 1 0 011.707.293L5.95 4.536z" />
            </g>
            <defs>
                <clipPath id="prefix__clip0_4418_1525">
                    <path fill="#fff" d="M0 0h7v11H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
