// Function takes duration and returns parsed beautify string
// Examples:
// if you call beautifyDuration(61) then it returns 01:01 (one minute and one second)
// if you call beautifyDuration(2, 25) then it returns 02:25 (two minutes and twenty five seconds)
export function beautifyDuration(minutes: number | null, seconds: number | null): string | null;
export function beautifyDuration(seconds: number | null): string | null;
export function beautifyDuration(seconds: number | null, minutes?: number | null): string | null {
    const sec = 60;

    if (seconds === null) {
        return null;
    }

    const m: number = (minutes ?? 0) + Math.floor(seconds / sec);
    const fancyMin = m < 10 ? `0${m}` : m;
    const s: number = Math.floor(seconds - m * sec);
    const fancySec = s < 10 ? `0${s}` : s;
    return `${fancyMin}:${fancySec}`;
}
