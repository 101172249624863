import './SettingsItemNavigate.css';
import React, {ReactElement} from 'react';
import {SettingsItem} from './SettingsItem';
import {Spacer} from '../../../Utils/Spacer';
import {BiChevronRight} from 'react-icons/bi';
import {useNavigate} from 'react-router-dom';

interface Props {
    title: string;
    icon: JSX.Element;
    navigateTo: string;
    bottomTip?: string;
}

export const SettingsItemNavigate = (props: Props): ReactElement => {
    const {title, icon, navigateTo, bottomTip} = props;
    const navigate = useNavigate();
    return (
        <SettingsItem bottomTip={bottomTip} onClick={() => navigate(navigateTo)}>
            {icon}
            <span className={'SettingsItemNavigate-title'}>{title}</span>
            <Spacer />
            <BiChevronRight className={'SettingsItemNavigate-chevron'} />
        </SettingsItem>
    );
};
