import {socketEmit} from '../../socket/socketEmit';
import {createDialogWithAdminV2} from '../../shared/events';
import {loadDialog} from './loadDialog';

export const fetchDialogWithAdmin = (): Promise<{dialogId: string}> => {
    return new Promise((resolve) => {
        socketEmit(createDialogWithAdminV2, async (wasDialogCreated: boolean, dialogId: string) => {
            // If dialog was just created then it was fetched to this device automatically
            // Else we should load it manually
            if (!wasDialogCreated) {
                await loadDialog({dialogId: dialogId});
            }
            resolve({dialogId});
        });
    });
};
