import './Spacer.css';
import React, {CSSProperties, ReactElement} from 'react';

interface Props {
    extraStyles?: CSSProperties;
}
// Spacer is a component that fills all available space
export const Spacer = (props: Props): ReactElement => {
    return <div className={`Spacer-container ${props.extraStyles}`} />;
};
