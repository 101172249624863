import {OnCloseDialog} from '../../shared/callback-types';
import {store} from '../../redux/store';
import {closeDialog, deleteDialog} from '../../redux/reducers/dialogsReducer';

export const onCloseDialog = (data: OnCloseDialog) => {
    const {_id: dialogId, removePartnerDialog} = data;
    store.dispatch(closeDialog({dialogId}));
    if (removePartnerDialog) {
        store.dispatch(deleteDialog({dialogId}));
    }
};
