import './SettingsAbout.css';
import React, {ReactElement} from 'react';
import {SettingsPageBaseAligned} from '../SettingsPageBase';
import {SettingsContainer} from './SettingsContainer';
import {SettingsItemCopeable} from './SettingsContainerItem/SettingsItemCopeable';
import {VERSION} from '../../../constants/version';
import {useAppSelector} from '../../../redux/hooks';
import {SettingsItemNavigate} from './SettingsContainerItem/SettingsItemNavigate';
import {IoNewspaperOutline} from 'react-icons/io5';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';
import {COMMIT_HASH} from '../../../constants/commitHash';

export const SettingsAbout = (): ReactElement => {
    const userId = useAppSelector((state) => state.user.userId ?? 'unknown');
    return (
        <SettingsPageBaseAligned>
            <SettingsContainer title={getLocalizedString('settingsSystemInformation')}>
                <SettingsItemCopeable value={userId} subtitle={getLocalizedString('accountId')} />
                <SettingsItemCopeable value={VERSION} subtitle={getLocalizedString('appVersion')} />
                <SettingsItemCopeable value={COMMIT_HASH} subtitle={getLocalizedString('commitHash')} />
                <SettingsItemNavigate
                    title={getLocalizedString('termsOfUse')}
                    icon={<IoNewspaperOutline />}
                    navigateTo={''}
                />
            </SettingsContainer>
        </SettingsPageBaseAligned>
    );
};
