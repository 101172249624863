import './Settings.css';
import React, {ReactElement} from 'react';
import {useAppSelector} from '../../redux/hooks';
import {SettingsAvatar} from './SettingsAvatar/SettingsAvatar';
import {EditableSettingsInput} from './EditableSettingsInput/EditableSettingsInput';
import {updateSettings} from '../../utils/user/updateSettings';
import {SettingsGender} from './SettingsGender';
import {SettingsOptions} from './SettingsContainer/SettingsOptions';
import {SettingsPageBaseAligned} from './SettingsPageBase';
import {getLocalizedString} from '../../utils/helpers/getLocalizedString';
import {LanguageContext} from '../../contexts/languageContext';
import {MAX_ABOUT_ME_LENGTH, MAX_USERNAME_LENGTH} from '../../shared/limits';

export const Settings = (): ReactElement => {
    const settings = useAppSelector((state) => state.user.data?.settings?.public);
    const hasPremium = useAppSelector((state) => state.user.data.hasPremium);
    const updateUsername = (newValue: string): Promise<void> => updateSettings('username', newValue);
    const updateAboutMe = (newValue: string): Promise<void> => updateSettings('aboutMe', newValue);
    const language = useAppSelector((state) => state.user.data.language ?? 'en');
    return (
        <LanguageContext.Provider value={language}>
            <SettingsPageBaseAligned title={getLocalizedString('back')}>
                <SettingsAvatar
                    src={settings?.profilePicture ?? null}
                    gender={settings?.gender ?? 'dunno'}
                    hasPremium={hasPremium}
                />
                <EditableSettingsInput
                    title={getLocalizedString('name')}
                    value={settings?.username ?? ''}
                    placeholder={getLocalizedString('writeYourName')}
                    onSubmit={updateUsername}
                    limit={MAX_USERNAME_LENGTH}
                />
                <EditableSettingsInput
                    title={getLocalizedString('aboutMe')}
                    value={settings?.aboutMe ?? ''}
                    placeholder={getLocalizedString('modalDescriptionPlaceholder')}
                    bottomTip={getLocalizedString('modalAboutMeHint')}
                    onSubmit={updateAboutMe}
                    multiline={true}
                    limit={MAX_ABOUT_ME_LENGTH}
                />
                <SettingsGender gender={settings?.gender ?? 'dunno'} />
                <SettingsOptions />
            </SettingsPageBaseAligned>
        </LanguageContext.Provider>
    );
};
