import {createSlice} from '@reduxjs/toolkit';
import {BanInfo, UserSettings} from '../../shared/callback-types';
import {Language} from '../../shared/language';
import {SearchPreference} from '../../shared/search-preferences';
import {NSFW} from '../../types/nsfw';
import {getNSFW} from '../../utils/nsfw/getNSFW';
import {LANGUAGE} from '../../constants/localStorageKeys';

interface UserReducerProps {
    userId: null | string;
    isSearching: boolean;
    nsfw: NSFW;
    data: {
        exploreDialogId: null | string;
        hasPremium: boolean;
        ban: BanInfo | null;
        interests: string[];
        language: Language | null;
        searchPreferences: SearchPreference;
        settings: Partial<UserSettings>;
    };
}

const initState: UserReducerProps = {
    userId: null,
    isSearching: false,
    nsfw: getNSFW(),
    data: {
        exploreDialogId: null,
        hasPremium: false,
        ban: null,
        interests: [],
        language: localStorage.getItem(LANGUAGE) as Language | null,
        searchPreferences: {male: false, female: false},
        settings: {},
    },
};

export const userSlice = createSlice({
    name: 'user',
    initialState: initState,
    reducers: {
        resetUser: () => initState,
        startSearching: (state) => {
            state.isSearching = true;
        },
        stopSearching: (state) => {
            state.isSearching = false;
        },
        updateExploreDialogId: (state, action) => {
            state.data.exploreDialogId = action.payload.dialogId;
        },
        clearExploreDialogId: (state) => {
            state.data.exploreDialogId = null;
        },
        saveUserId: (state, action) => {
            state.userId = action.payload.userId;
        },
        updateUserData: (state, action) => {
            const {newUser} = action.payload;
            state.data = newUser;
        },
        updateUserSettings: (state, action) => {
            const {settings} = action.payload;
            state.data.settings = settings;
        },
        updateUserInterests: (state, action) => {
            const {interests} = action.payload;
            state.data.interests = interests;
        },
        updateUserSearchPreferences: (state, action) => {
            const {searchPreferences} = action.payload;
            state.data.searchPreferences = searchPreferences;
        },
        updateUserLanguage: (state, action) => {
            const {language} = action.payload;
            state.data.language = language;
        },
        updateNSFW: (state, action) => {
            const {nsfw} = action.payload;
            state.nsfw = nsfw;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    startSearching,
    stopSearching,
    updateExploreDialogId,
    clearExploreDialogId,
    saveUserId,
    updateUserData,
    updateUserSettings,
    updateUserInterests,
    updateUserSearchPreferences,
    updateUserLanguage,
    updateNSFW,
    resetUser,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
