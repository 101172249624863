import React from 'react';
import './Content.css';
import {FindPartner} from '../../assets/svg/FindPartner';
import {useAppSelector} from '../../redux/hooks';
import {SearchAnimation} from './SearchAnimation';
import {getLocalizedString} from '../../utils/helpers/getLocalizedString';

interface Props {}

export const Content = (props: Props) => {
    const isSearching = useAppSelector((state) => state.user.isSearching);
    if (isSearching) {
        return (
            <div className={'Explore-Content-container'}>
                <SearchAnimation />
                <span className={'Explore-Content-title'}>{getLocalizedString('lookingForPartner')}</span>
            </div>
        );
    }
    return (
        <div className={'Explore-Content-container'}>
            <FindPartner />
            <span className={'Explore-Content-title'}>{getLocalizedString('findPartnerTitle')}</span>
            <span className={'Explore-Content-subtitle'}>
                {getLocalizedString('findPartnerSubtitleBeginning')}
                <br />
                {getLocalizedString('findPartnerSubTitle')}
            </span>
        </div>
    );
};
