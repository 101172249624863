import './SettingsItemToggle.css';
import React, {ReactElement} from 'react';
import {SettingsItem} from './SettingsItem';
import {Spacer} from '../../../Utils/Spacer';
import {Toggle} from '../../../Utils/Toggle';
import {useToggle} from '../../../../utils/hooks/useToggle';

interface Props {
    title: string;
    defaultValue: boolean;
    onToggle: (isToggled: boolean) => void;
    bottomTip?: string;
}

export const SettingsItemToggle = (props: Props): ReactElement => {
    const {title, defaultValue, onToggle, bottomTip} = props;
    const {isToggled, toggle} = useToggle(defaultValue);
    return (
        <SettingsItem bottomTip={bottomTip}>
            <span className={'SettingsItemToggle-title'}>{title}</span>
            <Spacer />
            <Toggle
                isToggled={isToggled}
                onToggle={() => {
                    toggle();
                    onToggle(!isToggled);
                }}
            />
        </SettingsItem>
    );
};
