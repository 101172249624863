import * as React from 'react';

export function AddFriendIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={14} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.15 0c-.6.004-1.192.128-1.735.365A4.153 4.153 0 007 1.37 4.153 4.153 0 005.585.365 4.428 4.428 0 003.85 0a4.031 4.031 0 00-2.712 1.06A3.5 3.5 0 000 3.587c0 2.74 3.43 5.348 4.41 6.196C5.53 10.76 7 12 7 12s1.47-1.24 2.59-2.217C10.57 8.935 14 6.326 14 3.587a3.5 3.5 0 00-1.137-2.527A4.032 4.032 0 0010.15 0z"
                fill="#201F24"
            />
        </svg>
    );
}
