import './MessageDateSeparator.css';
import React, {ReactElement} from 'react';
import {useAppSelector} from '../../../../redux/hooks';
import {beautifyMessageTimestamp} from '../../../../utils/helpers/beautifyMessageTimestamp';

interface Props {
    isVisible: boolean;
    createdAt: Date;
}

export const MessageDateSeparator = (props: Props): ReactElement | null => {
    const {createdAt, isVisible} = props;
    const language = useAppSelector((state) => state.user.data.language ?? 'en');
    if (!isVisible) {
        return null;
    }
    return (
        <div className={'MessageDateSeparator-container'}>
            <span className={'MessageDateSeparator-span'}>{beautifyMessageTimestamp(createdAt, language)}</span>
        </div>
    );
};
