import {socketEmit} from '../../socket/socketEmit';
import {removeDialog as removeDialogEvent} from '../../shared/events';
import {removeDialog as removeDialogAction} from '../../redux/reducers/dialogsReducer';
import {SharedError} from '../../shared/errors-types';
import {store} from '../../redux/store';

export const removeDialog = async (dialogId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        socketEmit(removeDialogEvent, {dialogId}, (err: SharedError | null) => {
            if (err) {
                console.log('Error in removeDialog', err);
                reject(err);
                return;
            }
            store.dispatch(removeDialogAction({dialogId}));
        });
    });
};
