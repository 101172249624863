import {deleteCookie} from '../cookie/deleteCookie';
import {store} from '../../redux/store';
import {resetUser} from '../../redux/reducers/userReducer';
import {resetPartners} from '../../redux/reducers/partnerReducer';
import {resetDialogs} from '../../redux/reducers/dialogsReducer';
import {resetLocalSettings} from '../../redux/reducers/localSettingsReducer';
import {clearCachedSocket} from '../../socket/createSocket';

/*
 *  Do whatever is needed to clear the current user's data
 */
export const clearUser = () => {
    deleteCookie();
    store.dispatch(resetUser());
    store.dispatch(resetPartners());
    store.dispatch(resetDialogs());
    store.dispatch(resetLocalSettings());
    clearCachedSocket();
};
