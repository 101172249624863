import CryptoJS from 'crypto-js';
import socketIO, {Socket} from 'socket.io-client';
import {getUserId} from '../utils/user/getUserId';
import {salt} from '../shared/salt';
import {getCookie} from '../utils/cookie/getCookie';
import {getServerUrl} from '../utils/helpers/getServerUrl';
import {VERSION} from '../constants/version';
import {DefaultEventsMap} from '@socket.io/component-emitter';
import {SocketIncomingEventsMap} from '../types/socket';
import {
    closeDialog,
    newMessage,
    partnerFound,
    updateUser,
    updateDialogMeta,
    changeConnectionStatus,
    readMessage,
    persistDialog,
    updatePartnerSettings,
    userTyping,
    reactToMessage,
} from '../shared/events';
import {onPartnerFind} from './handlers/onPartnerFind';
import {onCloseDialog} from './handlers/onCloseDialog';
import {onNewMessage} from './handlers/onNewMessage';
import {onUpdateUser} from './handlers/onUpdateUser';
import {onUpdateDialogMeta} from './handlers/onUpdateDialogMeta';
import {onChangeConnectionStatus} from './handlers/onChangeConnectionStatus';
import {onReadMessage} from './handlers/onReadMessage';
import {onDialogPersist} from './handlers/onDialogPersist';
import {isDev} from '../utils/helpers/isDev';
import {onUpdatePartnerSettings} from './handlers/onUpdatePartnerSettings';
import {onUserTyping} from './handlers/onUserTyping';
import {ADMIN_LOGIN} from '../constants/localStorageKeys';
import {getUpdates} from '../utils/fetchData/getUpdates';
import {onReactToMessage} from './handlers/onReactToMessage';

let cachedSocket: Socket<SocketIncomingEventsMap, DefaultEventsMap> | null = null;

export const clearCachedSocket = () => {
    cachedSocket = null;
};

export const getSocket = async () => {
    if (cachedSocket) {
        return cachedSocket;
    }

    const userId = await getUserId();
    if (!userId) {
        return;
    }

    const cookie = getCookie();
    if (!cookie) {
        return;
    }

    const data = [{secret: userId}];
    const secret = Array.from(salt).reverse().join('');
    const hash = CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString();
    cachedSocket = socketIO(getServerUrl(), {
        query: {
            cookie: cookie,
            secret: hash,
            version: VERSION,
            systemLanguage: 'ru',
            platform: 'web',
            admin: localStorage.getItem(ADMIN_LOGIN),
        },
        transports: ['websocket'],
        forceNew: false,
    });
    await subscribeToEvents();
    return cachedSocket;
};

const subscribeToEvents = async (): Promise<void> => {
    return new Promise((resolve) => {
        if (cachedSocket) {
            cachedSocket.on('connect', () => {
                console.log('connected');
                getUpdates();
                resolve();
            });
            cachedSocket.on('disconnect', (reason) => console.log('disconnected', reason));
            cachedSocket.on('connect_error', () => {
                console.log('connect_error');
                setTimeout(() => {
                    cachedSocket?.connect();
                }, 2500);
            });
            cachedSocket.on(partnerFound, onPartnerFind);
            cachedSocket.on(closeDialog, onCloseDialog);
            cachedSocket.on(newMessage, onNewMessage);
            cachedSocket.on(updateUser, onUpdateUser);
            cachedSocket.on(updateDialogMeta, onUpdateDialogMeta);
            cachedSocket.on(changeConnectionStatus, onChangeConnectionStatus);
            cachedSocket.on(readMessage, onReadMessage);
            cachedSocket.on(persistDialog, onDialogPersist);
            cachedSocket.on(updatePartnerSettings, onUpdatePartnerSettings);
            cachedSocket.on(userTyping, onUserTyping);
            cachedSocket.on(reactToMessage, onReactToMessage);
        }
    });
};
