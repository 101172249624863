import './PopOverOption.css';
import React, {ReactElement} from 'react';
import {POP_OVER_ITEM_HEIGHT} from '../../../constants/popOverMenu';
import {Point} from '../../../types/point';
import {calculatePopOverOffset} from '../../../utils/helpers/calculatePopOverOffset';

export interface PopOverOptionProps {
    title: string;
    value: string;
    icon: JSX.Element;
    disabled?: boolean;
    onPress: (value: string, point: Point) => void;
}

export const PopOverOption = (props: PopOverOptionProps): ReactElement => {
    const {title, value, onPress, icon, disabled} = props;
    return (
        <div
            className={`PopOverOption-container ${disabled && 'disabled'}`}
            style={{height: POP_OVER_ITEM_HEIGHT}}
            onClick={(event) => {
                event.stopPropagation();
                let point = {
                    x: event.pageX,
                    y: event.pageY,
                };
                point = calculatePopOverOffset({
                    clicked: point,
                    messageWidth: event.currentTarget.clientWidth,
                    menuHeight: 120,
                    menuWidth: 200,
                });
                onPress(value, point);
            }}
        >
            <div className={'PopOverOption-icon'}>{icon}</div>
            <span className={'PopOverOption-span'}>{title}</span>
        </div>
    );
};
