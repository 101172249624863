import {store} from '../../redux/store';
import {socketEmit} from '../../socket/socketEmit';
import {getInterests} from '../../shared/events';
import {ClientInterest} from '../../shared/interests';
import {updateInterests} from '../../redux/reducers/localSettingsReducer';

export const fetchInterests = async (): Promise<void> => {
    socketEmit(getInterests, (interests: ClientInterest[]) => {
        if (interests.length) {
            store.dispatch(updateInterests({interests}));
        }
    });
};
