import './MessageImage.css';
import './MessageText.css';
import React, {memo, ReactElement, useEffect, useRef, useState} from 'react';
import {ModalRef} from '../../../../Utils/Modals/FancyModal';
import {ImageModal} from '../../../../Utils/Modals/ImageModal';
import {AiFillEye} from 'react-icons/ai';

interface Props {
    url: string;
    caption: string | undefined;
    width: number;
    height: number;
    madeWithCamera: boolean;
    isSentByMe: boolean;
    shouldBlur: boolean;
}

export const MessageImage = memo((props: Props): ReactElement => {
    const {url, caption, width, height, isSentByMe, shouldBlur} = props;
    const imageModalRef = useRef<ModalRef>(null);
    const [isRevealed, setIsRevealed] = useState(!shouldBlur);

    useEffect(() => {
        setIsRevealed(!shouldBlur);
    }, [shouldBlur]);

    let blurPlaceholder;
    if (!isRevealed) {
        blurPlaceholder = (
            <div
                className={'MessageImage-nsfw-eye-container'}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsRevealed(true);
                }}
            >
                <AiFillEye className={'MessageImage-nsfw-eye-icon'} />
            </div>
        );
    }

    return (
        <>
            <div>
                <div className={'MessageImage-img-div'}>
                    <img
                        className={`MessageImage-img ${shouldBlur && !isRevealed && 'blur'}`}
                        src={url}
                        alt={'messageImage'}
                        width={width}
                        height={height}
                        onClick={(e) => {
                            e.stopPropagation();
                            imageModalRef.current?.open();
                        }}
                    />
                    {blurPlaceholder}
                </div>
                {Boolean(caption?.length) && (
                    <p
                        className={`${
                            isSentByMe ? 'MessageText-user-text' : 'MessageText-partner-text'
                        } MessageImage-caption`}
                    >
                        {caption}
                    </p>
                )}
            </div>
            <ImageModal ref={imageModalRef} src={url} caption={caption} />
        </>
    );
});
