import './Toggle.css';
import React, {ReactElement} from 'react';

interface Props {
    isToggled: boolean;
    onToggle: () => void;
}

export const Toggle = (props: Props): ReactElement => {
    const {isToggled, onToggle} = props;
    return (
        <div className={`Toggle-container ${isToggled ? 'checked' : ''}`} onClick={onToggle}>
            <span className={`Toggle-slider ${isToggled ? 'checked' : ''}`} />
        </div>
    );
};
