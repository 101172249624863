import {MessageAny} from '../../shared/message-any';
import {socketEmit} from '../../socket/socketEmit';
import {newMessage} from '../../shared/events';
import {OnNewMessageServerResponse} from '../../shared/callback-types';
import {SharedError} from '../../shared/errors-types';
import {store} from '../../redux/store';
import {addMessage} from '../../redux/reducers/dialogsReducer';
import {uploadFile} from '../helpers/uploadFile';
import {MessageImage} from '../../shared/message-image';
import {MessageVoice} from '../../shared/message-voice';
import {ADMIN_LOGIN} from '../../constants/localStorageKeys';

export const sendMessage = (dialogId: string, message: MessageAny): Promise<void> => {
    return new Promise((resolve, reject) => {
        socketEmit(
            newMessage,
            {dialogId, message, admin: localStorage.getItem(ADMIN_LOGIN)},
            (err: SharedError | null, data: OnNewMessageServerResponse) => {
                if (err) {
                    console.log(`Error in sendMessage: ${err.code}`);
                    reject();
                    return;
                }
                resolve();
                store.dispatch(
                    addMessage({
                        dialogId: data.dialogId,
                        message: data.message,
                    })
                );
            }
        );
    });
};

export const sendImage = async (dialogId: string, message: MessageImage, image: File | Blob): Promise<void> => {
    await sendMessage(dialogId, message);
    await uploadFile({
        file: image,
        messageType: 'image',
        dialogId: dialogId,
        msgId: message.msgId,
    });
};

export const sendVoice = async (dialogId: string, message: MessageVoice, voice: File | Blob): Promise<void> => {
    await sendMessage(dialogId, message);
    await uploadFile({
        file: voice,
        messageType: 'voice',
        dialogId: dialogId,
        msgId: message.msgId,
    });
};
