import {AdminPublicUserInfo, Meta, OnGetDialogParams, PublicUserInfo} from '../../shared/callback-types';
import {SharedError} from '../../shared/errors-types';
import {MessageAny} from '../../shared/message-any';
import {getDialog} from '../../shared/events';
import {socketEmit} from '../../socket/socketEmit';
import {store} from '../../redux/store';
import {addDialog, addMessage} from '../../redux/reducers/dialogsReducer';
import {upsertPartner} from '../../redux/reducers/partnerReducer';

export const loadDialog = async (params: OnGetDialogParams): Promise<void> => {
    return new Promise((resolve, reject) => {
        socketEmit(
            getDialog,
            params,
            async (
                err: SharedError | null,
                response: {
                    dialogId: string;
                    messages: MessageAny[];
                    meta: Meta;
                    userInfo: PublicUserInfo | AdminPublicUserInfo;
                }
            ) => {
                const {dialogId, messages, meta, userInfo: partnerPublicInfo} = response;
                // If server doesn't response for 10 seconds then make button enabled and quit the function
                if (err) {
                    console.log(err);
                    reject(err);
                    return;
                }
                const partnerId = partnerPublicInfo.uid;
                store.dispatch(addDialog({meta, dialogId, partnerId}));
                store.dispatch(upsertPartner({partnerPublicInfo}));
                messages.forEach((message) => {
                    store.dispatch(addMessage({dialogId, message}));
                });
                resolve();
            }
        );
    });
};
