import {createSlice} from '@reduxjs/toolkit';
import {PopOverProps} from '../../types/popOverProps';
import {MessagePreview, MessagePreviewAction} from '../../types/messagePreview';
import {ClientInterest} from '../../shared/interests';

type LocalSettingsState = {
    shouldShowSlideMenu: boolean;
    showPopOverMenu: PopOverProps | null;
    showEmojiPanel: boolean;
    messagePreviews: {[dialogId: string]: MessagePreview | null};
    allInterests: ClientInterest[];
    draft: Record<string, string>;
    activeChat: string | null;
    wasInitialLoadDone: boolean;
};

const initState: LocalSettingsState = {
    shouldShowSlideMenu: false,
    showEmojiPanel: false,
    showPopOverMenu: null,
    messagePreviews: {},
    allInterests: [],
    draft: {},
    activeChat: null,
    wasInitialLoadDone: false,
};

export const localSettingsSlice = createSlice({
    name: 'localSettings',
    initialState: initState,
    reducers: {
        resetLocalSettings: () => initState,
        showSlideMenu: (state) => {
            state.shouldShowSlideMenu = true;
        },
        hideSlideMenu: (state) => {
            state.shouldShowSlideMenu = false;
        },
        showPopOverAt: (state, action) => {
            state.showPopOverMenu = action.payload.popOverProps;
        },
        hidePopOver: (state) => {
            state.showPopOverMenu = null;
        },
        updateMessagePreview: (
            state,
            action: {payload: {action: MessagePreviewAction; messageId: string; dialogId: string}}
        ) => {
            const {action: previewAction, messageId, dialogId} = action.payload;
            state.messagePreviews[dialogId] = {action: previewAction, messageId};
        },
        clearMessagePreview: (state, action) => {
            const {dialogId} = action.payload;
            state.messagePreviews[dialogId] = null;
        },
        updateInterests: (state, action) => {
            const {interests} = action.payload;
            state.allInterests = interests;
        },
        updateDraft: (state, action) => {
            const {draft, dialogId} = action.payload;
            state.draft[dialogId] = draft;
        },
        clearDraft: (state, action) => {
            const {dialogId} = action.payload;
            state.draft[dialogId] = '';
        },
        setActiveChat: (state, action) => {
            const {dialogId} = action.payload;
            state.activeChat = dialogId;
        },
        showEmojiPanel: (state) => {
            state.showEmojiPanel = true;
        },
        hideEmojiPanel: (state) => {
            state.showEmojiPanel = false;
        },
        initialLoadDone: (state) => {
            state.wasInitialLoadDone = true;
        },
    },
});

export const {
    showSlideMenu,
    hideSlideMenu,
    showPopOverAt,
    hidePopOver,
    updateMessagePreview,
    clearMessagePreview,
    updateDraft,
    clearDraft,
    updateInterests,
    setActiveChat,
    showEmojiPanel,
    hideEmojiPanel,
    resetLocalSettings,
    initialLoadDone,
} = localSettingsSlice.actions;

export const localSettingsReducer = localSettingsSlice.reducer;
