import './Login.css';
import React, {ReactElement, useRef, useState} from 'react';
import {BasicButton} from '../Utils/Buttons/BasicButton';
import {login} from '../../utils/admin/login';

export const Login = (): ReactElement => {
    const loginRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<undefined | string>(undefined);
    const attemptToLogin = async (): Promise<void> => {
        try {
            await login(loginRef.current?.value ?? '', passwordRef.current?.value ?? '');
        } catch (e) {
            if (e instanceof Error) {
                setError(`Something went wrong: ${e.message}`);
            }
        }
    };
    return (
        <div>
            <form
                className={'Login-form'}
                onSubmit={(e) => {
                    e.preventDefault();
                    attemptToLogin();
                }}
            >
                <input ref={loginRef} type={'text'} placeholder={'username'} onChange={() => setError(undefined)} />
                <input
                    ref={passwordRef}
                    type={'password'}
                    placeholder={'password'}
                    onChange={() => setError(undefined)}
                />
                {error && <p className={'Login-error'}>{error}</p>}
                <BasicButton
                    title={'Login'}
                    onClick={() => {
                        attemptToLogin();
                    }}
                />
            </form>
        </div>
    );
};
