import './UserSettingsAvatar.css';
import React, {ReactElement} from 'react';
import {Gender} from '../../../shared/gender';
import {genderImage} from '../../../constants/genderImage';
import {ProfilePicture} from '../../Utils/ProfilePicture';

interface Props {
    src: string | null;
    gender: Gender;
    hasPremium: boolean;
}

export const UserSettingsAvatar = (props: Props): ReactElement => {
    const {src, gender, hasPremium} = props;
    return (
        <ProfilePicture
            profilePictureUrl={src ?? genderImage[gender]}
            isClickable={false}
            height={42}
            width={42}
            hasPremium={hasPremium}
            extraStyles={{
                margin: 0,
                marginRight: 15,
            }}
        />
    );
};
