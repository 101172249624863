import './ChatProfile.css';
import React, {ReactElement} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import {ChatProfilePicture} from './ChatProfilePicture';
import {ChatProfileUsername} from './ChatProfileUsername';
import {ChatProfileOnlineStatus} from './ChatProfileOnlineStatus';
import {ChatProfileMenu} from './ChatProfileMenu/ChatProfileMenu';
import {ChatProfileInfoSection} from './ChatProfileInfoSection';
import {prettifyGender} from '../../../utils/helpers/prettifyGender';
import {ChatProfileMedia} from './ChatProfileMedia/ChatProfileMedia';
import {PageHeader} from '../../Utils/PageHeader';
import {isServiceChat} from '../../../utils/dialog/checkServiceChats';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    dialogId: string;
}

export const ChatProfile = (props: Props): ReactElement => {
    const {dialogId} = props;
    const meta = useAppSelector((state) => state.dialogs.metas[dialogId]);
    const partner = useAppSelector((state) => state.partners.partners[meta?.partner ?? '']);
    const userId = useAppSelector((state) => state.user.userId);
    return (
        <div className={'ChatProfile-container'}>
            <PageHeader title={getLocalizedString('profile')} />
            <div className={'ChatProfile-content'}>
                <ChatProfilePicture
                    gender={partner?.publicSettings?.gender ?? 'dunno'}
                    profilePictureUrl={partner?.publicSettings?.profilePicture}
                    isTemporaryDialog={meta?.temporary}
                    hasPremium={partner?.hasPremium}
                    isSystemChat={isServiceChat(partner?.uid)}
                />
                <ChatProfileUsername
                    username={partner?.publicSettings?.username}
                    isTemporary={Boolean(meta?.temporary)}
                />
                <ChatProfileOnlineStatus lastSeenStatus={partner?.lastSeenStatus} />
                <ChatProfileMenu
                    dialogId={dialogId}
                    isTemporaryDialog={meta?.temporary}
                    isDialogMuted={Boolean(meta?.mutedFor?.includes(userId ?? ''))}
                />
                <ChatProfileInfoSection
                    title={getLocalizedString('gender')}
                    content={getLocalizedString(prettifyGender[partner?.publicSettings?.gender ?? 'dunno'])}
                />
                <ChatProfileInfoSection
                    title={getLocalizedString('modalAboutMeTitle')}
                    content={partner?.publicSettings?.aboutMe}
                    placeholder={getLocalizedString('emptyAboutMeDescription')}
                />
                <ChatProfileMedia dialogId={dialogId} />
            </div>
        </div>
    );
};
