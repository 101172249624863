import {OnUpdateUser} from '../../shared/callback-types';
import {store} from '../../redux/store';
import {stopSearching, updateUserData} from '../../redux/reducers/userReducer';

export const onUpdateUser = async (data: OnUpdateUser): Promise<void> => {
    const {user} = store.getState();
    store.dispatch(updateUserData({newUser: data.user}));
    if (user.isSearching && data.user.exploreDialogId) {
        store.dispatch(stopSearching());
    }
};
