import './Chat.css';
import React, {ReactElement, useLayoutEffect, useRef} from 'react';
import {ChatHeader} from './ChatHeader/ChatHeader';
import {ChatHistory} from './ChatHistory/ChatHistory';
import {ChatFooter} from './ChatFooter/ChatFooter';
import {ChatExtraButtons} from './ChatExtraButtons/ChatExtraButtons';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {loadDialog} from '../../utils/fetchData/loadDialog';
import {loadUser} from '../../utils/fetchData/loadUser';
import {DeletedChat} from '../Utils/DeletedChat';
import {hideEmojiPanel} from '../../redux/reducers/localSettingsReducer';
import {useActionOnNewLocation} from '../../utils/hooks/useActionOnNewLocation';
import {LanguageContext} from '../../contexts/languageContext';

interface Props {
    dialogId: string;
}

export const Chat = (props: Props): ReactElement | null => {
    const {dialogId} = props;
    const chatHistoryRef = useRef<HTMLDivElement>(null);
    const userId = useAppSelector((state) => state.user.userId);
    const meta = useAppSelector((state) => state.dialogs.metas[dialogId]);
    const messages = useAppSelector((state) => state.dialogs.messages[dialogId]);
    const scrollToChatEnd = (): void => chatHistoryRef.current?.scrollTo({top: 0});
    const dispatch = useAppDispatch();
    const isEmojiPanelVisible = useAppSelector((state) => state.localSettings.showEmojiPanel);
    const language = useAppSelector((state) => state.user.data.language ?? 'en');

    useLayoutEffect(() => {
        if (!meta || !messages) {
            loadDialog({dialogId});
        }

        if (!userId) {
            loadUser();
        }
    }, [userId, meta, messages, dialogId]);

    useActionOnNewLocation(() => {
        if (isEmojiPanelVisible) {
            dispatch(hideEmojiPanel());
        }
    });
    // This function is used as a global permanent backdrop
    const onChatClick = () => {
        if (isEmojiPanelVisible) {
            dispatch(hideEmojiPanel());
        }
    };

    if (!meta || !messages || !userId) {
        return null;
    }

    if (meta.deleted) {
        return <DeletedChat dialogId={dialogId} />;
    }

    return (
        <LanguageContext.Provider value={language}>
            <div className={'Chat-container'} onClick={onChatClick}>
                <ChatHeader dialogId={dialogId} />
                <ChatHistory ref={chatHistoryRef} dialogId={dialogId} />
                <ChatExtraButtons
                    dialogId={dialogId}
                    isClosed={!meta?.active}
                    isTemporary={meta?.temporary}
                    isLikedByMe={meta?.likedBy?.includes(userId)}
                    isLikedByPartner={meta?.likedBy?.includes(meta.partner ?? '')}
                />
                <ChatFooter dialogId={dialogId} isClosed={!meta?.active} scrollToChatEnd={scrollToChatEnd} />
            </div>
        </LanguageContext.Provider>
    );
};
