import './ChatAdminTemplate.css';
import React, {ReactElement} from 'react';

interface Props {
    name: string;
    onPress: () => void;
}

export const ChatAdminTemplate = (props: Props): ReactElement => {
    const {name, onPress} = props;
    return (
        <span className={'ChatAdminTemplate-span'} onClick={onPress}>
            {name}
        </span>
    );
};
