import './ChatList.css';
import React, {memo, ReactElement, useEffect, useRef, useState} from 'react';
import {useAppSelector} from '../../redux/hooks';
import {Meta} from '../../shared/callback-types';
import {isUserAdmin} from '../../utils/dialog/checkServiceChats';
import {ChatPreview} from './ChatPreview/ChatPreview';
import {ExplorePreview} from './ExplorePreview';
import {NoFriends} from './NoFriends';
import {UserSettings} from './UserSettings/UserSettings';
import {loadDialogs} from '../../utils/fetchData/loadDialogs';
import {LoadingIndicator} from './LoadingIndicator';
import {store} from '../../redux/store';
import {getLastDialogMessage} from '../../utils/dialog/getLastDialogMessage';
import {useIsAdmin} from '../../utils/user/isUserAdmin';

interface Props {}

export const ChatList = memo((props: Props): ReactElement => {
    const userId = useAppSelector((state) => state.user.userId ?? '');
    const isAdmin = useIsAdmin();
    const metas = useAppSelector((state) => _filterMetas(userId, isAdmin, Object.entries(state.dialogs.metas)));
    const {isFetching, bottomElementRef, wasInitialLoadDone} = useInfiniteScroll(() =>
        metas.map(([dialogId]) => dialogId)
    );

    useEffect(() => {
        // Prevent page from resetting scroll position on rerender
        window.history.scrollRestoration = 'manual';
    }, []);

    return (
        <div className={'ChatList-container'}>
            <UserSettings />
            <ExplorePreview />
            {wasInitialLoadDone && !metas.length && <NoFriends />}
            {metas.map(([dialogId, meta]) => {
                return <ChatPreview key={dialogId} dialogId={dialogId} meta={meta} />;
            })}
            <div className={'ChatList-bottom-element-div'} ref={bottomElementRef} />
            {(isFetching || !wasInitialLoadDone) && <LoadingIndicator />}
        </div>
    );
});

// Filter out all metas which aren't suitable for ChatList
const _filterMetas = (userId: string, isAdmin: boolean, values: [string, Meta][]): [string, Meta][] => {
    const allMessages = store.getState().dialogs.messages;
    return values
        .filter(([dialogId, meta]) => {
            if (meta.temporary) {
                return false;
            }

            if (meta.removed) {
                return false;
            }

            if (isUserAdmin(meta.partner)) {
                return false;
            }

            // If user is admin then hide dialogs without partner messages
            if (isAdmin) {
                const hasPartnerMessages = Object.values(allMessages[dialogId]).some(
                    (msg) => msg.sender === meta.partner
                );
                if (!hasPartnerMessages) {
                    return false;
                }
            }

            return true;
        })
        .sort(([dialogAId, metaA], [dialogBId, metaB]) => {
            const lastMessageA = getLastDialogMessage(userId, Object.values(allMessages[dialogAId]));
            const lastMessageB = getLastDialogMessage(userId, Object.values(allMessages[dialogBId]));
            const messageATimestamp = new Date(lastMessageA?.createdAt ?? new Date()).getTime();
            const messageBTimestamp = new Date(lastMessageB?.createdAt ?? new Date()).getTime();
            return messageBTimestamp - messageATimestamp;
        });
};

const useInfiniteScroll = (getDialogIds: () => string[]) => {
    const [isFetching, setIsFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const wasInitialLoadDone = useAppSelector((state) => state.localSettings.wasInitialLoadDone);
    const observer = useRef<IntersectionObserver>();
    const bottomElementRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (observer.current) {
            observer.current.disconnect();
        }
        if (bottomElementRef.current) {
            observer.current = new IntersectionObserver((entries) => {
                // If the element is visible, load more dialogs
                if (entries[0].isIntersecting && !isFetching && wasInitialLoadDone && hasMore) {
                    setIsFetching(true);
                    loadDialogs({clientDialogsIds: getDialogIds()}, 25)
                        .then(({dialogsCount}) => {
                            setHasMore(dialogsCount > 0);
                        })
                        .finally(() => {
                            setIsFetching(false);
                        });
                }
            });
            observer.current.observe(bottomElementRef.current);
        }

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [getDialogIds, isFetching, wasInitialLoadDone, hasMore]);

    return {
        isFetching,
        bottomElementRef,
        wasInitialLoadDone,
    };
};
