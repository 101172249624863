import {getServerUrl} from '../utils/helpers/getServerUrl';

const serverURL = getServerUrl();

export const CREATE_ACCOUNT = `${serverURL}/createAccount`;
export const GET_USER_ID = `${serverURL}/getUserId`;
export const UPLOAD_FILE = `${serverURL}/upload`;
export const GET_TEMPLATES = `${serverURL}/get_templates`;
export const GET_TEMPLATE_ITEM = `${serverURL}/get_template_item`;
export const GET_GENERATED_RESPONSE = `${serverURL}/get_generated_response`;
export const LOGIN_URL = `${serverURL}/login`;
