import './SettingsNSFW.css';
import React, {ReactElement} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import {useDispatch} from 'react-redux';
import {SettingsSelectPicker} from './SettingsSelectPicker/SettingsSelectPicker';
import {NSFW} from '../../../types/nsfw';
import {updateNSFW} from '../../../redux/reducers/userReducer';
import {setNSFW} from '../../../utils/nsfw/setNSFW';
import {SettingsPageBase} from '../SettingsPageBase';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

export const SettingsNSFW = (): ReactElement => {
    const nsfw = useAppSelector((state) => state.user.nsfw);
    const dispatch = useDispatch();
    const nsfwOptions = [
        {title: getLocalizedString('nsfwOff'), value: NSFW.NONE},
        {title: getLocalizedString('nsfwTemporary'), value: NSFW.TEMPORARY},
        {title: getLocalizedString('nsfwAll'), value: NSFW.ALL},
    ];
    return (
        <SettingsPageBase>
            <SettingsSelectPicker
                options={nsfwOptions}
                onClick={(nsfw) => {
                    dispatch(updateNSFW({nsfw}));
                    setNSFW(nsfw);
                }}
                selectedItem={nsfw}
            />
        </SettingsPageBase>
    );
};
