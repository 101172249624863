import {OnNewMessageServerParams} from '../../shared/callback-types';
import {store} from '../../redux/store';
import {addMessage, updateMeta} from '../../redux/reducers/dialogsReducer';
import {loadDialogIfNeeded} from '../../utils/dialog/loadDialogIfNeeded';

export const onNewMessage = async (data: OnNewMessageServerParams): Promise<void> => {
    const {dialogId, message} = data;
    await loadDialogIfNeeded(dialogId);
    store.dispatch(addMessage({dialogId, message}));
    store.dispatch(updateMeta({dialogId, meta: {lastUpdatedAt: message.createdAt}}));
};
