import {socketEmit} from '../../socket/socketEmit';
import {getUser as getUserEvent} from '../../shared/events';
import {SharedError} from '../../shared/errors-types';
import {User} from '../../shared/callback-types';
import {store} from '../../redux/store';
import {updateUserData} from '../../redux/reducers/userReducer';
import {isUserAdmin} from '../dialog/checkServiceChats';
import {activateAdmin} from '../../redux/reducers/adminReducer';

export const loadUser = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            socketEmit(getUserEvent, {}, (err: SharedError | null, data: {user: User}) => {
                if (err) {
                    console.log('Error inside getUser', err);
                    reject(err);
                    return;
                }
                store.dispatch(updateUserData({newUser: data.user}));
                const isAdmin = isUserAdmin(data.user.uid);
                if (isAdmin) {
                    store.dispatch(activateAdmin());
                }
            });
        } finally {
            resolve();
        }
    });
};
