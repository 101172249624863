import './ChatProfileMenuItem.css';
import React, {ReactElement} from 'react';

export interface ProfileMenuItem {
    title: string;
    icon: JSX.Element;
    onPress: () => void;
}

interface Props extends ProfileMenuItem {
    isLast: boolean;
}

export const ChatProfileMenuItem = (props: Props): ReactElement => {
    const {title, icon, isLast, onPress} = props;
    return (
        <div className={`ChatProfileMenuItem-container ${!isLast && 'ChatProfileMenuItem-border'}`} onClick={onPress}>
            <div className={`ChatProfileMenuItem-icon`}>{icon}</div>
            <span className={`ChatProfileMenuItem-title`}>{title}</span>
        </div>
    );
};
