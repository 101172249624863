import './ChatProfileMedia.css';
import React, {ReactElement, useRef, useState} from 'react';
import {useAppSelector} from '../../../../redux/hooks';
import {MessageImage} from '../../../../shared/message-image';
import {MessageAny} from '../../../../shared/message-any';
import {ImageModal, ImageModalData} from '../../../Utils/Modals/ImageModal';
import {ModalRef} from '../../../Utils/Modals/FancyModal';

interface Props {
    dialogId: string;
}

export const ChatProfileMedia = (props: Props): ReactElement => {
    const {dialogId} = props;
    const imageModalRef = useRef<ModalRef>(null);
    const [zoomedImage, setZoomedImage] = useState<ImageModalData>({src: ''});
    const userId = useAppSelector((state) => state.user.userId ?? '');
    const images: MessageImage[] = useAppSelector((state) =>
        _filterImages(userId, Object.values(state.dialogs.messages[dialogId]))
    );
    return (
        <>
            <div className={'ChatProfileMedia-container'}>
                {images.reverse().map((image) => {
                    return (
                        <img
                            key={image.msgId}
                            alt={'Media from dialog'}
                            className={'ChatProfileMedia-img'}
                            src={image.uri}
                            onClick={() => {
                                setZoomedImage({src: image.uri, caption: image.text});
                                imageModalRef.current?.open();
                            }}
                        />
                    );
                })}
            </div>
            <ImageModal ref={imageModalRef} {...zoomedImage} />
        </>
    );
};

const _filterImages = (userId: string, messages: MessageAny[]): MessageImage[] => {
    return messages.filter((message) => {
        return message.type === 'image' && !message?.deletedFor?.includes(userId) && !message.secret;
    }) as MessageImage[];
};
