import './SettingsPrivacy.css';
import React, {ReactElement} from 'react';
import {SettingsPageBaseAligned} from '../SettingsPageBase';
import {SettingsItemToggle} from './SettingsContainerItem/SettingsItemToggle';
import {SettingsContainer} from './SettingsContainer';
import {useAppSelector} from '../../../redux/hooks';
import {updateSettings} from '../../../utils/user/updateSettings';
import {fetchPartnersInfo} from '../../../utils/fetchData/fetchPartnersInfo';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

export const SettingsPrivacy = (): ReactElement => {
    const isHidingStatus = useAppSelector((state) => state?.user?.data?.settings?.public?.hideOnlineStatus);
    const onHideOnlineToggle = (newValue: boolean) =>
        updateSettings('hideOnlineStatus', newValue).then(() => fetchPartnersInfo());

    if (isHidingStatus === undefined) {
        return (
            <SettingsPageBaseAligned>
                <SettingsContainer title={getLocalizedString('settingsPrivacy')}></SettingsContainer>
            </SettingsPageBaseAligned>
        );
    }
    return (
        <SettingsPageBaseAligned>
            <SettingsContainer title={getLocalizedString('settingsPrivacy')}>
                <SettingsItemToggle
                    title={getLocalizedString('hideOnlineTitle')}
                    bottomTip={getLocalizedString('hideOnlineDescription')}
                    defaultValue={isHidingStatus}
                    onToggle={onHideOnlineToggle}
                />
            </SettingsContainer>
        </SettingsPageBaseAligned>
    );
};
