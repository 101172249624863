import './SettingsLanguage.css';
import React, {ReactElement} from 'react';
import {SettingsSelectPicker} from './SettingsSelectPicker/SettingsSelectPicker';
import {useAppSelector} from '../../../redux/hooks';
import {optimisticUpdateUser} from '../../../utils/user/updateUser';
import {useDispatch} from 'react-redux';
import {updateUserLanguage} from '../../../redux/reducers/userReducer';
import {SettingsPageBase} from '../SettingsPageBase';

const languageOptions = [
    {value: 'en', title: 'English'},
    {value: 'ru', title: 'Русский'},
    {value: 'id', title: 'Bahasa Indonesia'},
    {value: 'es', title: 'Español'},
];

export const SettingsLanguage = (): ReactElement => {
    const language = useAppSelector((state) => state.user.data.language ?? 'en');
    const dispatch = useDispatch();
    return (
        <SettingsPageBase>
            <SettingsSelectPicker
                options={languageOptions}
                onClick={(value) => {
                    optimisticUpdateUser(
                        'language',
                        value,
                        (val) => dispatch(updateUserLanguage({language: val})),
                        language
                    );
                }}
                selectedItem={language}
            />
        </SettingsPageBase>
    );
};
