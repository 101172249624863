import './SettingsOptions.css';
import React, {ReactElement} from 'react';
import {SettingsItemNavigate} from './SettingsContainerItem/SettingsItemNavigate';
import {GrLanguage} from 'react-icons/gr';
import {AiFillQuestionCircle} from 'react-icons/ai';
import {MdLensBlur} from 'react-icons/md';
import {FiShield} from 'react-icons/fi';
import {RiVipCrownLine} from 'react-icons/ri';
import {BsCardChecklist} from 'react-icons/bs';
import {GrCircleInformation} from 'react-icons/gr';
import {SettingsContainer} from './SettingsContainer';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

export const SettingsOptions = (): ReactElement => {
    return (
        <SettingsContainer title={getLocalizedString('settingsTitle')}>
            <SettingsItemNavigate
                title={getLocalizedString('settingsHelp')}
                bottomTip={getLocalizedString('settingsBottomTipHelp')}
                icon={<AiFillQuestionCircle />}
                navigateTo={'support'}
            />
            <SettingsItemNavigate
                title={getLocalizedString('settingsLanguage')}
                bottomTip={getLocalizedString('settingsBottomTopLanguage')}
                icon={<GrLanguage />}
                navigateTo={'language'}
            />
            <SettingsItemNavigate
                title={getLocalizedString('settingsNSFW')}
                bottomTip={getLocalizedString('settingsBottomTopNSFW')}
                icon={<MdLensBlur />}
                navigateTo={'nsfw'}
            />
            <SettingsItemNavigate
                title={getLocalizedString('settingsPrivacy')}
                bottomTip={getLocalizedString('settingsBottomTopPrivacy')}
                icon={<FiShield />}
                navigateTo={'privacy'}
            />
            <SettingsItemNavigate
                title={getLocalizedString('settingsPremium')}
                bottomTip={getLocalizedString('settingsBottomTopPremium')}
                icon={<RiVipCrownLine />}
                navigateTo={''}
            />
            <SettingsItemNavigate
                title={getLocalizedString('settingsRules')}
                bottomTip={getLocalizedString('settingsBottomTopRules')}
                icon={<BsCardChecklist />}
                navigateTo={''}
            />
            <SettingsItemNavigate
                title={getLocalizedString('settingsSystemInformation')}
                icon={<GrCircleInformation />}
                navigateTo={'about'}
            />
        </SettingsContainer>
    );
};
