import dunno from '../assets/images/avatars/dunno.png';
import male from '../assets/images/avatars/male.png';
import female from '../assets/images/avatars/female.png';
import moderator from '../assets/images/avatars/moderator.png';

export const moderatorImage = moderator;
export const genderImage = {
    dunno: dunno,
    male: male,
    female: female,
};
