import './EditableSettingsInputLimit.css';
import React, {ReactElement} from 'react';

interface Props {
    limitLeft: number;
    isVisible: boolean;
    didReachLimit: boolean;
}

export const EditableSettingsInputLimit = (props: Props): ReactElement | null => {
    const {isVisible, limitLeft, didReachLimit} = props;

    if (!isVisible) {
        return null;
    }

    return <span className={`EditableSettingsInputLimit-limit ${didReachLimit && 'reached'}`}>{limitLeft}</span>;
};
