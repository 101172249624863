import './MessageInputRecording.css';
import React, {ReactElement} from 'react';
import {RecordButtonProps} from '../../../../types/recorder';
import {Spacer} from '../../../Utils/Spacer';
import {BsFillStopCircleFill} from 'react-icons/bs';
import {beautifyDuration} from '../../../../utils/helpers/beautifyDuration';

interface Props extends RecordButtonProps {}

export const MessageInputRecording = (props: Props): ReactElement => {
    const {recorderState, handlers} = props;
    const {recordingMinutes, recordingSeconds} = recorderState;
    return (
        <div className={'MessageInputRecording-container'}>
            <BsFillStopCircleFill className={'MessageInputRecording-stop'} onClick={() => handlers.cancelRecording()} />
            <Spacer />
            <div className={'MessageInputRecording-red-dot'} />
            <span className={'MessageInputRecording-duration'}>
                {beautifyDuration(recordingSeconds, recordingMinutes)}
            </span>
        </div>
    );
};
