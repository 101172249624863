import {Point} from '../../types/point';
import {POP_OVER_ITEM_HEIGHT} from '../../constants/popOverMenu';

// Each menu has 50px height and in total we have 4 items
const MENU_HEIGHT = POP_OVER_ITEM_HEIGHT * 4;
// Min width is 150px but it can go up to 50% of the screen
// Although to calculate offset 170px is enough
const MENU_WIDTH = 170;

type Params = {
    clicked: Point;
    messageWidth: number;
    menuHeight?: number;
    menuWidth?: number;
};

// Function takes the coordinates where user has clicked and if the menu goes out of bound it adds an offset to prevent it
export const calculatePopOverOffset = ({
    clicked,
    messageWidth,
    menuWidth = MENU_WIDTH,
    menuHeight = MENU_HEIGHT,
}: Params): Point => {
    const {x: clickedX, y: clickedY} = clicked;
    const {innerWidth, innerHeight} = window;

    // Deal with Y
    // If clickedY plus menu is bigger then inner height it means that menu will go out of bound on Y axis
    // To fix it we should add an offset
    if (clickedY + menuHeight > innerHeight) {
        clicked.y = innerHeight - menuHeight;
    }
    // Deal with X
    // If clickedX + menuWidth is bigger than innerWidth then menu would appear beyond the screen
    // To fix it we stick the menu to the right corner
    if (clickedX + menuWidth > innerWidth) {
        clicked.x = innerWidth - menuWidth;
    }
    return clicked;
};
