import {SharedError} from '../../shared/errors-types';
import {closeDialog as closeDialogEvent} from '../../shared/events';
import {socketEmit} from '../../socket/socketEmit';
import {
    closeDialog as closeDialogAction,
    removeDialog as removeDialogAction,
} from '../../redux/reducers/dialogsReducer';
import {store} from '../../redux/store';
import {getUserId} from '../user/getUserId';

export const closeDialog = async (
    dialogId: string,
    removeDialog: boolean = false,
    removePartnerDialog = false
): Promise<void> => {
    return new Promise((resolve, reject) => {
        socketEmit(
            closeDialogEvent,
            {
                _id: dialogId,
                removeUserDialog: removeDialog,
                removePartnerDialog: removePartnerDialog,
            },
            async (err: SharedError | null, _: Date, removedFor: string[]) => {
                // If server doesn't response for 10 seconds then make button enabled and quit the function
                if (err) {
                    console.log(err);
                    reject();
                    return;
                }
                resolve();
                const userId = await getUserId();
                if (removedFor?.includes(userId ?? '')) {
                    store.dispatch(removeDialogAction({dialogId}));
                }
                store.dispatch(closeDialogAction({dialogId}));
            }
        );
    });
};
