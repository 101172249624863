import {socketEmit} from '../../socket/socketEmit';
import {getUpdatesV3} from '../../shared/events';
import {SharedError} from '../../shared/errors-types';
import {store} from '../../redux/store';
import {getUserId} from '../user/getUserId';
import {initialLoadDialogs} from './loadDialogs';
import {OnGetUpdatesData} from '../../shared/callback-types';
import {upsertPartner} from '../../redux/reducers/partnerReducer';
import {addDialog, addMessage} from '../../redux/reducers/dialogsReducer';

export const getUpdates = async (): Promise<void> => {
    const userId = await getUserId();
    if (!userId) {
        console.warn('Cannot get updates because user is not logged in');
        return;
    }
    // Get max lastMessageAt timestamp for the user
    const maxMessageLastUpdatedAt = store.getState().dialogs.lastMessageReceivedAt;
    // If user has no message then we must load initial chats
    if (!maxMessageLastUpdatedAt) {
        await initialLoadDialogs();
        return;
    }

    // Get list of partner ids with whom user has active chats
    const partners = Object.values(store.getState().dialogs.metas)
        .filter((d) => d.active)
        .map((d) => d.partner ?? d.users.find((u) => u !== userId));
    return new Promise((resolve, reject) => {
        try {
            socketEmit(
                getUpdatesV3,
                {
                    maxMessageLastUpdatedAt,
                    partners,
                },
                (error: SharedError | null, data: OnGetUpdatesData) => {
                    if (error) {
                        reject(error);
                        return;
                    }
                    data.partnersInfo.forEach((partnerPublicInfo) => {
                        store.dispatch(upsertPartner({partnerPublicInfo}));
                    });
                    data.metas.forEach(({_id: dialogId, meta}) => {
                        const partnerId = meta.users.find((uid) => userId !== uid);
                        store.dispatch(addDialog({dialogId, meta, partnerId}));
                    });
                    data.messages.forEach(({dialogId, message}) => {
                        store.dispatch(addMessage({dialogId, message}));
                    });
                    resolve();
                }
            );
        } catch (e) {
            reject(e);
        }
    });
};
