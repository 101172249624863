import './SettingsAvatarModalFooter.css';
import React, {ReactElement} from 'react';

interface Props {
    onDelete: () => void;
}

export const SettingsAvatarModalFooter = (props: Props): ReactElement => {
    const {onDelete} = props;
    return (
        <div className={'SettingsAvatarModalFooter-container'} onClick={onDelete}>
            <span className={'SettingsAvatarModalFooter-span'}>Удалить</span>
        </div>
    );
};
