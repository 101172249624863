import React, {ReactElement} from 'react';
import {ExtraButton} from './ExtraButton';
import {ReportIcon} from '../../../assets/svg/ReportIcon';
import {AddFriendIcon} from '../../../assets/svg/AddFriendIcon';
import {sendFriendRequest} from '../../../utils/dialog/sendFriendRequest';
import {cancelFriendRequest} from '../../../utils/dialog/cancelFriendRequest';
import {CancelFriendRequestIcon} from '../../../assets/svg/CancelFriendRequestIcon';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    dialogId: string;
    isClosed: boolean;
    isLikedByMe: boolean;
    isLikedByPartner: boolean;
}

export const FirstExtraButton = (props: Props): ReactElement => {
    const {dialogId, isClosed, isLikedByPartner, isLikedByMe} = props;

    if (isClosed) {
        return <ExtraButton title={getLocalizedString('complaint')} icon={<ReportIcon />} onPress={() => {}} />;
    }

    if (isLikedByMe) {
        return (
            <ExtraButton
                title={getLocalizedString('removeFromFriends')}
                icon={<CancelFriendRequestIcon />}
                onPress={() => cancelFriendRequest(dialogId)}
            />
        );
    }

    if (isLikedByPartner) {
        return (
            <ExtraButton
                title={getLocalizedString('acceptFriendRequest')}
                icon={<AddFriendIcon />}
                onPress={() => sendFriendRequest(dialogId)}
            />
        );
    }

    return (
        <ExtraButton
            title={getLocalizedString('addToFriends')}
            icon={<AddFriendIcon />}
            onPress={() => sendFriendRequest(dialogId)}
        />
    );
};
