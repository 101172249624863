import './GenderFilter.css';
import React, {ReactElement} from 'react';
import {OptionsPicker} from '../Utils/OptionsPicker/OptionsPicker';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {updateUser} from '../../utils/user/updateUser';
import {updateUserSearchPreferences} from '../../redux/reducers/userReducer';
import {SearchPreference} from '../../shared/search-preferences';
import {getLocalizedString} from '../../utils/helpers/getLocalizedString';

export const GenderFilter = (): ReactElement => {
    const hasPremium = useAppSelector((state) => state.user.data.hasPremium);
    const userSearchPreferences = useAppSelector((state) => {
        const enabledGenders = Object.entries(state?.user?.data?.searchPreferences)
            .filter(([_, isActive]) => isActive)
            .map(([gender, _]) => gender);

        // If no genders chosen then enable dunno
        if (!enabledGenders.length) {
            return ['dunno'];
        }
        return enabledGenders;
    });
    const dispatch = useAppDispatch();
    const onGenderPress = (pressedGender: string): void => {
        // If user clicks on already enabled gender then ignore it
        if (userSearchPreferences.includes(pressedGender)) {
            return;
        }
        const searchPreferences: SearchPreference = {
            male: pressedGender === 'male',
            female: pressedGender === 'female',
        };

        updateUser('searchPreferences', searchPreferences).then(() => {
            dispatch(updateUserSearchPreferences({searchPreferences}));
        });
    };

    const isGenderDisabled = (gender: string): boolean => {
        if (hasPremium) {
            return false;
        }
        return gender !== 'dunno';
    };

    const allGenders = {
        male: getLocalizedString('male'),
        female: getLocalizedString('female'),
        dunno: getLocalizedString('dunno'),
    };

    return (
        <div className={'GenderFilter-container'}>
            <span className={'GenderFilter-title-span'}>{getLocalizedString('partnerGender')}</span>
            <OptionsPicker
                allOptions={allGenders}
                activeOptions={userSearchPreferences}
                onPress={onGenderPress}
                isDisabled={isGenderDisabled}
            />
        </div>
    );
};
