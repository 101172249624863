import React, {ReactElement, useEffect, useState} from 'react';
import {useAppSelector} from '../../redux/hooks';
import {getAdminIdByLanguage} from '../../utils/helpers/getAdminIdByLanguage';
import {store} from '../../redux/store';
import {fetchDialogWithAdmin} from '../../utils/fetchData/fetchDialogWithAdmin';
import {Chat} from '../../components/Chat/Chat';

export const SupportRoute = (): ReactElement => {
    const adminDialogId = useAdminDialogId();

    if (!adminDialogId) {
        return <></>;
    }

    return <Chat dialogId={adminDialogId} />;
};

const useAdminDialogId = () => {
    const [adminDialogId, setAdminDialogId] = useState<string>();
    const language = useAppSelector((state) => state.user.data.language);

    useEffect(() => {
        const adminLogin = getAdminIdByLanguage(language ?? 'en');
        const adminDialog = Object.entries(store.getState().dialogs.metas).find(
            ([_, meta]) => meta.partner === adminLogin
        );
        // If admin dialog does not exist then fetch it
        if (!adminDialog) {
            fetchDialogWithAdmin().then(({dialogId}) => {
                setAdminDialogId(dialogId);
            });
        } else {
            setAdminDialogId(adminDialog[0]);
        }
    }, [language]);
    return adminDialogId;
};
