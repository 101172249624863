import {OnUserTyping} from '../../shared/callback-types';
import {store} from '../../redux/store';
import {updateTypingStatus} from '../../redux/reducers/partnerReducer';

const eventKeys: {[key: string]: string} = {};

export const onUserTyping = async (data: OnUserTyping): Promise<void> => {
    const {partnerId, action} = data;
    const radix = 36;
    const length = 7;
    const key = Math.random().toString(radix).substring(length);
    // Each data has data.key object which is just a random 7-chars string
    // we store event's key to check it before get rid of "partner is typing.." sign
    // we will remove "partner is typing..." sign only in case keys are similar
    // each new event will reinitialize lastEventKey and the previous event won't remove typing sign
    // that's how we fight against users who are consistently clicking buttons every 2 seconds
    eventKeys[partnerId] = key;
    store.dispatch(updateTypingStatus({partnerId, typingStatus: action}));

    const fadeoutDelayInMs = 3000;
    setTimeout(() => {
        // If keys are equal it means that the last event enters the function and user isn't typing anymore
        if (eventKeys[data.partnerId] === key) {
            store.dispatch(updateTypingStatus({partnerId, typingStatus: undefined}));
        }
    }, fadeoutDelayInMs);
};
