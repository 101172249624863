import React, {ReactElement} from 'react';
import {useParams} from 'react-router-dom';
import {useAppSelector} from '../redux/hooks';
import {ChatProfile} from '../components/Chat/ChatProfile/ChatProfile';
import {DialogNotFound} from '../components/Utils/DialogNotFound';

export const ChatRouteProfile = (): ReactElement | null => {
    const {dialogId} = useParams();
    const doesDialogExist = useAppSelector((state) => state.dialogs.metas[dialogId ?? '']);
    if (!doesDialogExist || !dialogId) {
        return <DialogNotFound dialogId={dialogId} />;
    }

    return <ChatProfile dialogId={dialogId} />;
};
