import './MessageInfo.css';
import React, {ReactElement} from 'react';
import {MessageTimestamp} from './MessageTimestamp';
import {MessageType} from '../../../../../shared/message-type';
import {MessageStatus as MessageStatusType} from '../../../../../shared/message-status';
import {MessageStatus} from './MessageStatus';
import {isUserMessage} from '../../../../../utils/message/isUserMessage';
import {MessageEditedLabel} from './MessageEditedLabel';

interface Props {
    sentAt: Date;
    messageType: MessageType;
    status: MessageStatusType;
    isSentByMe: boolean;
    isEdited: boolean;
}

export const MessageInfo = (props: Props): ReactElement | null => {
    const {sentAt, messageType, status, isSentByMe, isEdited} = props;
    if (!isUserMessage(messageType)) {
        return null;
    }
    return (
        <div className={'MessageInfo-container'}>
            <MessageEditedLabel isEdited={isEdited} isSentByMe={isSentByMe} />
            <MessageTimestamp sentAt={sentAt} isSentByMe={isSentByMe} />
            <MessageStatus status={status} isSentByMe={isSentByMe} />
        </div>
    );
};
