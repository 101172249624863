import './ChatPreview.css';
import {ReactElement} from 'react';
import {TypingTypes} from '../../../shared/typing-types';
import {prettifyTypingStatus} from '../../../utils/helpers/prettifyTypingStatus';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    username: string;
    lastMessageText: string;
    draft: string;
    typingStatus: TypingTypes | undefined;
    isSentByMe: boolean;
    isMuted: boolean;
}

export const PreviewContent = (props: Props): ReactElement => {
    const {username, lastMessageText, draft, typingStatus, isSentByMe, isMuted} = props;
    const name = username?.length === 0 ? getLocalizedString('username') : username;
    const isDraftExists = draft?.length > 0;
    let content = [];
    let title = lastMessageText;

    if (typingStatus) {
        content.push(getLocalizedString(prettifyTypingStatus[typingStatus]));
    } else {
        if (isSentByMe && !isDraftExists) {
            content.push(
                <span key={'you'} className={`PreviewContent-last-message-text-sender`}>
                    {getLocalizedString('you')}:&nbsp;
                </span>
            );
        }
        if (isDraftExists) {
            content.push(
                <span key={'you'} className={`PreviewContent-last-message-text-sender draft`}>
                    {getLocalizedString('draft')}:&nbsp;
                </span>
            );
            content.push(draft);
        } else {
            content.push(lastMessageText);
        }
    }

    if (typingStatus) {
        title = getLocalizedString(prettifyTypingStatus[typingStatus ?? '']);
    } else if (isDraftExists) {
        title = draft;
    }

    return (
        <div className={'PreviewContent-container'}>
            <span className={'PreviewContent-username'} title={name}>
                {name}
            </span>
            <span
                className={`PreviewContent-last-message-text ${isMuted && 'muted'} ${typingStatus && 'typing'}`}
                title={title}
            >
                {content}
            </span>
        </div>
    );
};
