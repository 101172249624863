import {socketEmit} from '../../socket/socketEmit';
import {getAdminDialogs, getDialogs as getDialogsEvent} from '../../shared/events';
import {AdminPublicUserInfo, PublicUserInfo} from '../../shared/callback-types';
import {store} from '../../redux/store';
import {addDialog, addMessage} from '../../redux/reducers/dialogsReducer';
import {upsertPartner} from '../../redux/reducers/partnerReducer';
import {SharedError} from '../../shared/errors-types';
import {Dialog} from '../../shared/dialog';
import {getUserId} from '../user/getUserId';
import {isUserAdmin} from '../dialog/checkServiceChats';
import {initialLoadDone} from '../../redux/reducers/localSettingsReducer';

type LoadDialogsParams = {offset: number; clientDialogsIds?: never} | {offset?: never; clientDialogsIds: string[]};

export const loadDialogs = async (params: LoadDialogsParams, limit: number): Promise<{dialogsCount: number}> => {
    const uid = await getUserId();
    const isAdmin = isUserAdmin(uid) || store.getState().admin.isAdmin;
    const event = isAdmin ? getAdminDialogs : getDialogsEvent;
    return new Promise((resolve, reject) => {
        socketEmit(
            event,
            {...params, limit: limit},
            async (
                error: SharedError | null,
                data: {
                    dialogs: Dialog[];
                    partnersInfo: Array<PublicUserInfo | AdminPublicUserInfo>;
                }
            ) => {
                try {
                    if (error) {
                        console.log('Error in loadDialogs', error);
                        reject();
                        return;
                    }
                    data.dialogs.forEach(({_id: dialogId, meta, messages}) => {
                        const partnerId = meta.users.find((uid) => store.getState().user.userId !== uid);
                        store.dispatch(addDialog({dialogId, meta, partnerId}));
                        messages.forEach((message) => {
                            store.dispatch(addMessage({dialogId, message}));
                        });
                    });
                    data.partnersInfo.forEach((partnerPublicInfo) => {
                        store.dispatch(upsertPartner({partnerPublicInfo}));
                    });
                } finally {
                    resolve({dialogsCount: data.dialogs.length});
                    store.dispatch(initialLoadDone());
                }
            }
        );
    });
};

export const initialLoadDialogs = async (): Promise<{dialogsCount: number}> => {
    return loadDialogs({offset: 0}, 10);
};
