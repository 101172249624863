import './RecordButton.css';
import React, {ReactElement, useEffect} from 'react';
import {FaMicrophone} from 'react-icons/fa';
import {RecordButtonProps} from '../../../../types/recorder';
import {SendButtonProps} from './SendButton';

interface Props extends RecordButtonProps, Pick<SendButtonProps, 'handleClick'> {}

export const RecordButton = (props: Props): ReactElement => {
    const {recorderState, handlers, handleClick} = props;
    const {startRecording, stopRecording} = handlers;

    useEffect(() => {
        if (!recorderState.initRecording && recorderState.audio) {
            handleClick();
        }
    }, [recorderState.audio, handleClick, recorderState.initRecording]);

    return (
        <div
            className={'RecordButton-container'}
            onClick={() => {
                if (!recorderState.initRecording) {
                    startRecording();
                } else {
                    stopRecording();
                }
            }}
        >
            <FaMicrophone className={`RecordButton-icon ${recorderState.initRecording && 'send'}`} />
        </div>
    );
};
