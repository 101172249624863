import {useState} from 'react';

export const useToggle = (initialValue = false) => {
    const [isToggled, setToggle] = useState(initialValue);
    const toggle = () => setToggle((prevState) => !prevState);
    return {
        isToggled,
        toggle,
    };
};
