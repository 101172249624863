import * as React from 'react';

interface Props {
    color: string;
}

export function SentStatus(props: Props): JSX.Element {
    return (
        <svg width={11} height={9} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M9.45.267L3.267 6.449.817 4 0 4.821l3.267 3.267 7-7L9.45.267z" fill={props.color} />
        </svg>
    );
}
