import {TypingTypes} from '../../shared/typing-types';

// This object maps the typing status to a related localized string key
// don't get confused why they are the same, it's just a coincidence and can be changed in the future.
export const prettifyTypingStatus: Record<TypingTypes, string> = {
    typing: 'typing',
    recording: 'recording',
    choosingImage: 'choosingImage',
    takingPhoto: 'takingPhoto',
    recordingVideo: 'recordingVideo',
};
