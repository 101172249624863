import React, {memo, ReactElement} from 'react';
import './MessageAboutMe.css';

interface Props {
    text: string;
}

export const MessageAboutMe = memo((props: Props): ReactElement => {
    const {text} = props;
    return <p className={'MessageAboutMe-text'}>{text}</p>;
});
