import './FancyModal.css';
import React, {forwardRef, memo, PropsWithChildren, ReactElement, useImperativeHandle, useState} from 'react';
import {BasicModal} from './BasicModal';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    title: string;
    description?: string;
    hideCloseButton?: boolean;
}

export interface ModalRef {
    open: () => void;
    close: () => void;
}

export const FancyModal = memo(
    forwardRef<ModalRef, PropsWithChildren<Props>>((props, ref): ReactElement | null => {
        const {title, description, children, hideCloseButton} = props;
        const [isOpen, setIsOpen] = useState(false);
        useImperativeHandle(ref, () => ({
            open: () => setIsOpen(true),
            close: () => setIsOpen(false),
        }));

        return (
            <BasicModal isVisible={isOpen} onBackdropPress={() => setIsOpen(false)}>
                <div className={'FancyModal-container'}>
                    <h5 className={'FancyModal-header-title'}>{title}</h5>
                    {description && <span className={'FancyModal-description-span'}>{description}</span>}
                    {children}
                    {!hideCloseButton && (
                        <span className={'FancyModal-close-span'} onClick={() => setIsOpen(false)}>
                            {getLocalizedString('cancelButton')}
                        </span>
                    )}
                </div>
            </BasicModal>
        );
    })
);
