import './InterestsFilter.css';
import React, {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {OptionsPicker} from '../Utils/OptionsPicker/OptionsPicker';
import {ClientInterest} from '../../shared/interests';
import {Language} from '../../shared/language';
import {updateUser} from '../../utils/user/updateUser';
import {updateUserInterests} from '../../redux/reducers/userReducer';
import {getLocalizedString} from '../../utils/helpers/getLocalizedString';

export const InterestsFilter = (): ReactElement => {
    const language = useAppSelector((state) => state?.user?.data?.language);
    const allInterests = useAppSelector((state) =>
        _parseInterests(state?.localSettings?.allInterests, language ?? 'ru')
    );
    const userInterests = useAppSelector((state) => state?.user?.data?.interests);
    const dispatch = useAppDispatch();

    const onOptionPress = async (pressedInterest: string): Promise<void> => {
        const newInterests = userInterests?.includes(pressedInterest)
            ? userInterests.filter((i) => i !== pressedInterest)
            : [...userInterests, pressedInterest];
        updateUser('interests', newInterests).then(() => {
            dispatch(updateUserInterests({interests: newInterests}));
        });
    };
    const onClearInterests = () => {
        updateUser('interests', []).then(() => {
            dispatch(updateUserInterests({interests: []}));
        });
    };
    return (
        <div className={'InterestsFilter-container'}>
            <span className={'InterestsFilter-title-span'}>{getLocalizedString('interestsTitle')}</span>
            <OptionsPicker allOptions={allInterests} activeOptions={userInterests} onPress={onOptionPress} />
            <span className={'InterestsFilter-clear-interests-span'} onClick={onClearInterests}>
                {getLocalizedString('clearInterests')}
            </span>
        </div>
    );
};

const _parseInterests = (interests: ClientInterest[], language: Language): Record<string, string> => {
    // Make an object where each field is {key: translatedInterest}
    return Object.assign(
        {},
        ...Array.from(interests).map(({name, translations}) => ({[name]: translations[language]}))
    );
};
