import './LoggedIn.css';
import React, {ReactElement} from 'react';
import {BasicButton} from '../Utils/Buttons/BasicButton';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {logoutAdmin} from '../../redux/reducers/adminReducer';

export const LoggedIn = (): ReactElement => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <div>
            <span>You are already logged in</span>
            <BasicButton
                title={'Logout'}
                onClick={() => {
                    dispatch(logoutAdmin());
                    window.location.reload();
                }}
            />
            <BasicButton
                title={'Go to main'}
                onClick={() => {
                    navigate('explore', {replace: true});
                }}
            />
        </div>
    );
};
