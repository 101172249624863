import React, {ReactElement} from 'react';
import {useAppSelector} from '../redux/hooks';
import {ChatProfile} from '../components/Chat/ChatProfile/ChatProfile';
import {DialogNotFound} from '../components/Utils/DialogNotFound';

export const ExploreProfileRoute = (): ReactElement | null => {
    const exploreDialogId = useAppSelector((state) => state.user.data.exploreDialogId);
    if (!exploreDialogId) {
        return <DialogNotFound dialogId={exploreDialogId} />;
    }
    return <ChatProfile dialogId={exploreDialogId} />;
};
