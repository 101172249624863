import React, {ReactElement} from 'react';
import './Explore.css';
import {Content} from './Content';
import {SearchButton} from './SearchButton';
import {ExploreBurgerMenuTrigger} from './ExploreBurgerMenuTrigger';
import {InterestsFilter} from './InterestsFilter';
import {GenderFilter} from './GenderFilter';
import {useAppSelector} from '../../redux/hooks';
import {LanguageContext} from '../../contexts/languageContext';

export const Explore = (): ReactElement => {
    const language = useAppSelector((state) => state.user.data.language ?? 'en');
    return (
        <LanguageContext.Provider value={language}>
            <div className={'Explore-container'}>
                <ExploreBurgerMenuTrigger />
                <div className={'Explore-content'}>
                    <Content />
                    <SearchButton />
                    <GenderFilter />
                    <InterestsFilter />
                </div>
            </div>
        </LanguageContext.Provider>
    );
};
