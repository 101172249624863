import './MessageSystem.css';
import React, {memo, ReactElement} from 'react';

interface Props {
    text: string;
}

export const MessageSystem = memo((props: Props): ReactElement => {
    const {text} = props;
    return <p className={'MessageSystem-text'}>{text}</p>;
});
