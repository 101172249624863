import './DialogNotFound.css';
import React, {ReactElement, useEffect} from 'react';
import dialogNotFound from '../../assets/images/dialogNotFound.png';
import {useNavigate} from 'react-router-dom';
import {getLocalizedString} from '../../utils/helpers/getLocalizedString';
import {loadDialog} from '../../utils/fetchData/loadDialog';

interface Props {
    dialogId: string | undefined | null;
}

export const DialogNotFound = (props: Props): ReactElement => {
    const {dialogId} = props;
    const navigate = useNavigate();

    useEffect(() => {
        if (dialogId) {
            loadDialog({dialogId});
        }
    }, [dialogId]);
    return (
        <div className={'DialogNotFound-container'}>
            <img src={dialogNotFound} alt={'dialog not found'} />
            <span className={'DialogNotFound-title'}>{getLocalizedString('dialogNotFound')}</span>
            <button className={'DialogNotFound-button'} onClick={() => navigate('/explore', {replace: true})}>
                {getLocalizedString('goToExplore')}
            </button>
        </div>
    );
};
