import {socketEmit} from '../../socket/socketEmit';
import {quitSearchingQueue} from '../../shared/events';
import {store} from '../../redux/store';
import {stopSearching as stopSearchingAction} from '../../redux/reducers/userReducer';

export const stopSearching = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
        socketEmit(quitSearchingQueue, (err: Error | null) => {
            if (err) {
                console.log('stop search error', err);
                reject(err);
                return;
            }
            resolve();
            store.dispatch(stopSearchingAction());
        });
    });
};
