import './PageHeader.css';
import React, {ReactElement} from 'react';
import {BackButton, BackButtonProps} from './BackButton';
import {Spacer} from './Spacer';
import {BurgerMenuTrigger} from './BurgerMenuTrigger';

interface Props extends BackButtonProps {
    hideBackButton?: boolean;
    hideBurgerMenu?: boolean;
}

export const PageHeader = (props: Props): ReactElement => {
    const {hideBackButton, hideBurgerMenu, title} = props;
    return (
        <div className={'PageHeader-container'}>
            {!hideBackButton && <BackButton title={title} />}
            <Spacer />
            {!hideBurgerMenu && <BurgerMenuTrigger />}
        </div>
    );
};
