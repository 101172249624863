import React, {ReactElement} from 'react';
import {LastSeenStatus} from '../../../shared/callback-types';
import {TypingTypes} from '../../../shared/typing-types';
import {OnlineStatus} from './OnlineStatus';
import {ChatHeaderTypingStatus} from './ChatHeaderTypingStatus';

interface Props {
    lastSeenStatus: LastSeenStatus;
    typingStatus: TypingTypes | undefined;
}

export const ChatHeaderStatus = (props: Props): ReactElement => {
    const {typingStatus, lastSeenStatus} = props;

    if (typingStatus) {
        return <ChatHeaderTypingStatus typingStatus={typingStatus} />;
    } else {
        return <OnlineStatus lastSeenStatus={lastSeenStatus} />;
    }
};
