import './MessageReactions.css';
import {UserReaction} from '../../../../../shared/user-reaction';
import MessageReaction from './MessageReaction';
import {useEffect, useState} from 'react';
import {Reaction} from '../../../../../shared/reaction';

interface Props {
    reactions: UserReaction[];
    isMsgSentByMe: boolean;
}

const MessageReactions = (props: Props): JSX.Element | null => {
    const {reactions, isMsgSentByMe} = props;
    const [groupedReactions, setGroupedReactions] = useState<Array<[string, string[]]>>();
    useEffect(() => {
        const groupedByReactionId = reactions.reduce((acc, reaction) => {
            if (!acc[reaction.reactionId]) {
                acc[reaction.reactionId] = [];
            }
            acc[reaction.reactionId].push(reaction.userId);
            return acc;
        }, {} as Record<Reaction, string[]>);

        setGroupedReactions(Object.entries(groupedByReactionId));
    }, [reactions]);
    if (reactions.length === 0) {
        return null;
    }
    return (
        <div className={`MessageReactions-container ${isMsgSentByMe ? 'me' : 'partner'}`}>
            {groupedReactions?.map(([reactionId, users]) => (
                <MessageReaction
                    key={reactionId}
                    users={users}
                    isSentByMe={isMsgSentByMe}
                    reactionId={reactionId as Reaction}
                />
            ))}
        </div>
    );
};

export default MessageReactions;
