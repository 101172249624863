import './EmojiButton.css';
import React, {ReactElement} from 'react';
import {FiSmile} from 'react-icons/fi';
import {EmojiPanel} from './EmojiPanel';
import {useAppDispatch, useAppSelector} from '../../../../redux/hooks';
import {
    hideEmojiPanel,
    showEmojiPanel as dispatchShowEmojiPanel,
} from '../../../../redux/reducers/localSettingsReducer';

interface Props {
    onEmojiPress: (text: string) => void;
}

export const EmojiButton = (props: Props): ReactElement => {
    const {onEmojiPress} = props;
    const dispatch = useAppDispatch();
    const showEmojiPanel = useAppSelector((state) => state.localSettings.showEmojiPanel);
    return (
        <div
            className={'EmojiButton-container'}
            onClick={() => {
                if (showEmojiPanel) {
                    dispatch(hideEmojiPanel());
                } else {
                    dispatch(dispatchShowEmojiPanel());
                }
            }}
        >
            <FiSmile color={'#93919D'} size={22} />
            <EmojiPanel isVisible={showEmojiPanel} onEmojiPress={onEmojiPress} />
        </div>
    );
};
