import './Toast.css';
import React, {forwardRef, ReactElement, useImperativeHandle, useState} from 'react';
import {createPortal} from 'react-dom';

interface Props {
    title: string;
}

export interface ToastRef {
    show: () => void;
}

export const Toast = forwardRef<ToastRef, Props>((props, ref): ReactElement | null => {
    const {title} = props;
    const [isVisible, setIsVisible] = useState(false);
    useImperativeHandle(ref, () => ({
        show: () => {
            setIsVisible(true);
            setTimeout(() => setIsVisible(false), 4000);
        },
    }));

    if (!isVisible) {
        return null;
    }

    return createPortal(
        <div className={`Toast-container ${isVisible ? 'show' : ''}`}>
            <span className={'Toast-title'}>{title}</span>
        </div>,
        document.getElementById('root')!
    );
});
