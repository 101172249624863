import './ChatProfilePicture.css';
import React, {ReactElement} from 'react';
import dunno from '../../../assets/images/avatars/dunno.png';
import {Gender} from '../../../shared/gender';
import {genderImage, moderatorImage} from '../../../constants/genderImage';
import {ProfilePicture} from '../../Utils/ProfilePicture';

interface Props {
    profilePictureUrl: string | null;
    isTemporaryDialog: boolean;
    gender: Gender;
    hasPremium: boolean;
    isSystemChat: boolean;
}

export const ChatProfilePicture = (props: Props): ReactElement => {
    const {profilePictureUrl, isTemporaryDialog, gender, hasPremium, isSystemChat} = props;
    let src = dunno;
    if (isSystemChat) {
        src = moderatorImage;
    } else if (!isTemporaryDialog) {
        src = profilePictureUrl;
    }
    return (
        <ProfilePicture
            profilePictureUrl={src ?? genderImage[gender]}
            isClickable={Boolean(profilePictureUrl)}
            height={140}
            width={140}
            hasPremium={hasPremium}
        />
    );
};
