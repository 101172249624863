import * as React from 'react';

export function NoFriendsIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.463 10.021a.898.898 0 01-.472.132.898.898 0 01-.473-.132L.761 5.895A.5.5 0 000 6.322v5.21a2.362 2.362 0 002.361 2.362H13.64A2.362 2.362 0 0016 11.533V6.32a.5.5 0 00-.76-.427L8.463 10.02z"
                fill="#77767D"
            />
            <path
                d="M13.639 2.106H2.36c-.866 0-1.63.48-2.034 1.19-.21.37-.008.81.355 1.03l6.806 4.15a1 1 0 001.042 0l6.79-4.149c.362-.221.563-.66.353-1.03a2.343 2.343 0 00-2.034-1.19z"
                fill="#77767D"
            />
        </svg>
    );
}
