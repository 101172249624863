import './ChatExtraButtons.css';
import React, {memo, ReactElement} from 'react';
import {ExtraButton} from './ExtraButton';
import {NewPartnerIcon} from '../../../assets/svg/NewPartnerIcon';
import {closeDialog} from '../../../utils/dialog/closeDialog';
import {FirstExtraButton} from './FirstExtraButton';
import {BsFillXCircleFill} from 'react-icons/bs';
import {startSearching} from '../../../utils/user/startSearching';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    dialogId: string;
    isClosed: boolean;
    isTemporary: boolean;
    isLikedByMe: boolean;
    isLikedByPartner: boolean;
}

export const ChatExtraButtons = memo((props: Props): ReactElement | null => {
    const {dialogId, isTemporary} = props;

    if (!isTemporary) {
        return null;
    }

    return (
        <div className={'ChatExtraButtons-container'}>
            <FirstExtraButton {...props} />
            <ExtraButton
                title={getLocalizedString('closeDialog')}
                icon={<BsFillXCircleFill color={'#201F24'} size={15} />}
                onPress={() => closeDialog(dialogId)}
                isMiddle={true}
            />
            <ExtraButton
                title={getLocalizedString('newPartner')}
                icon={<NewPartnerIcon />}
                onPress={() => closeDialog(dialogId).then(() => startSearching())}
            />
        </div>
    );
});
