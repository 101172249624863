import {store} from '../../redux/store';
import {isSystemChat, isUserAdmin} from './checkServiceChats';

export const getPartnerName = (dialogId: string): string => {
    const dialogMeta = store.getState().dialogs.metas[dialogId];
    const userId = store.getState().user.userId;
    const partnerId = dialogMeta.partner ?? dialogMeta.users.find((uid) => uid !== userId);
    if (!dialogMeta || dialogMeta.temporary || !partnerId) {
        return 'username';
    }
    if (isUserAdmin(partnerId)) {
        return 'admin';
    }

    if (isSystemChat(partnerId)) {
        return 'anonymousChat';
    }

    const partner = store.getState().partners.partners[partnerId];
    if (!partner) {
        return 'username';
    }

    const customName = dialogMeta.customDialogNameFor?.[userId ?? '']; // If user has renamed their partner
    const partnerUsername = partner.publicSettings?.username; // partner's username
    const partnerDialogName = dialogMeta.dialogNameFor?.[userId ?? '']; // automatically generated name for partner
    return customName ?? partnerUsername ?? partnerDialogName ?? 'username';
};
