export const getImageSize = (image: File): Promise<{width: number; height: number}> => {
    return new Promise((resolve) => {
        const img = new Image();
        const obj = URL.createObjectURL(image);
        img.onload = function () {
            // Couldn't resolve type issue here
            // @ts-ignore
            resolve({width: this.width, height: this.height});
        };
        img.src = obj;
    });
};
