import * as React from 'react';

export function AttachIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.383 2.292c-.731 0-1.413.392-1.781 1.023L6.594 5.042H4.812A2.983 2.983 0 001.833 8.02v8.708a2.983 2.983 0 002.98 2.98h12.374a2.983 2.983 0 002.98-2.98V8.021a2.983 2.983 0 00-2.98-2.98h-1.782l-1.007-1.726a2.069 2.069 0 00-1.782-1.023H9.383zM11 7.792a4.13 4.13 0 014.125 4.125A4.13 4.13 0 0111 16.042a4.13 4.13 0 01-4.125-4.125A4.13 4.13 0 0111 7.792zm0 1.375a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z"
                fill="#93919D"
            />
        </svg>
    );
}
