import React, {forwardRef, ReactElement} from 'react';
import {PixelCrop} from 'react-image-crop';

interface Props {
    completedCrop: PixelCrop | undefined;
}

export const Canvas = forwardRef<HTMLCanvasElement, Props>((props, ref): ReactElement => {
    const {completedCrop} = props;
    return (
        <canvas
            ref={ref}
            style={{
                display: 'none',
                width: completedCrop?.width,
                height: completedCrop?.height,
            }}
        />
    );
});
