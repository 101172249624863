import './ChatProfileUsername.css';
import React, {ReactElement} from 'react';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    username: string;
    isTemporary: boolean;
}

export const ChatProfileUsername = (props: Props): ReactElement => {
    const {username, isTemporary} = props;
    const name = isTemporary || username?.length === 0 ? getLocalizedString('username') : username;
    return (
        <div className={'ChatProfileUsername-container'}>
            <span className={'ChatProfileUsername-text'}>{name}</span>
        </div>
    );
};
