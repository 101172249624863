import * as React from 'react';

export function CancelFriendRequestIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={15} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#prefix__clip0_4407_1523)">
                <path
                    d="M14.5 3.607c0 2.557-2.94 5.049-4.06 6.032l-.14.066c-.98.852-2.38 1.967-2.38 1.967L7.5 12l-.42-.393S5.75 10.492 4.7 9.639l-.14-.065C3.44 8.656.5 6.164.5 3.607a3.359 3.359 0 01.278-1.388c.191-.441.475-.841.834-1.178a3.824 3.824 0 011.256-.78c.47-.179.975-.268 1.482-.26.746.005 1.474.21 2.1.59l-1.75 4h2.8l-.7 3.278 3.5-4.59H7.5L8.83.459A3.888 3.888 0 0110.65 0a4.021 4.021 0 011.482.26c.47.18.898.445 1.256.781.36.337.643.737.834 1.178.19.44.285.912.278 1.388z"
                    fill="#201F24"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_4407_1523">
                    <path fill="#fff" d="M0 0h15v12H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
