import './ChatProfileMenu.css';
import React, {ReactElement, useRef} from 'react';
import {ChatProfileMenuItem, ProfileMenuItem} from './ChatProfileMenuItem';
import {BsFillXCircleFill} from 'react-icons/bs';
import {GoUnmute, GoMute} from 'react-icons/go';
import {AiFillExclamationCircle} from 'react-icons/ai';
import {GoCircleSlash} from 'react-icons/go';
import {ModalRef} from '../../../Utils/Modals/FancyModal';
import {ReportModal} from '../../../Utils/Modals/ReportModal';
import {DeleteFriendModal} from '../../../Utils/Modals/DeleteFriendModal';
import {closeDialog} from '../../../../utils/dialog/closeDialog';
import {useNavigate} from 'react-router-dom';
import {toggleMute} from '../../../../utils/dialog/toggleMute';
import {getLocalizedString} from '../../../../utils/helpers/getLocalizedString';

interface Props {
    dialogId: string;
    isTemporaryDialog: boolean;
    isDialogMuted: boolean;
}

export const ChatProfileMenu = (props: Props): ReactElement => {
    const {dialogId, isTemporaryDialog, isDialogMuted} = props;
    const navigate = useNavigate();
    const reportModalRef = useRef<ModalRef>(null);
    const deleteFriendModalRef = useRef<ModalRef>(null);
    const _getMenuOptions = (): ProfileMenuItem[] => {
        const iconProps = {
            color: '#97A6F2',
            size: 23,
        };
        return [
            {
                title: getLocalizedString('complaint'),
                icon: <AiFillExclamationCircle {...iconProps} />,
                onPress: () => reportModalRef?.current?.open(),
            },
            {
                title: getLocalizedString(isTemporaryDialog ? 'closeDialog' : 'deleteFriendTitle'),
                icon: <BsFillXCircleFill {...iconProps} />,
                onPress: () => {
                    if (isTemporaryDialog) {
                        closeDialog(dialogId).finally(() => navigate(-1));
                    } else {
                        deleteFriendModalRef?.current?.open();
                    }
                },
            },
            {
                title: getLocalizedString('block'),
                icon: <GoCircleSlash {...iconProps} />,
                onPress: () => {},
            },
            {
                title: getLocalizedString(isDialogMuted ? 'mute' : 'unmute'),
                icon: isDialogMuted ? <GoUnmute {...iconProps} /> : <GoMute {...iconProps} />,
                onPress: () => toggleMute(dialogId, isDialogMuted),
            },
        ];
    };

    return (
        <div className={'ChatProfileMenu-container'}>
            {_getMenuOptions().map(({title, icon, onPress}, idx) => {
                return (
                    <ChatProfileMenuItem key={title} title={title} icon={icon} isLast={idx === 3} onPress={onPress} />
                );
            })}
            <ReportModal ref={reportModalRef} dialogId={dialogId} />
            <DeleteFriendModal ref={deleteFriendModalRef} dialogId={dialogId} />
        </div>
    );
};
