import './DeletedChat.css';
import React, {ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';
import deletedChat from '../../assets/images/deletedChat.png';
import {removeDialog} from '../../utils/dialog/removeDialog';
import {getLocalizedString} from '../../utils/helpers/getLocalizedString';

interface Props {
    dialogId: string;
}

export const DeletedChat = (props: Props): ReactElement => {
    const {dialogId} = props;
    const navigate = useNavigate();
    return (
        <div className={'DeletedChat-container'}>
            <img src={deletedChat} alt={'deleted chat'} />
            <span className={'DeletedChat-title'}>{getLocalizedString('deletedDialog')}</span>
            <button
                className={'DeletedChat-button'}
                onClick={() => {
                    navigate('/explore', {replace: true});
                    removeDialog(dialogId);
                }}
            >
                {getLocalizedString('iSee')}
            </button>
        </div>
    );
};
