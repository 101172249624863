import './SettingsAvatar.css';
import React, {ChangeEvent, ReactElement, useEffect, useRef, useState} from 'react';
import {Gender} from '../../../shared/gender';
import {genderImage} from '../../../constants/genderImage';
import {AiFillCamera} from 'react-icons/ai';
import {uploadFileToDrive} from '../../../utils/helpers/uploadFileToDrive';
import {updateSettings} from '../../../utils/user/updateSettings';
import {ImageCropModal} from '../../Utils/Modals/ImageCropModal/ImageCropModal';
import {ModalRef} from '../../Utils/Modals/FancyModal';
import {SettingsAvatarModalFooter} from './SettingsAvatarModalFooter';
import {ProfilePicture} from '../../Utils/ProfilePicture';

interface Props {
    src: string | null;
    gender: Gender;
    hasPremium: boolean;
}

export const SettingsAvatar = (props: Props): ReactElement => {
    const {src, gender, hasPremium} = props;
    const [isUploading, setIsUploading] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const imageCropModalRef = useRef<ModalRef>(null);

    useEffect(() => {
        if (selectedImage) {
            imageCropModalRef.current?.open();
        }
    }, [selectedImage]);

    const onSubmit = (croppedImage: Blob): void => {
        setIsUploading(true);
        uploadFileToDrive(croppedImage)
            .then((url) => {
                if (url) {
                    updateSettings('profilePicture', url);
                }
            })
            .finally(() => setIsUploading(false));
    };

    const onFileSelect = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setSelectedImage(reader?.result?.toString() || ''));
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const onModalClose = (): void => {
        setSelectedImage('');
        formRef.current?.reset();
    };

    const onAvatarDelete = (): Promise<void> => updateSettings('profilePicture', null);

    return (
        <>
            <div className={`SettingsAvatar-container ${isUploading && 'uploading'}`}>
                <ProfilePicture
                    profilePictureUrl={src ?? genderImage[gender]}
                    isClickable={Boolean(src)}
                    height={140}
                    width={140}
                    extraStyles={{margin: 0}}
                    hasPremium={hasPremium}
                    imageModalChildren={<SettingsAvatarModalFooter onDelete={onAvatarDelete} />}
                />
                <div className={'SettingsAvatar-avatar-change-div'} onClick={() => inputRef?.current?.click()}>
                    <AiFillCamera
                        className={'SettingsAvatar-avatar-change-icon SettingsAvatar-avatar-change-icon-hovered'}
                    />
                </div>
            </div>
            <form ref={formRef} style={{display: 'none'}}>
                <input ref={inputRef} type="file" accept=".jpg, .jpeg, .png" onChange={onFileSelect} />
            </form>
            <ImageCropModal
                ref={imageCropModalRef}
                selectedImage={selectedImage}
                onSubmit={onSubmit}
                onClose={onModalClose}
            />
        </>
    );
};
