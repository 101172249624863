import 'moment/locale/ru';
import React, {useEffect} from 'react';
import {Route, Routes, BrowserRouter, Navigate, Outlet} from 'react-router-dom';
import {SideMenuLayout} from './routes/SideMenuLayout/SideMenuLayout';
import {ExploreRoute} from './routes/ExploreRoute';
import {ChatRoute} from './routes/ChatRoute';
import {ExploreProfileRoute} from './routes/ExploreProfileRoute';
import {ChatRouteProfile} from './routes/ChatRouteProfile';
import {getSocket} from './socket/createSocket';
import {SettingsRoute} from './routes/SettingsRoutes/SettingsRoute';
import {fetchUpdates} from './utils/fetchData/fetchUpdates';
import {LoginRoute} from './routes/LoginRoute';
import {SupportRoute} from './routes/SettingsRoutes/SupportRoute';
import {LanguageRoute} from './routes/SettingsRoutes/LanguageRoute';
import {NSFWRoute} from './routes/SettingsRoutes/NSFWRoute';
import {PrivacyRoute} from './routes/SettingsRoutes/PrivacyRoute';
import {AboutRoute} from './routes/SettingsRoutes/AboutRoute';
import {LANGUAGE} from './constants/localStorageKeys';
import {store} from './redux/store';

function App() {
    useEffect(() => {
        getSocket().finally(() => fetchUpdates());
        return () => {
            const language = store?.getState()?.user?.data?.language;
            if (language) {
                // Save language to localStorage to use it right away on next app start
                localStorage.setItem(LANGUAGE, language);
            }
        };
    }, []);
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SideMenuLayout />}>
                        <Route path="explore" element={<Outlet />}>
                            <Route index element={<ExploreRoute />} />
                            <Route path="profile" element={<ExploreProfileRoute />} />
                        </Route>
                        <Route path="dialog/:dialogId" element={<Outlet />}>
                            <Route index element={<ChatRoute />} />
                            <Route path="profile" element={<ChatRouteProfile />} />
                        </Route>
                        <Route path="settings" element={<Outlet />}>
                            <Route index element={<SettingsRoute />} />
                            <Route path="support" element={<SupportRoute />} />
                            <Route path="language" element={<LanguageRoute />} />
                            <Route path="nsfw" element={<NSFWRoute />} />
                            <Route path="privacy" element={<PrivacyRoute />} />
                            <Route path="about" element={<AboutRoute />} />
                        </Route>
                        <Route path="/" element={<Navigate to="/explore" replace />} />
                        <Route path="*" element={<Navigate to="/explore" replace />} />
                    </Route>
                    <Route path="login" element={<LoginRoute />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
