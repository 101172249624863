export const isUserAdmin = (uid: string | undefined | null): boolean => {
    if (uid) {
        return uid.startsWith('admin');
    } else {
        return false;
    }
};

export const isSystemChat = (partnerId: string | undefined): partnerId is 'system' => {
    if (partnerId) {
        return partnerId === 'system';
    } else {
        return false;
    }
};

export const isServiceChat = (partnerId: string | undefined): boolean => {
    if (partnerId) {
        return isUserAdmin(partnerId) || isSystemChat(partnerId);
    } else {
        return false;
    }
};
