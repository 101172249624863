import './AttachButton.css';
import React, {forwardRef, memo, ReactElement, useImperativeHandle, useRef} from 'react';
import {AttachIcon} from '../../../assets/svg/AttachIcon';
import {AttachButtonRefType} from '../../../types/attachButtonRefType';

interface Props {
    setSelectedImage: (file: File) => void;
}

export const AttachButton = memo(
    forwardRef<AttachButtonRefType, Props>((props, ref): ReactElement => {
        const {setSelectedImage} = props;
        const inputRef = useRef<HTMLInputElement>(null);
        const formRef = useRef<HTMLFormElement>(null);
        useImperativeHandle(ref, () => ({
            clearValue: () => {
                if (formRef.current) {
                    formRef.current?.reset();
                }
            },
        }));
        return (
            <div
                className={`AttachButton-container`}
                onClick={() => {
                    if (inputRef?.current) {
                        inputRef?.current?.click();
                    }
                }}
            >
                <AttachIcon />
                <form ref={formRef} style={{display: 'none'}}>
                    <input
                        ref={inputRef}
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={(event) => {
                            if (event.target.files && event.target.files.length > 0) {
                                setSelectedImage(event.target.files[0]);
                            }
                        }}
                    />
                </form>
            </div>
        );
    })
);
