import './MessageReply.css';
import React, {ReactElement} from 'react';
import {MessageAny} from '../../../../shared/message-any';
import {useAppSelector} from '../../../../redux/hooks';
import {getLocalizedString} from '../../../../utils/helpers/getLocalizedString';

interface Props {
    repliedMessage: MessageAny | undefined;
    isSentByMe: boolean;
}

export const MessageReply = (props: Props): ReactElement | null => {
    const {repliedMessage, isSentByMe} = props;
    const senderRelatedStyles = isSentByMe ? 'user' : 'partner';
    const partnerSettings = useAppSelector((state) => {
        // We only care about partners setting if reply was sent not by the user
        if (repliedMessage && !isSentByMe) {
            return state.partners.partners[repliedMessage.sender]?.publicSettings;
        }
    });

    const getName = (): string => {
        if (isSentByMe) {
            return getLocalizedString('me');
        }
        return partnerSettings?.username ?? getLocalizedString('username');
    };

    const getBody = (): string => {
        if (repliedMessage?.type === 'text') {
            return repliedMessage?.text ?? '';
        } else if (repliedMessage?.type === 'voice') {
            return getLocalizedString('lastMessageVoice');
        } else if (repliedMessage?.type === 'image') {
            return repliedMessage?.text && repliedMessage?.text?.length > 0
                ? repliedMessage?.text
                : getLocalizedString('lastMessageImage');
        }
        return '';
    };

    if (!repliedMessage) {
        return null;
    }

    return (
        <div className={'MessageReply-container'}>
            <div className={`MessageReply-content ${senderRelatedStyles}`}>
                {repliedMessage?.type === 'image' && (
                    <img className={'MessageReply-image'} src={repliedMessage.uri} alt={'Reply'} />
                )}
                <div className={`MessageReply-container-spans ${senderRelatedStyles}`}>
                    <span className={`MessageReply-content-name ${senderRelatedStyles}`}>{getName()}</span>
                    <span className={`MessageReply-content-body ${senderRelatedStyles}`}>{getBody()}</span>
                </div>
            </div>
        </div>
    );
};
