// Fetch all data that the website needs at the launch
import {loadUser} from './loadUser';
import {fetchInterests} from './fetchInterests';
import {loadTemplates} from '../admin/loadTemplates';
import {getUpdates} from './getUpdates';

export const fetchUpdates = async (): Promise<void> => {
    fetchInterests();
    await loadUser();
    getUpdates();
    loadTemplates();
};
