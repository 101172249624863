import './ChatHeaderTypingStatus.css';
import React, {ReactElement} from 'react';
import {TypingTypes} from '../../../shared/typing-types';
import {prettifyTypingStatus} from '../../../utils/helpers/prettifyTypingStatus';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    typingStatus: TypingTypes;
}

export const ChatHeaderTypingStatus = (props: Props): ReactElement => {
    const {typingStatus} = props;
    return (
        <span className={'ChatHeaderTypingStatus-span'}>
            {getLocalizedString(prettifyTypingStatus[typingStatus])}...
        </span>
    );
};
