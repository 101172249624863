import {ru} from '../../constants/translations/ru';
import {en} from '../../constants/translations/en';
import {Language} from '../../shared/language';
import {store} from '../../redux/store';

export const translations: Record<Language, Record<string, string>> = {
    ru: ru,
    en: en,
    id: en,
    es: en,
    it: en,
    pt: en,
};

export const getLocalizedString = (text: string): string => {
    const language = store.getState().user.data.language ?? 'en';
    return translations[language][text] ?? text;
};
