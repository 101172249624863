import './MessageStatus.css';
import React, {memo, ReactElement} from 'react';
import {MessageStatus as MessageStatusType} from '../../../../../shared/message-status';
import ClipLoader from 'react-spinners/ClipLoader';
import {exhaustiveCheck} from '../../../../../utils/helpers/exhaustiveCheck';
import {SentStatus} from '../../../../../assets/svg/SentStatus';
import {ReadStatus} from '../../../../../assets/svg/ReadStatus';

interface Props {
    status: MessageStatusType;
    isSentByMe: boolean;
}

export const MessageStatus = memo((props: Props): ReactElement | null => {
    const {status, isSentByMe} = props;
    if (!isSentByMe) {
        return null;
    }
    const color = isSentByMe ? '#CBD3F9' : '#93919D';
    switch (status) {
        case 'sending':
        case 'uploading':
            return (
                <div className={'MessageStatus-container'}>
                    <ClipLoader loading={true} size={5} color={color} />
                </div>
            );
        case 'sent':
        case 'reading':
            return (
                <div className={'MessageStatus-container'}>
                    <SentStatus color={color} />
                </div>
            );
        case 'read':
            return (
                <div className={'MessageStatus-container'}>
                    <ReadStatus color={color} />
                </div>
            );
        default:
            exhaustiveCheck(status);
            return null;
    }
});
