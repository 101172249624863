import './ImageCropModal.css';
import React, {useState, useRef, forwardRef, useImperativeHandle, ReactElement, useEffect} from 'react';
import ReactCrop, {centerCrop, makeAspectCrop, Crop, PixelCrop} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BasicModal} from '../BasicModal';
import {ModalRef} from '../FancyModal';
import {Canvas} from './Canvas';
import {canvasPreview} from './canvasPreview';
import {ImageCropModalFooter} from './ImageCropModalFooter';

interface Props {
    selectedImage: string;
    onSubmit: (croppedImage: Blob) => void;
    onClose: () => void;
}

// This is to demonstrate how to make and center a % aspect crop
// which is a bit trickier, so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            1,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

export const ImageCropModal = forwardRef<ModalRef, Props>((props, ref): ReactElement | null => {
    const {selectedImage, onSubmit, onClose} = props;
    const [isOpen, setIsOpen] = useState(false);
    useImperativeHandle(ref, () => ({
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
    }));
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const {width, height} = e.currentTarget;
        setCrop(centerAspectCrop(width, height));
    }
    useEffect(() => {
        if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
            canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
        }
    }, [completedCrop]);

    const onBackdropPress = (): void => {
        setIsOpen(false);
        onClose();
    };

    const onSave = (): void => {
        previewCanvasRef.current?.toBlob((blob) => {
            if (blob) {
                onSubmit(blob);
            }
            onBackdropPress();
        });
    };

    return (
        <>
            <BasicModal isVisible={isOpen} onBackdropPress={onBackdropPress} backdropStyles={'ImageCropModal-backdrop'}>
                <div className={'ImageCropModal-container'}>
                    {Boolean(selectedImage) && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={1}
                            keepSelection={true}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                className={'ImageCropModal-img'}
                                src={selectedImage}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                    <ImageCropModalFooter onSave={onSave} onCancel={onBackdropPress} />
                </div>
            </BasicModal>
            <Canvas ref={previewCanvasRef} completedCrop={completedCrop} />
        </>
    );
});
