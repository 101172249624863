import React, {memo, ReactElement} from 'react';
import './MessageText.css';

interface Props {
    text: string;
    isSentByMe: boolean;
}

export const MessageText = memo((props: Props): ReactElement => {
    const {text, isSentByMe} = props;
    return (
        <p className={`MessageText-p ${isSentByMe ? 'MessageText-user-text' : 'MessageText-partner-text'}`}>{text}</p>
    );
});
