import * as React from 'react';

export function ReportIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M7 14A7 7 0 107 0a7 7 0 000 14z" fill="#201F24" />
            <path
                d="M6.964 3.5a.547.547 0 00-.547.547v3.522a.547.547 0 101.094 0V4.047a.547.547 0 00-.547-.547zM7 10.286a.738.738 0 100-1.477.738.738 0 000 1.477z"
                fill="#fff"
            />
        </svg>
    );
}
