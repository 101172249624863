import './ChatAdminTemplates.css';
import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {ChatAdminTemplate} from './ChatAdminTemplate';
import {getTemplateData} from '../../../utils/admin/getTemplateData';
import {ClientTemplate} from '../../../shared/template';
import {generateMessageFromTemplate} from '../../../utils/admin/generateMessageFromTemplate';
import {sendImage, sendMessage} from '../../../utils/message/sendMessage';
import {createBlobFromURL} from '../../../utils/helpers/createBlobFromURL';
import {clearDraft} from '../../../redux/reducers/localSettingsReducer';
import {Toast, ToastRef} from '../../Utils/Toast';
import {useIsAdmin} from '../../../utils/user/isUserAdmin';

interface Props {
    dialogId: string;
    messageText: string;
    scrollToChatEnd: () => void;
}

export const ChatAdminTemplates = (props: Props): ReactElement | null => {
    const {messageText, dialogId, scrollToChatEnd} = props;
    const isAdmin = useIsAdmin();
    const templates = useAppSelector((state) => state.admin.templates);
    const [filteredTemplates, setFilteredTemplates] = useState(templates);
    const toastRef = useRef<ToastRef>(null);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const filtered = templates.filter((item) => item.startsWith(messageText));
        // If message input is empty or filter does not match any template then show all templates
        // Otherwise return only filtered templates
        if (messageText.length === 0 || filtered.length === 0) {
            setFilteredTemplates(templates);
        } else {
            setFilteredTemplates(filtered);
        }
    }, [messageText, templates]);

    if (!isAdmin) {
        return null;
    }

    const onTemplateSend = async (template: ClientTemplate): Promise<void> => {
        const message = await generateMessageFromTemplate(template);
        if (message?.type === 'text') {
            sendMessage(dialogId, message);
        } else if (message?.type === 'image') {
            const imageBlob = await createBlobFromURL(template.image?.uri ?? '');
            if (imageBlob) {
                sendImage(dialogId, message, imageBlob);
            }
        }
        scrollToChatEnd();
        dispatch(clearDraft({dialogId}));
    };

    const showNoTemplatesToast = (): void => toastRef.current?.show();

    return (
        <div className={'ChatAdminTemplates-container'}>
            {filteredTemplates.map((item) => {
                return (
                    <ChatAdminTemplate
                        key={item}
                        name={item}
                        onPress={async () => {
                            const template = await getTemplateData(item, dialogId);
                            if (template) {
                                onTemplateSend(template);
                            } else {
                                showNoTemplatesToast();
                            }
                        }}
                    />
                );
            })}
            <Toast ref={toastRef} title={'No template found for user'} />
        </div>
    );
};
