import React, {forwardRef, memo, ReactElement, useRef} from 'react';
import {FancyModal, ModalRef} from './FancyModal';
import {ModalButton} from './ModalButton';
import {sendReport} from '../../../utils/dialog/sendReport';
import {ComplaintId} from '../../../shared/complaints';
import {Toast, ToastRef} from '../Toast';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    dialogId: string;
}

export const ReportModal = memo(
    forwardRef<ModalRef, Props>((props: Props, ref): ReactElement => {
        const {dialogId} = props;
        const reportToast = useRef<ToastRef>(null);
        // TODO: add wrong gender report
        const reports: {title: string; value: ComplaintId}[] = [
            {title: getLocalizedString('advertisementAndSelling'), value: 'advertisementAndSelling'},
            {title: getLocalizedString('childPorn'), value: 'childPorn'},
            {title: getLocalizedString('insulting'), value: 'insulting'},
            {title: getLocalizedString('objectionableContent'), value: 'objectionableContent'},
            {title: getLocalizedString('begging'), value: 'begging'},
            {title: getLocalizedString('underageUser'), value: 'underageUser'},
            {title: getLocalizedString('scammer'), value: 'scammer'},
            {title: getLocalizedString('badProfilePicture'), value: 'badProfilePicture'},
        ];

        return (
            <>
                <FancyModal ref={ref} title={getLocalizedString('complaintModalTitle')}>
                    {reports.map(({title, value}) => {
                        return (
                            <ModalButton
                                title={title}
                                value={value}
                                key={value}
                                onPress={(reportReason) => {
                                    sendReport(dialogId, reportReason);
                                    if (ref && 'current' in ref) {
                                        ref?.current?.close?.();
                                        reportToast?.current?.show();
                                    }
                                }}
                            />
                        );
                    })}
                </FancyModal>
                <Toast ref={reportToast} title={getLocalizedString('reportSentSuccess')} />
            </>
        );
    })
);
