import React, {ReactElement} from 'react';
import {Login} from '../components/Login/Login';
import {LoggedIn} from '../components/Login/LoggedIn';
import {useIsAdmin} from '../utils/user/isUserAdmin';

export const LoginRoute = (): ReactElement | null => {
    const isAdmin = useIsAdmin();
    if (isAdmin) {
        return <LoggedIn />;
    }
    return <Login />;
};
