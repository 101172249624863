import './MessageContent.css';
import React, {ReactElement} from 'react';
import {MessageSystem} from './MessageSystem';
import {MessageAny} from '../../../../../shared/message-any';
import {MessageText} from './MessageText';
import {MessageAboutMe} from './MessageAboutMe';
import {MessageImage} from './MessageImage';
import {MessageVoice} from './MessageVoice';
import {useAppSelector} from '../../../../../redux/hooks';
import {NSFW} from '../../../../../types/nsfw';
import {exhaustiveCheck} from '../../../../../utils/helpers/exhaustiveCheck';

interface Props {
    message: MessageAny;
    isSentByMe: boolean;
    dialogId: string;
}

export const MessageContent = (props: Props): ReactElement => {
    const {message, isSentByMe, dialogId} = props;
    const shouldBlurImage = useAppSelector((state) => {
        if (message.type !== 'image' || isSentByMe) {
            return false;
        }
        const nsfw = state.user?.nsfw;
        switch (nsfw) {
            case NSFW.NONE:
                return false;
            case NSFW.ALL:
                return true;
            case NSFW.TEMPORARY:
                return Boolean(state.dialogs?.metas[dialogId]?.temporary);
            default:
                exhaustiveCheck(nsfw);
                return false;
        }
    });

    switch (message.type) {
        case 'system':
            return <MessageSystem text={message.text} />;
        case 'text':
            return <MessageText text={message.text} isSentByMe={isSentByMe} />;
        case 'image':
            return (
                <MessageImage
                    url={message.uri}
                    caption={message.text}
                    width={message.width}
                    height={message.height}
                    isSentByMe={isSentByMe}
                    madeWithCamera={message.madeWithCamera}
                    shouldBlur={shouldBlurImage}
                />
            );
        case 'about-me':
            return <MessageAboutMe text={message.text} />;
        case 'voice':
            return <MessageVoice url={message.uri} isSentByMe={isSentByMe} />;
        default:
            return <div>Received not supported message: {message.type}</div>;
    }
};
