import './BackButton.css';
import React, {ReactElement} from 'react';
import {BackButtonIcon} from '../../assets/svg/BackButtonIcon';
import {useNavigate} from 'react-router-dom';

export interface BackButtonProps {
    title?: string;
    iconColor?: string;
    textColor?: string;
    onPress?: () => void;
}

export const BackButton = (props: BackButtonProps): ReactElement => {
    const {iconColor, textColor, title, onPress} = props;
    const navigate = useNavigate();
    return (
        <div
            className={'BackButton-container'}
            onClick={() => {
                if (onPress) {
                    onPress();
                } else {
                    navigate(-1);
                }
            }}
        >
            <BackButtonIcon color={iconColor} />
            {title && (
                <span className={'BackButton-title'} style={{color: textColor}}>
                    {title}
                </span>
            )}
        </div>
    );
};
