import React, {ReactElement} from 'react';
import {useAppSelector} from '../redux/hooks';
import {Chat} from '../components/Chat/Chat';
import {Explore} from '../components/Explore/Explore';

export const ExploreRoute = (): ReactElement => {
    const exploreDialogId = useAppSelector((state) => state.user.data.exploreDialogId);
    if (exploreDialogId) {
        return <Chat dialogId={exploreDialogId} />;
    } else {
        return <Explore />;
    }
};
