import './OptionsPicker.css';
import React, {ReactElement} from 'react';
import {OptionsPickerItem} from './OptionsPickerItem';

interface Props {
    allOptions: Record<string, string>;
    activeOptions: string[];
    onPress: (option: string) => void;
    isDisabled?: (value: string) => boolean;
}

export const OptionsPicker = (props: Props): ReactElement => {
    const {allOptions, activeOptions, onPress, isDisabled = () => false} = props;
    return (
        <div className={'OptionsPicker-container'}>
            {Object.entries(allOptions).map(([key, value]) => {
                const disabled = isDisabled(key);
                return (
                    <OptionsPickerItem
                        key={key}
                        title={value}
                        isActive={activeOptions.includes(key)}
                        isDisabled={disabled}
                        onPress={() => onPress(key)}
                    />
                );
            })}
        </div>
    );
};
