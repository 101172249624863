import './ActionButton.css';
import React, {ReactElement} from 'react';
import {SendButtonProps, SendButton} from './SendButton';
import {RecordButtonProps} from '../../../../types/recorder';
import {RecordButton} from './RecordButton';

interface Props extends SendButtonProps, RecordButtonProps {
    shouldShowRecord: boolean;
}

export const ActionButton = (props: Props): ReactElement => {
    const {shouldDisableSend, handleClick, recorderState, handlers, shouldShowRecord} = props;

    if (shouldShowRecord) {
        return <RecordButton recorderState={recorderState} handlers={handlers} handleClick={handleClick} />;
    } else {
        return <SendButton shouldDisableSend={shouldDisableSend} handleClick={handleClick} />;
    }
};
