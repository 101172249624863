import './EditableSettingsInputActionButton.css';
import React, {ReactElement} from 'react';
import {BsFillPencilFill} from 'react-icons/bs';
import {FcCheckmark} from 'react-icons/fc';

interface Props {
    isFocused: boolean;
    onEdit: () => void;
    onSave: () => void;
}

export const EditableSettingsInputActionButton = (props: Props): ReactElement => {
    const {isFocused, onEdit, onSave} = props;
    const commonProps = {
        className: 'EditableSettingsInputActionButton-icon',
    };
    return (
        <div
            className={'EditableSettingsInputActionButton-container'}
            onClick={() => {
                if (isFocused) {
                    onSave();
                } else {
                    onEdit();
                }
            }}
        >
            {isFocused ? <FcCheckmark {...commonProps} size={25} /> : <BsFillPencilFill {...commonProps} size={18} />}
        </div>
    );
};
