import './EditableSettingsInput.css';
import React, {FormEvent, KeyboardEvent, ReactElement, useEffect, useRef, useState} from 'react';
import {EditableSettingsInputLimit} from './EditableSettingsInputLimit';
import {EditableSettingsInputActionButton} from './EditableSettingsInputActionButton';

interface Props {
    title: string;
    value: string;
    bottomTip?: string;
    placeholder?: string;
    onSubmit: (newValue: string) => Promise<void>;
    multiline?: boolean;
    limit?: number;
}

export const EditableSettingsInput = (props: Props): ReactElement => {
    const {title, value, placeholder, onSubmit, multiline, limit, bottomTip} = props;
    const [limitLeft, setLimitLeft] = useState((limit ?? 0) - value?.length);
    const inputRef = useRef<HTMLDivElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const didReachLimit = Boolean(limit && limitLeft < 0);
    useEffect(() => {
        setLimitLeft((limit ?? 0) - value?.length);
    }, [isFocused, limit, value.length]);

    const onPress = (): void => {
        setIsFocused(false);
        inputRef.current?.blur();
        const trimmedValue = inputRef.current?.innerText.trim() ?? '';
        if (inputRef.current) {
            inputRef.current.innerText = trimmedValue;
        }
        onSubmit(trimmedValue).catch(() => {
            // In case of error return previous value
            if (inputRef.current) {
                inputRef.current.innerText = value;
            }
        });
    };
    const onEditPress = (): void => {
        setIsFocused(true);
        inputRef.current?.focus();
    };

    const onInput = (e: FormEvent<HTMLDivElement>): void => {
        if (limit) {
            setLimitLeft(limit - e.currentTarget.innerText.trim().length);
        }
    };
    const onKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
        // Send message on enter
        if (e.key === 'Enter' && (!multiline || (multiline && !e.shiftKey))) {
            e.preventDefault();
            onPress();
        }
    };
    return (
        <div className={'UserSettingsEditableSection-container'}>
            <span className={'UserSettingsEditableSection-title'}>{title}</span>
            <div className={'UserSettingsEditableSection-content'}>
                <div className={'UserSettingsEditableSection-input-wrapper'}>
                    {/* TODO: bug in Safari when it removes line breaks on the second save */}
                    <div
                        tabIndex={0}
                        ref={inputRef}
                        className={`UserSettingsEditableSection-input ${didReachLimit && 'limit-exceeded'}`}
                        contentEditable={true}
                        onInput={onInput}
                        suppressContentEditableWarning={true}
                        onKeyDown={onKeyDown}
                        data-placeholder={placeholder}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                    >
                        {value.length > 0 && value}
                    </div>
                    <EditableSettingsInputLimit
                        isVisible={Boolean(limit && isFocused && inputRef?.current)}
                        limitLeft={limitLeft}
                        didReachLimit={didReachLimit}
                    />
                </div>
                <EditableSettingsInputActionButton isFocused={isFocused} onEdit={onEditPress} onSave={onPress} />
            </div>
            {bottomTip && <span className={'UserSettingsEditableSection-bottom-tip'}>{bottomTip}</span>}
        </div>
    );
};
