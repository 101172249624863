import './BasicButton.css';
import React, {ReactElement} from 'react';

interface Props {
    title: string;
    onClick: () => void;
}

export const BasicButton = (props: Props): ReactElement => {
    const {title, onClick} = props;
    return (
        <button className={`BasicButton-button`} onClick={onClick}>
            {title}
        </button>
    );
};
