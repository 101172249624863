import './ExploreBurgerMenuTrigger.css';
import React, {ReactElement} from 'react';
import {AiOutlineMenu} from 'react-icons/ai';
import {useAppDispatch} from '../../redux/hooks';
import {showSlideMenu} from '../../redux/reducers/localSettingsReducer';

export const ExploreBurgerMenuTrigger = (): ReactElement => {
    const dispatch = useAppDispatch();
    return (
        <div className={'ExploreBurgerMenuTrigger-container'}>
            <div
                className={'ExploreBurgerMenuTrigger-icon-div'}
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(showSlideMenu());
                }}
            >
                <AiOutlineMenu className={'ExploreBurgerMenuTrigger-icon'} size={30} />
            </div>
        </div>
    );
};
