import {MessageType} from '../../shared/message-type';
import {exhaustiveCheck} from '../helpers/exhaustiveCheck';

// Before adding new message type read this -> https://github.com/minderov/AnonChat/pull/914#discussion_r607457389
export const isUserMessage = (messageType: MessageType): boolean => {
    switch (messageType) {
        case 'text':
        case 'image':
        case 'voice':
        case 'video':
            return true;
        case 'about-me':
        case 'inline-button':
        case 'system':
        case 'interests':
        case 'call':
            return false;
        default:
            exhaustiveCheck(messageType);
            return false;
    }
};
