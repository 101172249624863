import {LastSeenStatus} from '../../shared/callback-types';
import moment from 'moment';
import {store} from '../../redux/store';
import {getLocalizedString} from './getLocalizedString';

export const prettifyOnlineStatus = (lastSeenStatus: LastSeenStatus): string => {
    if (lastSeenStatus?.status === 'online' || lastSeenStatus?.status === 'hidden' || !lastSeenStatus?.lastSeenTime) {
        return '';
    }
    // If status is not online or hidden lastSeenStatus contains lastSeenTime field
    const isDateInTheFuture = new Date(lastSeenStatus.lastSeenTime).getTime() > new Date().getTime();
    const dateToMakePretty = isDateInTheFuture ? new Date() : new Date(lastSeenStatus?.lastSeenTime);

    const momentDate = moment(dateToMakePretty);
    const language = store.getState().user.data.language ?? 'en';
    momentDate.locale(language);
    const fourHoursAgo = 4;
    const fortyFiveSecondsAgo = 45;
    // For period from 0 to 44 seconds, display custom JUST_NOW string instead of 'few sec ago'
    if (moment().diff(momentDate, 'seconds') < fortyFiveSecondsAgo) {
        return getLocalizedString('wasJustNow');
    } else if (moment().diff(momentDate, 'hours') < fourHoursAgo) {
        // For recent times, display time "from now" (e.g. 15 seconds ago), more info: https://momentjs.com/docs/#/displaying/fromnow/
        return momentDate.fromNow();
    } else {
        // For the last week the format is "December 5th at 2:30 AM" or "5 декабря в 2.30"
        // for older dates, the format is "LL" (e.g. August 19, 2019)
        return momentDate.calendar(undefined, {
            lastWeek: 'D MMMM в HH:mm',
            sameElse: 'LL',
        });
    }
};
