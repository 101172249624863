import './ChatProfileOnlineStatus.css';
import React, {ReactElement} from 'react';
import {LastSeenStatus} from '../../../shared/callback-types';
import {prettifyOnlineStatus} from '../../../utils/helpers/prettifyOnlineStatus';
import {getLocalizedString} from '../../../utils/helpers/getLocalizedString';

interface Props {
    lastSeenStatus: LastSeenStatus;
}

export const ChatProfileOnlineStatus = (props: Props): ReactElement | null => {
    const {lastSeenStatus} = props;
    if (!lastSeenStatus || lastSeenStatus?.status === 'hidden') {
        return null;
    }
    const displayStatus =
        lastSeenStatus?.status === 'online' ? getLocalizedString('online') : prettifyOnlineStatus(lastSeenStatus);
    return (
        <div className={'ChatProfileOnlineStatus-container'}>
            <span className={'ChatProfileOnlineStatus-status'}>{displayStatus}</span>
        </div>
    );
};
