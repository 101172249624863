import {useState, useEffect} from 'react';
import {Recorder, Interval, AudioTrack, MediaRecorderEvent, SetRecorder} from '../../types/recorder';

const initialState: Recorder = {
    recordingMinutes: 0,
    recordingSeconds: 0,
    initRecording: false,
    mediaStream: null,
    mediaRecorder: null,
    audio: null,
};

export const useRecorder = () => {
    const [recorderState, setRecorderState] = useState<Recorder>(initialState);

    useEffect(() => {
        const MAX_RECORDER_TIME = 5;
        let recordingInterval: Interval = null;

        if (recorderState.initRecording) {
            recordingInterval = setInterval(() => {
                setRecorderState((prevState: Recorder) => {
                    if (
                        prevState.recordingMinutes === MAX_RECORDER_TIME &&
                        prevState.recordingSeconds === 0 &&
                        typeof recordingInterval === 'number'
                    ) {
                        clearInterval(recordingInterval);
                        return prevState;
                    }

                    if (prevState.recordingSeconds >= 0 && prevState.recordingSeconds < 59)
                        return {
                            ...prevState,
                            recordingSeconds: prevState.recordingSeconds + 1,
                        };
                    else if (prevState.recordingSeconds === 59)
                        return {
                            ...prevState,
                            recordingMinutes: prevState.recordingMinutes + 1,
                            recordingSeconds: 0,
                        };
                    else return prevState;
                });
            }, 1000);
        } else if (typeof recordingInterval === 'number') {
            clearInterval(recordingInterval);
        }

        return () => {
            if (typeof recordingInterval === 'number') {
                clearInterval(recordingInterval);
            }
        };
    });

    useEffect(() => {
        setRecorderState((prevState) => {
            if (prevState.mediaStream) {
                return {
                    ...prevState,
                    mediaRecorder: new MediaRecorder(prevState.mediaStream),
                };
            } else {
                return prevState;
            }
        });
    }, [recorderState.mediaStream]);

    useEffect(() => {
        const recorder = recorderState.mediaRecorder;
        let chunks: Blob[] = [];

        if (recorder && recorder.state === 'inactive') {
            recorder.start();

            recorder.ondataavailable = (e: MediaRecorderEvent) => {
                chunks.push(e.data);
            };

            recorder.onstop = () => {
                const blob = new Blob(chunks, {type: 'audio/ogg; codecs=opus'});
                chunks = [];

                setRecorderState((prevState: Recorder) => {
                    if (prevState.mediaRecorder) {
                        return {
                            ...initialState,
                            audio: blob,
                        };
                    } else {
                        return initialState;
                    }
                });
            };
        }

        return () => {
            if (recorder) {
                recorder.stream.getAudioTracks().forEach((track: AudioTrack) => track.stop());
            }
        };
    }, [recorderState.mediaRecorder]);

    return {
        recorderState,
        startRecording: () => _startRecording(setRecorderState),
        cancelRecording: () => setRecorderState(initialState),
        stopRecording: () => _stopRecording(recorderState.mediaRecorder),
    };
};

const _startRecording = async (setRecorderState: SetRecorder) => {
    try {
        const stream: MediaStream = await navigator.mediaDevices.getUserMedia({audio: true});

        setRecorderState((prevState) => {
            return {
                ...prevState,
                initRecording: true,
                mediaStream: stream,
            };
        });
    } catch (err) {
        console.log(err);
    }
};

const _stopRecording = (recorder: MediaRecorder | null) => {
    if (recorder && recorder.state !== 'inactive') {
        recorder.stop();
    }
};
