import './BasicModal.css';
import React, {memo, PropsWithChildren, ReactElement} from 'react';
import {createPortal} from 'react-dom';

interface Props {
    isVisible: boolean;
    onBackdropPress?: () => void;
    onLayoutPress?: () => void;
    backdropStyles?: string;
    layoutStyles?: string;
}

export const BasicModal = memo((props: PropsWithChildren<Props>): ReactElement | null => {
    const {isVisible, onBackdropPress, onLayoutPress, children, layoutStyles, backdropStyles} = props;

    if (!isVisible) {
        return null;
    }
    return createPortal(
        <>
            <div
                className={`BasicModal-backdrop ${backdropStyles}`}
                onClick={(e) => {
                    e.stopPropagation();
                    onBackdropPress?.();
                }}
            />
            <div
                className={`BasicModal-layout ${layoutStyles}`}
                onClick={(e) => {
                    e.stopPropagation();
                    onLayoutPress?.();
                }}
            >
                {children}
            </div>
        </>,
        document.getElementById('root')!
    );
});
