import './ChatAdminGptTemplate.css';
import React, {ReactElement, useEffect, useState} from 'react';
import {getCookie} from '../../../utils/cookie/getCookie';
import {MdContentPasteGo} from 'react-icons/md';
import {withAdminRights} from '../../../utils/helpers/withAdminRights';
import {GET_GENERATED_RESPONSE} from '../../../constants/paths';
import {useLastMessage} from '../../../utils/message/useLastMessage';
import {useIsAdmin} from '../../../utils/user/isUserAdmin';

interface Props {
    dialogId: string;
    onPress: (text: string) => void;
}

export const getSupportGptResponse = async (dialogId: string): Promise<string | null> => {
    const _getSupportGptResponse = async (): Promise<string | null> => {
        const data = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                chatId: dialogId,
                cookie: getCookie(),
            }),
        };
        const res = await fetch(GET_GENERATED_RESPONSE, data);
        if (!res.ok) {
            return null;
        }
        return (await res.json()).response;
    };
    return withAdminRights(_getSupportGptResponse);
};

function useGptResponse(dialogId: string) {
    const [response, setResponse] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    // lastMessage is used as a dependency to update the response when a new message is sent
    const lastMessage = useLastMessage(dialogId);

    useEffect(() => {
        // isMounted is used to prevent return response to a wrong dialog
        let isMounted = true;

        async function fetchData() {
            setLoading(true);
            setError(null);

            try {
                const response = await getSupportGptResponse(dialogId);

                if (!isMounted) {
                    return;
                }

                if (response) {
                    setResponse(response);
                } else {
                    setError(`Error: couldn't generate response`);
                }
            } catch (err: any) {
                if (isMounted) {
                    setError(`Error: ${err.message}`);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [dialogId, lastMessage?.msgId]);

    return {response, error, loading};
}

export const ChatAdminGptTemplate = (props: Props): ReactElement | null => {
    const {dialogId, onPress} = props;
    const isAdmin = useIsAdmin();
    const {response, error, loading} = useGptResponse(dialogId);

    if (!isAdmin) {
        return null;
    }

    const wrapper = (content: React.ReactNode) => <div className={'ChatAdminGptTemplate-container'}>{content}</div>;

    if (loading) {
        return wrapper('Generating response...');
    }

    if (error) {
        return wrapper(error);
    }

    if (!response) {
        return null;
    }

    return wrapper(
        <>
            <div className={'ChatAdminGptTemplate-response'}>{response}</div>
            <div className={'ChatAdminGptTemplate-buttons'}>
                <button
                    className={'ChatAdminGptTemplate-paste-button'}
                    onClick={() => {
                        onPress(response);
                    }}
                >
                    <MdContentPasteGo size={18} />
                </button>
            </div>
        </>
    );
};
